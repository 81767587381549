import axios from 'axios'

import {makeSetters} from '@helpers/vuex/mutations'

const makeDefaultState = () => {
  return {
    data: {
      henFedInGrams: 0,
      roosterFedInGrams: 0,
      remarks: '',
      waterConsumptionYesterdayInLitres: 0,
      targetToday: {
        gramsPerHen: 0,
        henFeed: '',
        gramsPerRooster: 0,
        roosterFeed: ''
      },
      targetTomorrow: {
        gramsPerHen: 0,
        henFeed: '',
        gramsPerRooster: 0,
        roosterFeed: ''
      }
    }
  }
}

const state = makeDefaultState()

const mutations = {
  ...makeSetters([
    'data.henFedInGrams',
    'data.roosterFedInGrams',
    'data.remarks',
    'data.waterConsumptionYesterdayInLitres'
  ]),
  setState (state, data) {
    state.data = data
  },
  resetState () {
    Object.assign(state, makeDefaultState())
  }
}

const actions = {
  loadFeedAmount ({commit, rootGetters}) {
    let farm = rootGetters['poultryManagement/farmer/currentFarm']
    let poultryHouse = rootGetters['poultryManagement/farmer/currentPoultryHouse']
    let date = rootGetters['poultryManagement/farmer/selectedDate']
    axios.get('/api/poultrymanagement/farmer/feedAmount', {
      params: {
        farmId: farm.id,
        poultryHouseNumber: poultryHouse.poultryHouseNumber,
        flockNumber: poultryHouse.flocks[0], // TODO: access departement instead, once departements concept exists
        date: date
      }
    })
    .then((result) => {
      if (result.data) {
        commit('setState', result.data)
      } else {
        commit('resetState')
      }
    })
  },
  saveFeedAmount ({state, dispatch, rootGetters}) {
    let farm = rootGetters['poultryManagement/farmer/currentFarm']
    let poultryHouse = rootGetters['poultryManagement/farmer/currentPoultryHouse']
    let date = rootGetters['poultryManagement/farmer/selectedDate']
    axios.post('/api/poultrymanagement/farmer/saveFeedAmount', {
      ...state.data,
      henFedInGrams: Math.round(state.data.henFedInGrams),
      roosterFedInGrams: Math.round(state.data.roosterFedInGrams),
      farmId: farm.id,
      poultryHouseNumber: poultryHouse.poultryHouseNumber,
      flockNumber: poultryHouse.flocks[0],
      date: date
    })
    .then(() => {
      dispatch('poultryManagement/farmer/goToFarm', farm, {root: true})
    })
  }
}

const getters = {
  feedAmount (state) {
    return state.data
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
