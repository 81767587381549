import {cloneDeep} from 'lodash'
import newGuid from 'src/js/helpers/new-guid'

export const selectTerm = ({commit}, term) => {
  commit('selectTerm', term)
}

export const createTermRequest = ({commit}) => {
  let newTerm = {
    uid: newGuid(),
    start: null,
    end: null,
    remark: '',
    lesePrice: null,
    currency: null // Replace with Euro as default (Construct correct item here)
  }

  commit('openCreateOrEdit', newTerm)
}

export const editTermRequest = ({commit, state}) => {
  if (!state.currentTerm) {
    throw new Error('No term selected')
  }

  let term = cloneDeep(state.currentTerm)

  commit('openCreateOrEdit', term)
}

export const createOrEditTermSuccess = ({commit, state}) => {
  if (!state.editTerm) {
    throw new Error('No term selected')
  }

  commit('realEstateContractLease/contracts/addOrUpdateTermToContract', state.editTerm, {root: true})
  commit('selectTerm', state.editTerm)
  commit('closeCreateOrEdit')
  commit('realEstateContractLease/contracts/setTermsChanged', null, {root: true})
}

export const createOrEditTermFailure = ({commit}) => {
  commit('closeCreateOrEdit')
}

export const deleteTermRequest = ({commit, rootState}) => {
  const currentContract = rootState.realEstateContractLease.contracts.currentContract
    ? rootState.realEstateContractLease.contracts.currentContract
    : rootState.realEstateContractLease.contracts.newContract

  if (!currentContract) {
    throw new Error('No contract selected')
  }

  commit('openDeleteTerm')
}

export const deleteTermSuccess = ({commit, state}) => {
  if (!state.currentTerm) {
    throw new Error('No term selected')
  }

  commit('realEstateContractLease/contracts/removeTermFromContract', state.currentTerm, {root: true})
  commit('closeDeleteTerm')
  commit('realEstateContractLease/contracts/setTermsChanged', null, {root: true})
}

export const deleteTermFailure = ({commit}) => {
  commit('closeDeleteTerm')
}
