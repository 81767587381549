import {makeSetters} from '@helpers/vuex/mutations'

const defaultState = () => ({
  actualFeedAmountPerAnimal: null,
  actualFeedAmountPerDay: null,
  targetFeedAmountPerAnimalToday: null,
  targetFeedAmountPerDayToday: null,
  targetFeedAmountPerDayTomorrow: null,
  targetFeedAmountPerAnimalTomorrow: null,
  usedFeedId: null,
  siloNumber: null,
  // usedFeedsOptions: [],
  // removalsFromSiloOptions: [],
  siloLevelPercent: null,
  waterPerDay: null,
  waterPerAnimal: null
})

export default {
  namespaced: true,
  state: defaultState,
  mutations: {
    ...makeSetters([
      'actualFeedAmountPerAnimal',
      'actualFeedAmountPerDay',
      'targetFeedAmountPerAnimalToday',
      'targetFeedAmountPerDayToday',
      'targetFeedAmountPerDayTomorrow',
      'targetFeedAmountPerAnimalTomorrow',
      'usedFeedId',
      'siloNumber',
      'siloLevelPercent',
      'waterPerDay',
      'waterPerAnimal'
    ]),
    reset (state) {
      Object.assign(state, defaultState())
    }
  }
}
