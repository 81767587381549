require './validation'

{Validation} = require './validation-helper'
{attachSelectionExtension} = require './selection-grid-validation'


Validation.attachSelectionExtension = attachSelectionExtension


module.exports = {Validation}

module.__esModule = true
module.exports.default = Validation
