const registry = {}

const register = (name, f) => {
  if (registry[name]) {
    console.error(`Registration of setup function '${name}' was attempted, but function is already registered. Old function will be overwritten.`)
  }
  registry[name] = f
}
const resolve = (name) => registry[name]

export {
  register,
  resolve
}
