export default {
  cycleDisplayMode (state) {
    switch (state.ui.displayMode) {
    case 'split':
      state.ui.displayMode = 'rightPanelMaximized'
      break
    case 'rightPanelMaximized':
      state.ui.displayMode = 'leftPanelMaximized'
      break
    case 'leftPanelMaximized':
      state.ui.displayMode = 'split'
      break
    default:
      throw new Error(`unknown displayMode ${state.ui.displayMode} - available modes: split, rightPanelMaximized, leftPanelMaximized`)
    }
  },

  setInitComplete (state, value) {
    state.initComplete = value
  },

  setPermissions (state, permissions) {
    state.permissions = permissions
  },

  setFarms (state, farms) {
    state.data.farms = !farms ? [] : farms
  },

  setPoultryHouses (state, poultryHouses) {
    state.data.poultryHouses = !poultryHouses ? [] : poultryHouses
  },

  setFlocks (state, flocks) {
    state.data.flocks = !flocks ? [] : flocks
  },

  setLivestock (state, livestock) {
    state.data.livestock = !livestock ? [] : livestock
  },

  setPoultryHouseVisits (state, visits) {
    state.data.poultryHouseVisits = !visits ? [] : visits
  },

  setMaterials (state, materials) {
    state.data.logistics.materials = !materials ? [] : materials
  },

  setFeeds (state, feeds) {
    state.data.feeds = !feeds ? [] : feeds
  },

  setIsInAdminComponent (state, isInAdminComponent) {
    state.ui.navigation.isInAdminComponent = isInAdminComponent
  },

  setLeftPanelContent (state, componentName) {
    state.ui.navigation.leftPanelContent = componentName
  },
  setRightPanelContent (state, componentName) {
    state.ui.navigation.rightPanelContent = componentName
  },

  setSelectedDate (state, date) {
    state.ui.selection.date = date
  },
  setSelectedFarmId (state, farmId) {
    state.ui.selection.farmId = farmId
  },
  setSelectedPoultryHouseId (state, poultryHouseId) {
    state.ui.selection.poultryHouse.id = poultryHouseId
  },
  setSelectedPoultryHouseVisit (state, visit) {
    state.ui.selection.poultryHouse.visit = visit
  },

  setMapMode (state, mapMode) {
    if (mapMode !== 'displayFarms' && mapMode !== 'displayPoultryHouses') {
      throw new Error(`unknown map mode ${mapMode} - available modes: displayFarms or displayPoultryHouses`)
    }

    state.ui.map.mode = mapMode
  },

  toggleMapSelection (state, allowSelection) {
    state.ui.map.allowSelection = allowSelection
  }
}
