import WKT from 'ol/format/WKT'
import GeoJSON from 'ol/format/GeoJSON'
import MultiPolygon from 'ol/geom/MultiPolygon'
import Feature from 'ol/Feature'

export const wktFormat = new WKT()
export const geoJsonFormat = new GeoJSON({dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857'})

export const makeFeature = obj => {
  const {wkt, geometry, id, style, ...rest} = obj
  const feature = wktFormat.readFeature(wkt || geometry)
  feature.getGeometry().transform('EPSG:4326', 'EPSG:900913')
  if (id) {
    feature.set('uid', id)
    feature.set('id', id)
  }
  if (style) {
    feature.setStyle(style)
  }
  feature.setProperties(rest)
  return feature
}

export function toWkt (feature) {
  if (feature.type === 'Feature') {
    feature = geoJsonFormat.readFeature(feature)
  }
  return feature ? wktFormat.writeFeature(feature, {dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857'}) : null
}

export const polyLikeFilter = feature => {
  const type = feature.getGeometry().getType()
  return type === 'Polygon' || type === 'MultiPolygon'
}

export const pointLikeFilter = feature => {
  const type = feature.getGeometry().getType()
  return type === 'Point' || type === 'MultiPoint'
}

export const lineLikeFilter = feature => {
  const type = feature.getGeometry().getType()
  return type === 'LineString' || type === 'MultiLineString'
}

export const nonPolyLikeFilter = feature => !polyLikeFilter(feature)

const supportedMultiTypes = ['MultiPoint', 'MultiLineString', 'MultiPolygon']

export function dissolveMultiGeometries (featureOrFeatures) {
  if (Array.isArray(featureOrFeatures)) {
    const features = featureOrFeatures
    return features.reduce((points, feature) => points.concat(dissolveMultiGeometries(feature)), [])
  }

  const feature = featureOrFeatures.type
    ? geoJsonFormat.readFeature(featureOrFeatures)
    : featureOrFeatures

  const geometry = feature.getGeometry()
  const type = geometry.getType()

  if (!type.startsWith('Multi')) return [feature]
  if (!supportedMultiTypes.includes(type)) {
    throw new Error(`invalid multi-geometry type: '${type}', can only split ${supportedMultiTypes}`)
  }

  const properties = feature.getProperties()

  const getGeometries = geometry[`get${type.substring(5)}s`]

  return getGeometries.call(geometry).map(geometry => new Feature({...properties, geometry}))
}

const dissolveWithCheck = types => featureOrFeatures => {
  const getType = feature => (feature.geometry && feature.geometry.type) || feature.getGeometry().getType()

  if (Array.isArray(featureOrFeatures)
    ? featureOrFeatures.some(feature => !types.includes(getType(feature)))
    : !types.includes(getType(featureOrFeatures))) {
    throw new Error(`invalid geometry types, only ${types} allowed`)
  }

  return dissolveMultiGeometries(featureOrFeatures)
}

export const splitToPoints = dissolveWithCheck(['Point', 'MultiPoint'])
export const splitToLineStrings = dissolveWithCheck(['LineString', 'MultiLineString'])
export const splitToPolygons = dissolveWithCheck(['Polygon', 'MultiPolygon'])

export function combineToMultiPolygon (features) {
  const geometryTypes = features.map(feature => feature.getGeometry().getType())

  if (geometryTypes.some(x => x !== 'Polygon' && x !== 'MultiPolygon')) {
    throw new Error(`cannot combined geometries of types ${geometryTypes} to MultiPolygon`)
  }

  const polygons = features.filter(f => f.getGeometry().getType() === 'Polygon')
  const multiPolygons = features.filter(f => f.getGeometry().getType() === 'MultiPolygon')

  const polygonArray = []
  for (const feature of multiPolygons) {
    for (const geometry of feature.getPolygons()) {
      polygonArray.push(geometry)
    }
  }
  for (const feature of polygons) {
    polygonArray.push(feature.getGeometry())
  }

  const combined = new MultiPolygon(polygonArray)
  return new Feature({geometry: combined})
}

export function seperateMultiLineFeatureToLineFeatures (featureOrWkt) {
  if (featureOrWkt.type === 'Feature') {
    if (featureOrWkt.geometry.type !== 'MultiLineString') throw new Error('No MultiLineString')

    return featureOrWkt.geometry.coordinates.map(coordinates => ({
      type: 'Feature',
      properties: featureOrWkt.properties,
      geometry: {
        type: 'LineString',
        coordinates
      }
    }))
  }

  if (typeof featureOrWkt === 'string') {
    featureOrWkt = wktFormat.readFeature(featureOrWkt, {dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857'})
  }

  const geometry = featureOrWkt.getGeometry()

  if (geometry.getType() !== 'MultiLineString') {
    throw new Error('No MultiLineString')
  }

  const properties = featureOrWkt.getProperties()
  return geometry.getLineStrings()
    .map(geometry => new Feature({...properties, geometry}))
}
