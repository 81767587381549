import axios from 'axios'
import moment from 'moment'

import {makeSetters} from '@helpers/vuex/mutations'
import {smarterGet} from '@helpers/vuex/data-loading'

export default {
  namespaced: true,
  state: {
    boxes: [],
    task: {
      boxId: null,
      applicationMapIds: [],
      lanePlanIds: [],
      name: null,
      description: null,
      validFrom: moment(),
      validTo: null
    },
    ui: {
      openModal: false
    }
  },
  getters: {
  },
  mutations: {
    ...makeSetters([
      'task.Name',
      'task.Description',
      'ui.openModal'
    ]),
    setBoxes (state, data) {
      state.boxes = data
      state.task.boxId = data[0].id
    },
    resetTask (state) {
      state.task = {
        boxId: null,
        applicationMapIds: [],
        lanePlanIds: [],
        name: null,
        description: null,
        validFrom: moment(),
        validTo: null
      }
    },
    setNewApplicationMapIds (state, ids) {
      state.task.applicationMapIds = ids
    }
  },
  actions: {
    loadboxes ({state, commit}) {
      smarterGet(['/OSB/GetBoxes']
        , {
          id: 'data.osb.boxes',
          expiry: 0,
          inputs: {
          },
          sharedCache: true,
          onResult ([data]) {
            commit('setBoxes', data)
          }
        })
    },
    createTask ({commit, state, rootState}) {
      const year = rootState.fieldRecordSystem.userSettings.harvestYear
      return axios.post(`/OSB/CreateTask/${year}`, state.task)
          .then(() => {
            commit('resetTask')
          })
    }
  }
}
