import proj4 from 'proj4'
import {register} from 'ol/proj/proj4'

proj4.defs('EPSG:25832', '+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs')
proj4.defs('EPSG:25833', '+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs')
proj4.defs('EPSG:31466', '+proj=tmerc +lat_0=0 +lon_0=6 +k=1 +x_0=2500000 +y_0=0 +ellps=bessel +towgs84=598.1,73.7,418.2,0.202,0.045,-2.455,6.7 +units=m +no_defs')
proj4.defs('EPSG:31467', '+proj=tmerc +lat_0=0 +lon_0=9 +k=1 +x_0=3500000 +y_0=0 +ellps=bessel +towgs84=598.1,73.7,418.2,0.202,0.045,-2.455,6.7 +units=m +no_defs')
proj4.defs('EPSG:31468', '+proj=tmerc +lat_0=0 +lon_0=12 +k=1 +x_0=4500000 +y_0=0 +ellps=bessel +towgs84=598.1,73.7,418.2,0.202,0.045,-2.455,6.7 +units=m +no_defs')
proj4.defs('EPSG:31469', '+proj=tmerc +lat_0=0 +lon_0=15 +k=1 +x_0=5500000 +y_0=0 +ellps=bessel +towgs84=598.1,73.7,418.2,0.202,0.045,-2.455,6.7 +units=m +no_defs')
proj4.defs('EPSG:32632', '+proj=utm +zone=32 +datum=WGS84 +units=m +no_defs')
proj4.defs('EPSG:32633', '+proj=utm +zone=33 +datum=WGS84 +units=m +no_defs')

register(proj4)

export default [
  {name: 'WGS84 / LatLon', code: 4326, abbreviation: 'WGS84'},
  {name: 'ETRS89 / UTM Zone 32N', code: 25832, abbreviation: 'ETRS89 '},
  {name: 'ETRS89 / UTM Zone 33N', code: 25833, abbreviation: 'ETRS89/33N '},
  {name: 'Gauß-Krüger Zone 2', code: 31466, abbreviation: 'GK2'},
  {name: 'Gauß-Krüger Zone 3', code: 31467, abbreviation: 'GK3'},
  {name: 'Gauß-Krüger Zone 4', code: 31468, abbreviation: 'GK4'},
  {name: 'Gauß-Krüger Zone 5', code: 31469, abbreviation: 'GK5'},
  {name: 'UTM Zone 32N', code: 32632, abbreviation: 'UTM32N'},
  {name: 'UTM Zone 33N', code: 32633, abbreviation: 'UTM33N'}
]
