export default function tokenize (s) {
  const tokenRegex = /{(\w+)(?:,\s?(\w+))?}/g

  let matches = tokenRegex.exec(s)
  let lastIndex = 0
  const tokens = []

  while (matches) {
    const endOfMatch = tokenRegex.lastIndex
    const startOfMatch = endOfMatch - matches[0].length

    tokens.push(s.slice(lastIndex, startOfMatch))

    lastIndex = endOfMatch

    const [text, key, format] = matches

    tokens.push({text, key, format})

    matches = tokenRegex.exec(s)
  }

  if (lastIndex < s.length) {
    tokens.push(s.slice(lastIndex, s.length))
  }

  return tokens
}
