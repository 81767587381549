import axios from 'axios'
import {backend} from 'src/js/infrastructure'

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.patch['Content-Type'] = 'application/json'
axios.defaults.headers.put['Content-Type'] = 'application/json'
axios.defaults.withCredentials = true
axios.interceptors.request.use(config => {
  const server = backend.getServer()
  if (server && !config.url.startsWith('http') && config.url.startsWith('/')) {
    config.url = new URL(config.url, server)
  }
  return config
})
