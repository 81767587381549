const mutations = {
  recieveOrgUnits (state, orgUnits) {
    state.selectedOrgUnit = null
    if (orgUnits) {
      state.orgUnits = orgUnits
      state.originalTree = orgUnits
    } else {
      state.orgUnits = null
      state.originalTree = null
    }
  },
  selectOrgUnit (state, orgUnit) {
    state.selectedOrgUnit = orgUnit
  }
}

export default mutations
