import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import contract from './contract'
import facilities from './facilities'
import landparcels from './land-parcel'
import properties from './properties'
import lease from './lease'

const state = {
  areas: {
    available: [{text: null}],
    current: null
  },
  orgUnits: {
    available: [],
    current: null
  },
  tableData: {
    rows: [],
    selection: [],
    selectionData: [],
    initialNumberOfRowsToLoad: null,
    indexNextRows: null,
    numberNextRows: null,
    totalItemsForEntity: 0,
    bufferRows: [],
    endOfDataReached: false
  },
  masterData: {
    dmsReferenceTypes: [],
    countries: [],
    usageTypes: [],
    currencies: []
  },
  data: {
    localDistrict: null,
    cadastralDistricts: [],
    selectedCadastralDistrict: null,
    landParcels: [],
    landParcel: null,
    segments: []
  },
  ui: {
    leftSidebarPinned: true,
    rightSidebarPinned: true
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules: {
    contract,
    facilities,
    landparcels,
    properties,
    lease
  }
}
