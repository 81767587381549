{register} = require 'src/js/infrastructure/setup-registry'

setup = ($context) ->
    $modal = $context.find('.modal')
    deferred = $.Deferred()
    result = false

    $modal.find('button[type="submit"]').on 'click', -> result = true
    $modal.on 'hidden.bs.modal', ->
        deferred.resolve(result)
        result = false

    $modal.data 'promise', deferred.promise()

module.exports.__esModule = true
module.exports.default = setup

register('App.Modals.BinaryQuestionSetup', setup)
