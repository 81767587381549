import {getArea, getDistance} from 'ol/sphere'
import {transform} from 'ol/proj'

import {geoJsonFormat, wktFormat} from './features'

const sum = (total, area) => total + area

const getDistanceByProjection = (c1, c2) => {
  c1 = transform(c1, 'EPSG:3857', 'EPSG:4326')
  c2 = transform(c2, 'EPSG:3857', 'EPSG:4326')
  // ol getDistance uses EPSG:4326
  return getDistance(c1, c2)
}

const getGeodesicLength = (line) => {
  const coordinates = line.getCoordinates()
  return new Array(coordinates.length - 1).fill(null)
    .map((item, i) => [coordinates[i], coordinates[i + 1]])
    .map(coords => getDistanceByProjection(coords[0], coords[1]))
    .reduce(sum, 0)
}

const computeAreaSize = geometry => {
  const outerArea = Math.abs(getArea(geometry))

  if (geometry.getLinearRingCount() > 1) {
    const holeArea = new Array(geometry.getLinearRingCount() - 1).fill(null)
      .map((x, i) => {
        const innerRing = geometry.getLinearRing(i + 1)
        return Math.abs(getArea(innerRing)) // ol getArea uses EPSG:3857
      })
      .reduce(sum, 0)

    return outerArea - holeArea
  }

  return outerArea
}

const getGeodesicArea = (geometry) => {
  switch (geometry.getType()) {
  case 'Polygon':
    return computeAreaSize(geometry)
  case 'MultiPolygon':
    return geometry.getPolygons().map(computeAreaSize).reduce(sum, 0)
  default: throw new Error(`invalid geometry type for area calculation: ${geometry.getType()}`)
  }
}

export const computeSize = geometry => {
  if (typeof geometry === 'string') {
    geometry = wktFormat.readGeometry(geometry, {dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857'})
  }
  if (typeof geometry.type === 'string') {
    geometry = geoJsonFormat.readGeometry(geometry)
  }

  const type = geometry.getType()
  if (type === 'Polygon' || type === 'MultiPolygon') {
    return getGeodesicArea(geometry)
  } else if (type === 'LineString') {
    return getGeodesicLength(geometry)
  }
  throw new Error(`currently only size of Polygons or LineStrings can be computed, geometry was type ${type}`)
}
