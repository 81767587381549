const hexArray = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f']

const newGuid = function () {
  let id = ''
  for (let i = 0; i < 36; i++) {
    if (i === 8 || i === 13 || i === 18 || i === 23) {
      id += '-'
    } else {
      id += `${hexArray[Math.floor(Math.random() * 16)]}`
    }
  }
  return id
}

export default newGuid
