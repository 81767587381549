import queryData from './api/queryData'

export const initRequest = ({commit}) => {
  queryData.getTreeNodes((treeNodes) => {
    commit('navigation/recieveOrgUnits', treeNodes)
  })
}

export const getFilteredAppointments = ({commit}, {orgUnit, start, end}) => {
  queryData.getAppointmentByOrgUnitId({orgUnitId: orgUnit.uid, start, end}, (appointments) => {
    commit('realEstateContractLeaseAppointment/appointments/recieveAppointments', appointments, {root: true})
  })
}

export const extendContracts = ({dispatch, state}, {orgUnit, start, end}) => {
  const contractIds = state.appointments.currentAppointments.length > 0
    ? state.appointments.currentAppointments.map(a => a.leaseContract.value)
    : []

  queryData.extendContracts({contractIds}).then(() => dispatch('getFilteredAppointments', {orgUnit, start, end}))
}

export const cancelContracts = ({dispatch}, {orgUnit, contractIds, start, end}) => {
  queryData.cancelContracts(orgUnit.uid, contractIds)
  .then(() => dispatch('getFilteredAppointments', {orgUnit, start, end}))
}
