import {format} from 'src/js/i18n/conversion'

import tokenize from './tokenize'

export default function replace (s, context, customFormatters = {}) {
  return tokenize(s).map(token => {
    const value = context[token.key]

    if (value === undefined) return token.text || token

    try {
      switch (token.format) {
      case 'number':
      case 'date':
      case 'datetime':
      case 'time': {
        return format(value, token.format)
      }
      default: {
        if (customFormatters[token.format]) {
          return customFormatters[token.format](value)
        }

        return value
      }
      }
    } catch (error) {
      console.error(error)

      return value
    }
  })
}
