# require '???' # .tinycolorpicker() ???

setup = ({$element, options, $ctx = $(document)}) ->
    $element.tinycolorpicker()
    picker = $element.data 'plugin_tinycolorpicker'
    color = $element.find('input.colorInput').val()
    console.log 'color'
    console.log color
    if color
        picker.setColor color

    return $.Deferred().resolve().promise()

module.exports = {
    setup
}

module.exports.__esModule = true
module.exports.default = setup
