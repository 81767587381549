import queryData from '../api/queryData'

export const selectOrgUnit = ({commit}, orgUnit) => {
  if (orgUnit) {
    queryData.getCurrentTenant(orgUnit.uid, (currentTenantId) => {
      commit('selectOrgUnit', {
        id: orgUnit.uid,
        tenantId: currentTenantId
      })
    })
    queryData.getBanksForCurrentTenant(orgUnit.uid, (banks) => {
      commit('realEstateMasterDataContacts/bankingAccounts/recieveBanks', banks, {root: true})
    })
  } else {
    commit('selectOrgUnit', null)
  }
}
