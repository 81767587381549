# CoffeeScript

class Plugin
    actions: []
    modes: []

    webgis: null
    map: null
    enabled: false

    init: ->
    enable: ->
    disable: ->

class Action
    @defaultOptions =
        icon: ''
        hotkey: null
        context: 'selection'
        onExecute: (features) ->

    constructor: (@name, options) ->
        options = $.extend true, {}, Action.defaultOptions, options
        @icon = options.icon
        @hotkey = options.hotkey
        @context = options.context
        @onExecute = options.onExecute

class ModalAction
    @defaultOptions =
        icon: ''
        hotkey: null
        onFeatureHover: (feature, layer) ->
        onFeatureClick: (feature, layer) ->
        onMapClick: (coordinate) ->
        enable: ->
        disable: ->

    constructor: (@name, options) ->
        options = $.extend true, {}, ModalAction.defaultOptions, options
        @icon = options.icon
        @hotkey = options.hotkey
        @onFeatureHover = options.onFeatureHover
        @onFeatureClick = options.onFeatureClick
        @onMapClick = options.onMapClick
        @enable = options.enable
        @disable = options.disable

class Mode extends ModalAction
    @defaultOptions =
        actions: []

    constructor: (name, options) ->
        options = $.extend true, {}, Mode.defaultOptions, options
        super name, options

        @actions = options.actions

module.exports = {
    Plugin,
    Action,
    ModalAction,
    Mode
}

module.exports.__esModule = true
module.exports.default = Plugin
