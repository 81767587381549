
let $waitscreen = null
let waitscrenGlitchWatcher = null

const fixGlitchedWaitscreen = () => {
  const transparent = ['transparent', 'rgba(0, 0, 0, 0)'].includes($waitscreen.css('background-color'))
  if ($waitscreen.hasClass('waitscreen-fade') && transparent) {
    $waitscreen.addClass('inactive')
    stopGlitchWatcher()
  }
}

const startGlitchWatcher = () => {
  stopGlitchWatcher()
  waitscrenGlitchWatcher = setInterval(fixGlitchedWaitscreen, 100)
}

const stopGlitchWatcher = () => {
  if (waitscrenGlitchWatcher) {
    clearInterval(waitscrenGlitchWatcher)
  }
}

const show = (error = false) => {
  if (error) {
    $waitscreen.addClass('error')
  } else {
    $waitscreen.removeClass('error')
  }

  stopGlitchWatcher()

  $waitscreen.removeClass('inactive error display-progress display-progress-step waitscreen-fade')
}

const hide = () => {
  $waitscreen.addClass('waitscreen-fade')
  stopGlitchWatcher()
  startGlitchWatcher()
}

const waitFor = (promise) => {
  show()
  promise.always(hide)
}

const setError = () => {
  $waitscreen.addClass('error')
}

const setProgress = (progress, step, estimate) => {
  $waitscreen.addClass('display-progress')
  const $progress = $waitscreen.find('#waitscreen-progress')
  const $progressBar = $progress.find('.progress-bar')

  const $step = $waitscreen.find('.waitscreen-progress-step')

  if (!step.length) {
    $waitscreen.removeClass('display-progress-step')
  } else {
    $waitscreen.addClass('display-progress-step')
    $step.text(step)
  }

  $progressBar.text(estimate)

  if (progress === undefined) {
    $progressBar.addClass('progress-bar-striped')
    $progressBar.addClass('active')

    $progressBar.css('width', '100%')
    $progressBar.attr('aria-valuenow', 'indeterminate')
  } else if (progress === null) {
    $waitscreen.removeClass('display-progress')
  } else {
    $progressBar.removeClass('progress-bar-striped')
    $progressBar.removeClass('active')

    const percent = Math.round(progress * 100)
    const percentText = `${percent}%`

    $progressBar.css('width', percentText)
    $progressBar.attr('aria-valuenow', percent)
    $progressBar.text(percentText)
  }
}

const setup = () => {
  $waitscreen = $('.waitscreen')

  $waitscreen.find('#waitscreen-close-button').on('click', hide)
}

// set up waitscreen singleton on page load
$(setup)

export {
  show,
  hide,
  waitFor,
  setError,
  setProgress
}
