import mutations from './mutations'
import actions from './actions'
import getters from './getters'

const state = {
  editBuffer: null,
  ui: {
    selectedType: 'fertilizer',
    selectedProductIds: [],
    productHash: null,
    groupKey: null
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
