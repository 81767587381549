import axios from 'axios'
import newGuid from '@helpers/new-guid'

const actions = {

  loadGlobalMasterData ({commit}) {
    axios.get('/realestate/MasterDataDmsReferenceType/GetDmsReferenceTypes').then(result => commit('setAvailableDmsReferenceTypes', result.data))
    axios.get('/realestate/Country/GetCountries').then(result => commit('setAvailableCountries', result.data))
    axios.get('/realestate/MasterDataUsageType/GetUsageTypes').then(result => commit('setAvailableUsageTypes', result.data))
    axios.get('/realestate/MasterDataCurrency/GetCurrencies').then(result => commit('setAvailableCurrencies', result.data))
  },

  loadAvailableAreas ({commit}) {
    return axios.get('/realestate/areas')
                .then(result => commit('setAvailableAreas', result.data))
  },

  loadAvailableOrgUnits ({commit, state}) {
    const currentAreaId = state.areas.current.id

    axios.get(`/realestate/${currentAreaId}/orgunits`)
         .then(result => commit('setAvailableOrgUnits', result.data))
  },

  loadTableData ({commit, state}, loadingArgs) {
    if (!(state.orgUnits.current && state.areas.current)) {
      return
    }
    const currentOrgUnit = state.orgUnits.current.uid
    const currentAreaId = state.areas.current.id

    let sortQueryParameter = ''

    if (loadingArgs && loadingArgs.sort) {
      for (let sortBycolumn of loadingArgs.sort) {
        sortQueryParameter = sortQueryParameter.concat(`${sortBycolumn.column}:${sortBycolumn.direction};`)
      }
    }

    if (loadingArgs.direction === 'initial') {
      let url = `/realestate/${currentOrgUnit}/${currentAreaId}?begin=${0}&count=${state.tableData.initialNumberOfRowsToLoad}`
      url = sortQueryParameter ? url.concat(`&sort=${sortQueryParameter}`) : url
      axios.get(url).then(result => {
        commit('setInitialRows', result.data.currentItems)
        commit('setTotalItemsForEntity', result.data.totalItemsForEntity)
      })
    } else if (loadingArgs.direction === 'next') {
      let url = `/realestate/${currentOrgUnit}/${currentAreaId}?begin=${state.tableData.indexNextRows}&count=${state.tableData.numberNextRows}`
      url = sortQueryParameter ? url.concat(`&sort=${sortQueryParameter}`) : url
      commit('appendBufferRows')
      axios.get(url).then(result => {
        commit('setNextBufferRows', result.data.currentItems)
      })
    }
  },

  loadDataForTableSelection ({commit}, url) {
    axios.get(url).then(result => commit('setDataForTableSelection', result.data))
  },

  fetchAggregationData ({state, commit}) { // TODO: include requests for remaining areas, once the methods exist in backend
    const orgUnitId = state.orgUnits.current.uid

    switch (state.areas.current.id) {
    case 'landparcels': {
      const ids = state.tableData.selection.map(x => x.Uid)
      axios.post(`/realestate/${orgUnitId}/entitelments/`, ids)
      .then(result => {
        commit('landparcels/setAggregationData', result.data)
      })
      break
    }
    case 'facilities': {
      break
    }
    case 'properties': {
      break
    }
    case 'lease': {
      const ids = state.tableData.selection.map(x => x.uid)
      axios.post(`/realestate/${orgUnitId}/lease/aggregations`, ids)
      .then(result => {
        commit('lease/setAggregationData', result.data)
      })
      break
    }
    case 'sales': {
      const ids = state.tableData.selection.map(x => x.Uid)
      axios.post(`/realestate/${orgUnitId}/sales/summary`, ids)
      .then(result => {
        commit('contract/updateSalesSummary', result.data)
      })
      break
    }
    case 'purchases': {
      const ids = state.tableData.selection.map(x => x.Uid)
      axios.post(`/realestate/${orgUnitId}/purchase/summary`, ids)
      .then(result => {
        commit('contract/updatePurchaseSummary', result.data)
      })
      break
    }
    }
  },

  deleteLeaseContractArea ({commit}, id) {
    commit('deleteLeaseContractArea', id)
  },

  getLocalDistrict ({state, commit}) {
    commit('setSegments', [])
    commit('setLandParcels', [])

    const currentOrgUnit = state.orgUnits.current.uid
    const localDistrict = state.data.localDistrict.value

    axios.get(`/realestate/cadastraldistrict/CadastralDistrictsContainingSegments?parentid=${localDistrict}&entityid=${currentOrgUnit}`)
    .then(response => response.data)
    .then(data =>
      commit('setCadastralDistricts', data)
    )
  },
  getLandParcelInCadastrialDistrict ({state, commit}) {
    commit('setSegments', [])

    const parentId = state.data.selectedCadastralDistrict.uid
    const entityId = state.orgUnits.current.uid

    axios.get('/RealEstate/LandParcel/GetLandParcelsInCadastralDistrict', {params: {parentId, entityId}})
    .then(response => response.data)
    .then(data =>
      commit('setLandParcels', data)
    )
  },
  getSegmentsForLandParcel ({state, commit}) {
    const cadastralId = state.data.selectedCadastralDistrict.uid
    const parcelId = state.data.landParcel.uid
    axios.get(`/realEstate/Segments/SegmentsForCadastralDistrict?cadastralDistrictId=${cadastralId}&landParcelId=${parcelId}`)
    .then(response => response.data)
    .then(data =>
      commit('setSegments', data)
    )
  },
  addSegmentToAdmentments ({state, commit}, {segment, selectedAmendment}) {
    const newArea = {
      fkLandParcel: segment.landParcelId,
      groundpoints: segment.groundpoints,
      landParcelIdentifier: segment.localDistrictName + ', ' + segment.cadastralDistrictNumber + ', ' + segment.landParcelNumber,
      link: `/RealEstate/Company/${state.orgUnits.current.uid}/Landparcel/${segment.landParcelId}`,
      sizeActual: segment.segmentSize,
      sizeContractual: segment.segmentSize,
      uid: newGuid(),
      usage: {
        label: segment.usageShortcut,
        text: segment.usageName,
        value: segment.usageId
      }
    }
    commit('addAreaToAmendments', {newArea, selectedAmendment})
  },
  loadBankData ({state, commit}) {
    const currentOrgUnitId = state.orgUnits.current.uid

    return axios.get('/RealEstate/MasterDataBank/GetBanks', {params: {entityId: currentOrgUnitId}})
      .then(result => result.data)
      .then(data => {
        commit('setBankData', data)
      })
  },
  loadLoanNumbers ({state, commit}) {
    const currentOrgUnitId = state.orgUnits.current.uid

    return axios.get('/RealEstate/MasterDataLoanNumber/GetLoanNumbers', {params: {entityId: currentOrgUnitId}})
      .then(result => result.data)
      .then(data => {
        commit('setLoanNumbers', data)
      })
  },

  saveData ({state}) {
    let data = null
    if (state.areas.current.id === 'purchases' || state.areas.current.id === 'sales') {
      data = JSON.parse(JSON.stringify(state.contract.current))
      data.commonData.notary = {uid: data.commonData.notary.value, name: data.commonData.notary.text, city: data.commonData.notary.label}
      data.commonData.businessObject = {uid: data.commonData.businessObject.value, name: data.commonData.businessObject.text}
      data.commonData.partnerA = {uid: data.commonData.partnerA.value, name: data.commonData.partnerA.text, city: data.commonData.partnerA.label}
      data.commonData.partnerB.forEach((partner, index) => {
        data.commonData.partnerB[index] = {
          uid: partner.value,
          name: partner.text,
          city: partner.label
        }
      })

      if (state.areas.current.id === 'purchases') {
        data.metaData = {
          landParcelInOut: true,
          contractUseSizeOut: false
        }
      } else if (state.areas.current.id === 'sales') {
        data.metaData = {
          landParcelInOut: false,
          contractUseSizeOut: true
        }
      }
    } else if (state[state.areas.current.id]) {
      data = state[state.areas.current.id].current
    }
    return axios.post(state.areas.current.links.save, data)
  },

  resetData ({state, commit}) {
    const url = state.areas.current.links.get
    let currentId = null

    if (state.areas.current.id === 'purchases' || state.areas.current.id === 'sales') {
      currentId = state.contract.current.commonData.uid
    } else {
      currentId = state[state.areas.current.id].current.uid
    }

    axios.get(`${url}?id=${currentId}`)
      .then(result => commit('setDataForTableSelection', result.data))
  }
}

export default actions
