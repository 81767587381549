import moment from 'moment'
import {computeSize} from '@helpers/openlayers/computation'
import {stringify} from 'wellknown'
import translateUnit from 'src/js/i18n/translate-unit'

let parse = x => x
let format = x => `${x}`

let parseNumber = x => x
let parseDateTime = x => x

let formatNumberDefault = x => `${x}`
let formatFixed0 = x => `${x}`
let formatFixed0g = x => `${x}`
let formatFixed1 = x => `${x}`
let formatFixed2 = x => `${x}`
let formatFixed4 = x => `${x}`
let formatExact = x => `${x}`
const recreate = () => {
  parseNumber = Globalize.numberParser()
  parseDateTime = (string, format) => moment(string, format)
  parse = (value, format) => {
    if (format) {
      format = format.toLowerCase()
    }
    let parsed = value
    if (format === 'number' || !format) {
      parsed = parseNumber(value)
    }
    if (format === 'datetime' || (!format && isNaN(parsed))) {
      parsed = parseDateTime(value)
      if (!format && !parsed.isValid()) {
        return null
      }
    }
    return parsed
  }
  format = (value, format) => {
    if (value === null) return ''

    if (format) {
      format = format.toLowerCase()
    }

    if (value instanceof Date) {
      value = moment(value)
    }

    if (value instanceof moment) {
      switch (format) {
      case 'date':
        return value.local().format('L')
      case 'time':
        return value.local().format('LT')
      case 'iso':
        return value.toISOString()
      case 'datetime':
      default:
        return value.local().format('LLL')
      }
    } else if (typeof value === 'number') {
      switch (format) {
      case 'date':
        return moment(value).local().format('L')
      case 'time':
        return moment(value).local().format('LT')
      case 'datetime':
        return moment(value).local().format('LLL')
      case 'area':
        return formatArea(value)
      case 'hectare':
        return formatHectare(value, 'f4')
      case 'hectare-sparse':
        return formatHectare(value, 'f2')
      case 'distance':
        return formatLength(value)
      case 'harvestyear': {
        const yearAsMoment = moment({year: value})
        const a = yearAsMoment.clone().subtract(1, 'year').format('YY')
        const b = yearAsMoment.format('YY')
        return `${a}/${b}`
      }
      case 'harvestyearlong': {
        const yearAsMoment = moment({year: value})
        const a = yearAsMoment.clone().subtract(1, 'year').format('YYYY')
        const b = yearAsMoment.format('YYYY')
        return `${a}/${b}`
      }
      case 'percent': {
        return `${formatFixed2(value * 100)} ${translateUnit('%')}`
      }
      case 'f0':
        return formatFixed0(value)
      case 'f0g':
        return formatFixed0g(value)
      case 'f1':
        return formatFixed1(value)
      case 'number':
      case 'f2':
        return formatFixed2(value)
      case 'f4':
        return formatFixed4(value)
      case 'exact':
        return formatExact(value)
      default:
        return formatNumberDefault(value)
      }
    } else if (typeof value === 'string') {
      switch (format) {
      case 'date':
        return moment(value).local().format('L')
      case 'long-date':
        return moment(value).local().format('LL')
      case 'time':
        return moment(value).local().format('LT')
      case 'datetime-short':
        return moment(value).local().format('L LT')
      case 'datetime':
        return moment(value).local().format('LLL')
      case 'day-month':
        return moment(value).local().format('DoMM')
      default:
        return value
      }
    } else if (typeof value === 'object') {
      if (value.hasOwnProperty('coordinates') && value.hasOwnProperty('type')) {
        switch (value.type) {
        case 'Polygon':
        case 'MultiPolygon':
          return formatArea(computeSize(value))
        case 'LineString':
        case 'MultiLineString':
          return formatLength(computeSize(value))
        case 'Point':
        case 'MultiPoint':
          return stringify(value)
        default:
          return value
        }
      } else {
        throw new Error('this type of Object cannot be formatted')
      }
    } else {
      throw new Error(`value of type '${typeof value}' cannot be formatted`)
    }
  }
  formatNumberDefault = Globalize.numberFormatter({minimumFractionDigits: 0, maximumFractionDigits: 2})
  formatFixed0 = Globalize.numberFormatter({maximumFractionDigits: 0, useGrouping: false})
  formatFixed0g = Globalize.numberFormatter({maximumFractionDigits: 0, useGrouping: true})
  formatFixed1 = Globalize.numberFormatter({maximumFractionDigits: 1, useGrouping: false})
  formatFixed2 = Globalize.numberFormatter({minimumFractionDigits: 2, maximumFractionDigits: 2})
  formatFixed4 = Globalize.numberFormatter({minimumFractionDigits: 4, maximumFractionDigits: 4})
  formatExact = Globalize.numberFormatter({maximumFractionDigits: 20, useGrouping: false})

  const formatLength = (length) => length >= 100 ? `${format(length / 1000)} ${translateUnit('km')}` : `${format(length)} ${translateUnit('m')}`

  const formatArea = (area) => area >= 1000 ? `${format(area / 10000)} ${translateUnit('ha')}` : `${format(area)} ${translateUnit('m²')}`

  const formatHectare = (area, precision) => `${format(area / 10000, precision)} ${translateUnit('ha')}`

  for (const id in recreateListeners) {
    recreateListeners[id]({parse, format})
  }
}

let listenerId = 0
const recreateListeners = {}
function afterRecreate (listener) {
  const id = listenerId++
  recreateListeners[id] = listener

  return () => {
    delete recreateListeners.id
  }
}

export {
  recreate,
  afterRecreate,
  // valid parse formats:
  // number, datetime; undefined == automatic (number prioritised)
  parse,
  // valid format formats:
  // numbers: f2, f4, exact; undefined == default formatting (0-2 fractional digits)
  // moments: date, time, datetime, iso; undefined == datetime
  format
}
