# CoffeeScript
{register} = require 'src/js/infrastructure/setup-registry'
{waitscreen} = require 'src/js/infrastructure'
{post} = require 'src/coffee/infrastructure/ajax'
axios = require 'axios'


_syncRoot = null
_defaultTimeout = 10000
_timeout = _defaultTimeout


# updates the counter
updateNotificationsCounter = ($ctx  = $(document)) ->
    $panel = $ctx.find('#notifications-panel')
    count = $panel.find('.notification').length
    if count == 0
      $panel.hide()
    else
      $panel.show()
    $panel.find('#notifications-counter').text "(#{count})"


# function to refresh the panel content
refreshPanelContent = ($ctx = $(document)) ->
    if _syncRoot
        clearTimeout _syncRoot

    updateNotifications($ctx)
    .catch ->
        _timeout = _timeout * 2
    .then ->
        _syncRoot = setTimeout((-> refreshPanelContent($ctx)), _timeout)


# delete click handling - evt handlers will be attached on first refresh
attachDeleteButtonClickHandlers = ($ctx = $(document)) ->
    $panel = $ctx.find('#notifications-panel')

    $buttons = $panel.find('.delete-notification-button')
    $buttons.off 'click'

    $buttons.on 'click', (evt) ->
        evt.preventDefault()

        deleteNotification $(evt.target).closest('.notification')

        return false

attachDeleteAllButtonClickHandlers = ($ctx = $(document)) ->
    $panel = $ctx.find('#notifications-panel')

    $button = $panel.find('#delete-all-notification-button')
    $button.off 'click'

    $button.on 'click', (evt) ->
        evt.preventDefault()
        notifications = $panel.find('.notification')
        for n in notifications
            $notification = $ n
            type = $notification.data 'type'

            if type == 'Finished' || type == 'Failed' || type == 'Aborted'
                deleteNotification $notification

        return false

updateNotifications = ($ctx = $(document)) ->
  $panel = $ctx.find('#notifications-panel')

  url = $panel.data 'refresh-url'
  $scroller = $panel.find('#notifications-panel-scroller')

  return axios.get url
  .then (response) ->
      $scroller.empty().html response.data
      updateNotificationsCounter $ctx
      attachDeleteButtonClickHandlers $ctx
      attachDeleteAllButtonClickHandlers $ctx
      _timeout = _defaultTimeout

# deletes a notification - calls the server
deleteNotification = ($notification) ->
    if _syncRoot
        clearTimeout _syncRoot

    url = $('#notifications-panel').data 'delete-url'
    data =
        id: $notification.data 'id'
        origin: $notification.data 'origin'

    $notification.fadeTo('slow', 0).slideDown ->
        $notification.remove()
        updateNotificationsCounter()

    promise = post url, data
    .fail -> promise # do nothing
    .always ->
        _syncRoot = setTimeout(refreshPanelContent, _timeout - 1000) # speed it up a bit


# adds a notification to the panel, does not call the server, because generating notifications cannot be done clientside
addNotification = ($notification) ->
    $panel = $('#notifications-panel')
    $scroller = $panel.find('#notifications-panel-scroller')

    # remove the notification from dom and add it anew
    $scroller.append $notification.detach().hide()

    $notification.fadeIn 'slow', ->
        attachDeleteButtonClickHandlers()
        attachDeleteAllButtonClickHandlers $ctx
        updateNotificationsCounter()


# setup - should only be used once in page.coffee
setupNotificationsPanel = ($ctx) ->
    $panel = $ctx.find('#notifications-panel')

    return unless $panel.length > 0

    # call refresh once - automagic after that
    refreshPanelContent $ctx


module.exports = {
    setupNotificationsPanel,
    deleteNotification,
    addNotification,
    updateNotifications
}
