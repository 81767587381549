import queryData from '../api/queryData'

export const selectOrgUnit = ({commit}, {orgUnit, start, end, onlyUnpaid}) => {
  commit('selectOrgUnit', {
    id: orgUnit.uid
  })

  queryData.getPayments({orgUnitId: orgUnit.uid, start, end, onlyUnpaid}, (payments) => {
    commit('realEstateContractLeasePayments/recievePayments', payments, {root: true})
  })
}
