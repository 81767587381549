const defaultProductBuffer = () => ({
  name: null,
  note: null,
  source: 'product-administration'
})

const defaultSeedBuffer = () => ({
  cropId: null,
  quality: null,
  minSeedRate: null,
  maxSeedRate: null,
  maizeMatrix: [0, 0, 0, 0, 0, 0, 0, 0, 0],
  type: 'seed',
  bsA_Culture: null,
  bsA_Culture_Short: null,
  bsA_Number: null,
  vulnerabilities: null,
  mixtureComponents: []
})

const defaultFertilizerBuffer = () => ({
  type: 'fertilizer',
  short: null,
  density: null,
  drySubstrateAmount: null,
  isLiquid: false,
  isOrganic: false,
  phosphorus: null,
  magnesium: null,
  lime: null,
  potassium: null,

  nitrogen: null,
  ammonium: null,
  nitrate: null,
  nitrogenAmide: null,
  tanContent: null,
  minimumEfficiency: null,

  sulfur: null,
  iron: null,
  manganese: null,
  zinc: null,
  boron: null,
  copper: null,
  molybdenum: null,
  isComposted: false,
  consistency: null,
  fertilizerSource: null,
  tags: []
})

const defaultProtectionBuffer = () => ({
  category: [],
  usage: [],
  activeSubstance: [],
  approvalNumber: '',
  isLiquid: false,
  type: 'protection',
  tags: []
})

export default type => {
  const buffer = defaultProductBuffer()

  switch (type) {
  case 'fertilizer': return Object.assign(buffer, defaultFertilizerBuffer())
  case 'seed': return Object.assign(buffer, defaultSeedBuffer())
  case 'protection': return Object.assign(buffer, defaultProtectionBuffer())
  default: throw new Error(`invalid product type: ${type}`)
  }
}
