import axios from 'axios'
import moment from 'moment'
import pages from './pages'
import eggProduction from './egg-production'

import animalWeight from './animal-weight'
import farmStatistics from './farm-statistics'
import feedAmount from './feed-amount'

import {makeSetters} from '@helpers/vuex/mutations'

const makeDefaultState = () => {
  return {
    ui: {
      currentPage: pages.blank,
      errorMessage: '',
      includeTree: true,
      expandedPoultryHouse: null
    },
    data: {
      currentFarm: {
        name: ''
      },
      poultryHouses: [],
      currentPoultryHouse: {
        flocks: [],
        numHens: 0,
        numRoosters: 0,
        poultryHouseNumber: ''
      },
      currentVisit: {
        isFirst: true,
        recordedInFirstRun: false,
        farm: '',
        poultryHouse: ''
      },
      selectedDate: moment().format('YYYY-MM-DD'),
      overview: {}
    }
  }
}

const state = makeDefaultState()

const mutations = {
  ...makeSetters([
    'data.selectedDate'
  ]),
  resetState (state) {
    Object.assign(state, makeDefaultState())
  },
  setCurrentFarm (state, farm) {
    state.data.currentFarm = farm
    if (farm === null) {
      state.ui.currentPage = pages.blank
    }
  },
  setExpandedPoultryHouse (state, poultryHouse) {
    state.ui.expandedPoultryHouse = poultryHouse
  },
  setPoultryHouses (state, poultryHouses) {
    state.data.poultryHouses = poultryHouses
  },
  setCurrentPoultryHouse (state, poultryHouse) {
    state.data.currentPoultryHouse = poultryHouse
  },
  goToPage (state, page) {
    state.ui.includeTree = true
    state.ui.currentPage = page
  },
  goToPageFullscreen (state, page) {
    state.ui.includeTree = false
    state.ui.currentPage = page
  },
  setPoultryHouseVisit (state, visit) {
    state.data.currentVisit = visit
  },
  setErrorMessage (state, errorMessage) {
    state.ui.errorMessage = errorMessage
  },
  setPoultryHouseStatus (state, {poultryHouseNumber, status}) {
    state.data.poultryHouses.find(x => x.poultryHouseNumber === poultryHouseNumber).status = status
  }
}

const actions = {
  goToFarm ({commit, dispatch, state}, farm) {
    commit('setCurrentFarm', farm)
    dispatch('poultryManagement/farmer/farmStatistics/loadFarmStatistics', null, {root: true})
    // TODO: refactoring needed, below code nearly exactly does the same as the loadPoultryHouses action
    axios.get('/api/poultrymanagement/farmer/poultryHouses', {
      params: {
        farm: farm.id,
        date: moment(state.data.selectedDate).format('L')
      }
    })
    .then((result) => {
      commit('setPoultryHouses', result.data)
      commit('goToPage', pages.overview)
    })
    .catch((error) => {
      commit('setErrorMessage', error)
      commit('goToPage', pages.error)
    })
  },
  loadPoultryHouses ({commit, state}) {
    axios.get('/api/poultrymanagement/farmer/poultryHouses', {
      params: {
        farm: state.data.currentFarm.id,
        date: moment(state.data.selectedDate).format('L')
      }
    })
    .then((result) => {
      commit('setPoultryHouses', result.data)
    })
    .catch((error) => {
      commit('setErrorMessage', error)
      commit('goToPage', pages.error)
    })
  },
  openPoultryHouseVisit ({state, commit}, run) {
    axios.get('/api/PoultryManagement/farmer/getrun', {
      params: {
        farm: state.data.currentFarm.id,
        poultryHouseNumber: state.data.currentPoultryHouse.poultryHouseNumber,
        date: state.data.selectedDate,
        run: run
      }
    })
    .then((result) => {
      let visit = result.data
      visit.farm = state.data.currentFarm.name
      visit.farmId = state.data.currentFarm.id
      visit.hens = state.data.currentPoultryHouse.numHens
      visit.roosters = state.data.currentPoultryHouse.numRoosters
      // TODO: access departement instead, once such a concept exists
      visit.flockNumber = state.data.currentPoultryHouse.flocks[0]
      commit('setPoultryHouseVisit', visit)
      commit('goToPageFullscreen', 'PoultryHouseVisit')
    })
    .catch((error) => {
      commit('setErrorMessage', error)
      commit('goToPage', pages.error)
    })
  },
  saveVisit ({state, commit, dispatch}) {
    axios.post('/api/PoultryManagement/farmer/saverun', state.data.currentVisit)
    .then(() => {
      // TODO: find better solution to update poultry house overview data
      dispatch('goToFarm', state.data.currentFarm)
    })
    .catch((error) => {
      commit('setErrorMessage', error)
      commit('goToPage', pages.error)
    })
  }
}

const getters = {
  currentPage (state) {
    return state.ui.currentPage
  },
  includeTree (state) {
    return state.ui.includeTree
  },
  expandedPoultryHouse (state) {
    return state.ui.expandedPoultryHouse
  },
  poultryHouses (state) {
    return state.data.poultryHouses
  },
  currentFarm (state) {
    return state.data.currentFarm
  },
  currentFarmName (state, getters) {
    return getters.currentFarm ? getters.currentFarm.name : ''
  },
  currentPoultryHouse (state) {
    return state.data.currentPoultryHouse
  },
  currentPoultryHouseNumber (state) {
    return state.data.currentPoultryHouse.poultryHouseNumber
  },
  currentVisit (state) {
    return state.data.currentVisit
  },
  selectedDate (state) {
    return state.data.selectedDate
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  modules: {
    animalWeight,
    eggProduction,
    farmStatistics,
    feedAmount
  }
}
