import mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'

const state = {
  currentAmendment: null,
  selectedAmendment: null,
  editAmendment: null,
  isEditAmendmentModalOpen: false,
  isDeleteAmendmentModalOpen: false
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
