import axios from 'axios'

const actions = {
  preloadStaticData ({dispatch}) {
    return Promise.all([
      dispatch('masterData/loadUserPermissions', null, {root: true}),
      dispatch('masterData/reloadCrops', null, {root: true}),
      dispatch('masterData/reloadCropUsages', true, {root: true}),
      dispatch('masterData/reloadUnits', null, {root: true}),
      dispatch('masterData/reloadProductTags', true, {root: true}),
      dispatch('masterData/reloadGlobalProducts', true, {root: true}),
      dispatch('masterData/reloadProductAlgorithms', null, {root: true}),
      dispatch('masterData/reloadProductCharacteristics', null, {root: true}),
      dispatch('masterData/loadPortalSettings', null, {root: true})
      // dispatch('fieldRecordSystem/reloadMaizeProductData', null, {root: true})
    ])
  },
  loadCropTypes ({commit}) {
    return axios.get('/api/v2/master-data/products/croptypes')
    .then(res => res.data)
    .then(cropTypes => {
      commit('setCropTypes', cropTypes)
    })
  },
  select ({rootGetters, commit}, id) {
    const products = rootGetters['masterData/products']

    commit('selectExclusive', {id, products})
  },
  toggle ({rootGetters, commit}, id) {
    const products = rootGetters['masterData/products']

    commit('toggleSelection', {id, products})
  },
  save ({state, dispatch}) {
    const product = state.editBuffer

    return axios.post('/api/v2/master-data/products', product)
    .then(res => res.data)
    .then(product => dispatch('masterData/reloadGlobalProducts', true, {root: true})
    .then(() => product.id))
  },
  archiveSelection ({state, commit, dispatch}) {
    const actions = state.ui.selectedProductIds.map((productId) => {
      return axios.delete(`/api/v2/master-data/products/${productId}/archive`)
    })

    return Promise.all(actions)
    .then(() => {
      commit('clearSelection')
    })
    .then(() => dispatch('masterData/reloadGlobalProducts', true, {root: true}))
  }
}

export default actions
