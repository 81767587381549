import mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'

const state = {
  currentDmsReference: null,
  editDmsReference: null,
  isEditDmsReferenceModalOpen: false,
  isDeleteDmsReferenceModalOpen: false,
  dmsReferenceTypes: []
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
