import mutations from './mutations'
import actions from './actions'
import * as getters from './getters'

import moment from 'moment'

import foodConsumption from './modules/food-consumption'

const state = {
  ui: {
    // transitions,
    displayMode: 'split', // leftPanelMaximized, rightPanelMaximized
    navigation: {
      leftPanelContent: 'Map',
      rightPanelContent: 'FarmSelection',
      isInAdminComponent: false
    },
    map: {
      allowSelection: true,
      mode: 'displayFarms' // displayPoultryHouses
    },
    selection: {
      date: moment().startOf('day').format('YYYY-MM-DD'),
      farmId: null,
      poultryHouse: {
        id: null,
        visit: null
      },
      history: {
        farms: [],
        poultryHouses: []
      }
    },
    search: {
      text: '',
      result: null
    },
    view: {},
    waitscreen: false
  },
  userSettings: {
    date: null,
    ui: {}
  },
  data: {
    user: 'Georg Meier',
    farms: [],
    poultryHouses: [],
    flocks: [],
    livestock: [],
    poultryHouseVisits: [],
    logistics: {
      materials: []
    },

    feeds: []
  },
  permissions: [],
  initComplete: false
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  modules: {
    foodConsumption
  }
}
