import newGuid from 'src/js/helpers/new-guid'

export const selectBankingAccount = ({commit}, bankingAccount) => {
  commit('selectBankingAccount', bankingAccount)
}

export const createNewBankingAccountSuccess = ({commit}) => {
  commit('addBankingAccountToContract')
}

export const createRequest = ({commit, rootState}) => {
  const currentContact = rootState.realEstateMasterDataContacts.contacts.currentContact
    ? rootState.realEstateMasterDataContacts.contacts.currentContact
    : rootState.realEstateMasterDataContacts.contacts.newContact

  if (!currentContact) {
    throw new Error('No contract selected')
  }

  let newBankingAccount = {
    uid: newGuid(),
    bank: null,
    iban: ''
  }

  commit('openCreateOrEdit', newBankingAccount)
}

export const editRequest = ({commit, state}) => {
  if (!state.currentBankingAccount) {
    throw new Error('No banking account selected')
  }

  commit('openCreateOrEdit', state.currentBankingAccount)
}

export const createOrEditSuccess = ({commit, state}) => {
  if (!state.editBankingAccount) {
    throw new Error('No banking account selected')
  }

  commit('realEstateMasterDataContacts/contacts/addOrUpdateBankingAccountToContact', state.editBankingAccount, {root: true})
  commit('selectBankingAccount', state.editBankingAccount)
  commit('closeCreateOrEdit')
}

export const createOrEditFailure = ({commit}) => {
  commit('closeCreateOrEdit')
}

export const deleteRequest = ({commit, rootState}) => {
  const currentContact = rootState.realEstateMasterDataContacts.contacts.currentContact
    ? rootState.realEstateMasterDataContacts.contacts.currentContact
    : rootState.realEstateMasterDataContacts.contacts.newContact

  if (!currentContact) {
    throw new Error('No contact selected')
  }

  commit('openDelete')
}

export const deleteSuccess = ({commit, state}) => {
  if (!state.currentBankingAccount) {
    throw new Error('No banking account')
  }

  commit('realEstateMasterDataContacts/contacts/removeBankingAccountFromContact', state.currentBankingAccount, {root: true})
  commit('closeDelete')
}

export const deleteFailure = ({commit}) => {
  commit('closeDelete')
}

export const createBankingAccountSuccess = ({commit}) => {
  commit('addBankingAccountToContract')
}
