# CoffeeScript
String::startsWith ?= (s) -> @[..s.length] is s
String::endsWith ?= (s) -> s is '' or @[-s.length..] is s

getModuleNameFromLocation = ->
    return window.location.pathname.split('/')[1]

loadJSON = ($element) ->
    obj = JSON.parse $element.html()
    $element.remove()
    return obj

parseNamespace = (namespace) ->
    obj = window
    for part in namespace.split('.')
        obj = if obj then obj[part] else undefined
    return obj
    
functionComparer = (f) ->
    return (a, b) ->
        a = f(a)
        b = f(b)

        #if typeof a is 'string' and typeof b is 'string'
        #    return a.localeCompare(b)

        return 1 if a > b
        return -1 if a < b
        return 0

Array::orderBy ?= (accessFunction) ->
    return this.sort(functionComparer(accessFunction))

window.CloneObjectSimple = (obj) -> JSON.parse JSON.stringify obj

window.PathJoin = (segments...) ->
    ###
    Concatenates path segments
    strips out superfluous slashes and converts backslashes to forward slashes
    ###

    pathStart = (str) ->
        return str.chatAt(0) is '/ ' ? '/' : ''
    strip = (s) ->
        return s.replace(/\\/g, '/').replace(/^\/\/*/, '').replace(/\/\/*$/, '')
    flattenedSplit = [].concat (segment.split('/') for segment in segments)...
    cleaned = (strip(part) for part in flattenedSplit when part and part isnt '.')
    return pathStart(segments[0]) + cleaned.join('/')

window.Parse = (value) ->
    try
        return JSON.parse value
    catch
        return value

window.Val = (id) ->
    return $(id).val()

window.Data = (id, key) ->
    return $(id).data key

window.BooleanNot = (array1, array2) ->
    stringedArray2 = (y.toString() for y in array2)
    return (x for x in array1 when x.toString() not in stringedArray2)

window.sleep = (milliseconds) ->
    start = new Date().getTime()
    continue while (new Date().getTime() - start) > milliseconds
    null

# HACK: currently different return necessary for local/deploy
window.getAppPathFromLocation = ->
    return '/' + window.location.pathname.split('/')[1] + '/'
    #return ''

$.fn.disable = ->
    this.prop 'disabled', true
    this.attr 'disabled', 'disabled'
    this.addClass 'disabled'

$.fn.enable = ->
    this.prop 'disabled', false
    this.removeAttr 'disabled'
    this.removeClass 'disabled'

$.fn.getISODateTime = ->
    datepicker = this.data 'DateTimePicker'
    unless datepicker
        throw new Error "element #{this} has no date picker attached"
    return datepicker.date().toISOString()

$.fn.clearValidation = ->
    $element = $ this
    validator = $element.data 'validator'
    validator?.reset()
    $element.find('.has-error').removeClass('has-error')
    $element.find('.has-success').removeClass('has-success')
    $element.find('.has-feedback .help-block').remove()
    $element.find('.has-feedback').removeClass('has-feedback')
    $element.find('.form-control-feedback').remove()

module.exports = {
    getModuleNameFromLocation,
    loadJSON,
    parseNamespace
}
