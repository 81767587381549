// let currentTheme = null

const setTheme = () => {
  // if (currentTheme) {
  //   currentTheme.unref()
  // }
  if (process.env.NODE_ENV !== 'production') {
    require('src/less/themes/development.usable.less')
  } else {
    const theme = document.body.getAttribute('theme')

    const link = document.createElement('link')
    link.type = 'text/css'
    link.rel = 'stylesheet'
    link.href = theme
    document.body.removeAttribute('theme')
    document.head.appendChild(link)
  }
  // currentTheme.ref()
}

export {
  setTheme
}
