
export const currentAmendment = (state) => {
  return state.currentAmendment ? state.currentAmendment : {}
}

export const hasCurrentAmendment = (state) => {
  return !!state.currentAmendment
}

export const editAmendment = (state) => {
  return state.editAmendment ? state.editAmendment : {}
}

export const hasEditAmendment = (state) => {
  return !!state.editAmendment
}

export const selectedAmendment = (state) => {
  return state.selectedAmendment
}

export const hasSelectedAmendment = (state) => {
  return !!state.selectedAmendment
}

export const isDeleteAmendmentModalOpen = (state) => {
  return state.isDeleteAmendmentModalOpen
}

export const isEditAmendmentModalOpen = (state) => {
  return state.isEditAmendmentModalOpen
}
