# CoffeeScript

{Plugin} = require './base'
{postJson} = require 'src/coffee/infrastructure/ajax'

page = null

class InfoClickPlugin extends Plugin
    @active: false

    constructor: (@overlay) ->

    init: ->
        @$content = @mapcontrol.$target.parent().find '.info-popup-content'
        @$button = @mapcontrol.$target.parent().find '.mapInfoClickButton'
        @$button.attr 'title', @$button.data 'enable-info-click'
        .on 'click', =>
            @toggle()
            if @$container.is(':visible')
                @$button.attr 'title', @$button.data 'enable-info-click'

        @$container = @$content.closest('.info-popup')
        @$container.find('.ol-popup-closer').on 'click', =>
            @$container.hide()
            @$button.attr 'title', @$button.data 'enable-info-click'
            return false

    toggle: =>
        if @active
            @deactivate()
        else
            @activate()
        @active = not @active

    activate: ->
        console.log 'info click activated'
        @map.on 'click', @getFeatureInfo, @overlay
        @mapcontrol.$target.css 'cursor', 'help'
        @$button.attr 'title', @$button.data 'disable-info-click'

    deactivate: ->
        console.log 'info click deactivated'
        @map.un 'click', @getFeatureInfo, @overlay
        @mapcontrol.$target.css 'cursor', 'auto'
        @$button.attr 'title', @$button.data 'enable-info-click'

    getFeatureInfo: (event) =>
        coord = event.coordinate
        res = @map.getView().getResolution()

        layers = []

        for layer in @mapcontrol.layerManager.allLayers()
            continue unless layer.getVisible()

            source = layer.getSource()

            continue unless source.getGetFeatureInfoUrl

            url = source.getGetFeatureInfoUrl coord, res, 'EPSG:900913', {'INFO_FORMAT': 'application/json'}

            layers.push
                Name: layer.get 'name'
                Url: url
                InfoClickItems: layer.get 'infoClick'

        @mapcontrol.$target.css 'cursor', 'wait'

        url = '/GeoMap/GetWMSFeatureInfo'
        data = layers


        postJson url, data
        .then (result, textStatus, jqXHR) =>
            switch jqXHR.status
                when 200
                    @overlay.setPosition coord
                    page.setupFragment $ result # TODO static fragment setup service?

                    .then ($fragment) =>
                        @$content.empty().append $fragment

                        $content = @$content
                        # HACK for tab support inside ol popup
                        $fragment.find('a[role=tab]').on 'click', ->
                            $a = $ this
                            $content.find('li').removeClass 'active'
                            $a.parent().addClass 'active'
                            $content.find('.tab-pane').removeClass 'active'
                            $content.find($(this).attr('href')).addClass 'active'
                            return false
                        # HACK END
                        @$container.show()
                    .then =>
                        @toggle()
                when 204
                    @$container.hide()
                    @toggle()

setPageInstance = (instance) -> page = instance

module.exports = {
    InfoClickPlugin,
    setPageInstance
}

module.exports.__esModule = true
module.exports.default = InfoClickPlugin
