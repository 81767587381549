import areas from './areas'
import amendments from './amendments'
import contracts from './contracts'
import dmsReferences from './dmsReferences'
import finances from './finances'
import terms from './terms'
import navigation from './navigation'
import save from './save'

import mutations from './mutations'
import * as getters from './getter'
import * as actions from './actions'

const state = {
  currencies: []
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules: {
    areas,
    amendments,
    contracts,
    dmsReferences,
    finances,
    terms,
    navigation,
    save
  }
}
