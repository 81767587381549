import {replaceAliases} from '@helpers/i18n'
import replaceTokens from '@helpers/replace-tokens'

import store from '@store'

export default function (fullKey, context) {
  fullKey = replaceAliases(fullKey)

  if (store.state.i18n.editMode) {
    store.commit('i18n/rememberUsage', fullKey)
  }

  const parts = fullKey.split('.')
  const namespace = parts.slice(0, -1).join('.')
  const key = parts[parts.length - 1]

  const existing = store.state.i18n.translations[namespace] ? store.state.i18n.translations[namespace][key] : undefined

  if (existing === undefined) {
    store.dispatch('i18n/signalMissingTranslation', {namespace, key})
  }

  const safeExisting = (existing || '').toString()

  if (safeExisting && context) {
    return replaceTokens(safeExisting, context).join('')
  }

  return safeExisting
}
