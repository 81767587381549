import {makeSetters} from '@helpers/vuex/mutations'

const defaultState = {
  features: [],
  devices: []
}

export default {
  namespaced: true,
  state: {
    ...defaultState
  },
  getters: {
  },
  mutations: {
    ...makeSetters([
      'features',
      'devices'
    ]),
    reset (state) {
      state = Object.assign({}, state, defaultState)
    }
  },
  actions: {
    async reset ({commit}) {
      commit('reset')
    }
  }
}
