{notifications} = require 'src/js/infrastructure'

makeSuccessHandler = (elementOrMessage) ->
    text = if elementOrMessage instanceof jQuery then elementOrMessage.data 'success-message' else elementOrMessage
    return -> notifications.success text

makeErrorHandler = (elementOrMessage) ->
    text = if elementOrMessage instanceof jQuery then elementOrMessage.data 'error-message' else elementOrMessage
    return -> notifications.error text

makeWarningHandler = (elementOrMessage) ->
    text = if elementOrMessage instanceof jQuery then elementOrMessage.data 'warning-message' else elementOrMessage
    return -> notifications.warning text

makeInfoHandler = (elementOrMessage) ->
    text = if elementOrMessage instanceof jQuery then elementOrMessage.data 'info-message' else elementOrMessage
    return -> notifications.info text

makeCriticalErrorHandler = (elementOrMessage) ->
    text = if elementOrMessage instanceof jQuery then elementOrMessage.data 'critical-error-message' else elementOrMessage
    return -> notifications.criticalError text

module.exports = {
    makeSuccessHandler,
    makeErrorHandler,
    makeWarningHandler,
    makeInfoHandler,
    makeCriticalErrorHandler
}
