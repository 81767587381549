reduceHeader = ->
    $('header').addClass('reduced')
    $('#combined-navigation').data('bs.affix')?.options.offset.top = 40

expandHeader = ->
    $('header').removeClass('reduced')
    $('#combined-navigation').data('bs.affix')?.options.offset.top = 170

module.exports = {
    reduceHeader,
    expandHeader
}
