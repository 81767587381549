{backend, maybeAddBackend} = require 'src/js/infrastructure'

getHeaders = ->
    token = backend.getToken()
    if token
        return {'Authorization': "Bearer #{token}", 'X-Requested-With': 'XMLHttpRequest'}
    else
        return {}

getStatic = (url, data) ->
    $.ajax
        type: 'GET'
        url: maybeAddBackend url
        data: data

get = (url, data, localizeData = true) ->
    $.ajax
        type: 'GET'
        url: maybeAddBackend url
        data: if localizeData then localizeObject data else data
        headers: getHeaders()

post = (url, data, localizeData = true) ->
    $.ajax
        type: 'POST'
        url: maybeAddBackend url
        data: if localizeData then localizeObject data else data
        headers: getHeaders()

postJson = (url, data) ->
    $.ajax
        type: 'POST'
        url: maybeAddBackend url
        data: JSON.stringify data
        contentType: 'application/json'
        headers: getHeaders()

put = (url, data, localizeData = true) ->
    $.ajax
        type: 'PUT'
        url: maybeAddBackend url
        data: if localizeData then localizeObject data else data
        headers: getHeaders()

putJson = (url, data) ->
    $.ajax
        type: 'PUT'
        url: maybeAddBackend url
        data: JSON.stringify data
        contentType: 'application/json'
        headers: getHeaders()



_tryAppendSimpleTypeToFormData = (formData, key, value, processedObjects) ->

    type = typeof value

    # localization when needed
    if type is 'number'
        processedObjects.push value
        formData.append key, Globalize.formatNumber value, {maximumFractionDigits: 20, useGrouping: false}
        return true

    if type is 'string'
        processedObjects.push value
        formData.append key, value
        return true

    if type is 'boolean'
        processedObjects.push value
        formData.append key, value
        return true

    # we could transform this into a moment and then localize it
    if value instanceof Date
        processedObjects.push value
        formData.append key, value.toISOString()
        return true

    # had to check for a moment object differently.
    if value._isAMomentObject
        processedObjects.push value
        formData.append key, value.toISOString()
        return true

    if value instanceof File
        throw new TypeError "nested Files are not supported, mvc modelbinder won't work with it"

    return false


# TODO: maybe move that to helpers or make it a jquery extension
_fillFormData = (formData, obj, objKey, processedObjects = []) ->

    return if obj is null or obj is undefined

    return if processedObjects.indexOf(obj) > -1
    processedObjects.push obj

    if obj instanceof Array
        index = 0
        for currentObj, i in obj
            # shrink the array if needed, so we won't have null values
            index = i
            if currentObj is null or currentObj is undefined
                index--
                continue

            currentKey = "#{objKey}[#{index}]"
            if _tryAppendSimpleTypeToFormData formData, currentKey, currentObj, processedObjects
                continue

            # go deep
            _fillFormData formData, currentObj, currentKey, processedObjects
        return

    # file list needs a different handling, mvc model binder bug workaround:
    if obj instanceof FileList
        #for currentObj, i in obj
        #    processedObjects.push currentObj
        #    formData.append "#{objKey}[#{i}]", currentObj
        #return
        throw new TypeError "FileList is not supported, mvc modelbinder won't work with it"

    for key, value of obj
        if value is null or value is undefined
            continue

        if (objKey)
            currentKey = "#{objKey}[#{key}]"
        else
            currentKey = key

        if _tryAppendSimpleTypeToFormData formData, currentKey, value, processedObjects
            continue

        # go deep
        _fillFormData formData, value, currentKey, processedObjects


uploadFiles = (url, files, additionalData, progressCallback) ->
    formData = _createFormData files, additionalData
    _formDataPost url, formData, progressCallback


antiForgeryUploadFiles = (url, files, additionalData, $ctx, progressCallback) ->
    formData = _createFormData files, additionalData
    formData.append '__RequestVerificationToken', readAntiForgeryHeader $ctx
    _formDataPost url, formData, progressCallback


_createFormData = (files, additionalData) ->
    unless files
        throw new Error 'files is null or undefined'

    formData = new FormData()

    # workaround for mvc modelbinder bugs:
    # - files can't be nested properly
    # - enumerables won't work with files
    # -> convention for mvc: MyController.MyAction(AdditionalData data, IFormFile file0, file1, file2, file3 = null, ... fileN = null)
    if files instanceof Array or files instanceof FileList
        for file, i in files
            if file instanceof File
                formData.append "file#{i}", file
    else
        # see if there is a file list embedded
        files2 = files['files']

        # handle { files: FileList, additionalData: data } objects
        unless additionalData
            additionalData = files['additionalData']

        if files2 instanceof Array or files2 instanceof FileList
            for file, i in files2
                if file instanceof File
                    formData.append "file#{i}", file
        else
            # use a custom binding, where the properties are the keys
            # and extract additionalData when needed
            additionalData2 = {}
            for key, value of files
                if value instanceof File
                    formData.append key, value
                else
                    additionalData2[key] = value

            # assign extracted data
            unless additionalData
                additionalData = additionalData2

    _fillFormData formData, additionalData

    return formData


_formDataPost = (url, formData, progressCallback) ->
    unless formData instanceof FormData
        throw new Error "formData is either not of type FormData or null or undefined"

    $.ajax
        type: 'POST'
        url: maybeAddBackend url
        processData: false
        contentType: false
        data: formData
        headers: getHeaders()
        xhr: ->
            customXhr = $.ajaxSettings.xhr()
            if customXhr.upload
                customXhr.upload.addEventListener('progress', progressCallback, false)
            return customXhr


formDataPost = (url, data, progressCallback) ->
    formData = new FormData()
    _fillFormData formData, data
    _formDataPost formData, url, data, progressCallback


antiForgeryPost = (url, data, $ctx, localizeData = true) ->
    unless data
        data = {}
    data['__RequestVerificationToken'] = readAntiForgeryHeader($ctx)
    post url, data, localizeData

antiForgeryPostJson = (url, data, $ctx) ->
    header =
        '__RequestVerificationToken': readAntiForgeryHeader($ctx)
    $.ajax
        type: 'POST'
        url: maybeAddBackend url
        data: JSON.stringify data
        contentType: 'application/json'
        headers: Object.assign(getHeaders(), header)

antiForgeryFormDataPost = (url, data, $ctx, progressCallback) ->
    formData = new FormData()
    _fillFormData formData, data
    formData.append '__RequestVerificationToken', readAntiForgeryHeader $ctx
    _formDataPost formData, url, data, progressCallback


readAntiForgeryHeader = ($ctx = $(document)) ->
    $ctx.find('input[name=__RequestVerificationToken]').val()

# TODO move to some helpers module
localizeObject = (obj) ->
    return _localizeObject $.extend(true, {}, obj)

# TODO move to some helpers module
# private method, only to be used with a cloned object and an array
_localizeObject = (obj, localizedObjects = []) ->

    # we don't need those
    if obj is null or obj is undefined
        return obj

    type = typeof obj

    # localizing yes, but not adding it to the localized list - possible collisions
    if type is 'number'
        localizedObjects.push obj
        return Globalize.formatNumber obj, {maximumFractionDigits: 20, useGrouping: false}

    if type is 'string'
        localizedObjects.push obj
        return obj

    if type is 'boolean'
        localizedObjects.push obj
        return obj

    # we could transform this into a moment and then localize it
    if obj instanceof Date
        localizedObjects.push obj
        return obj.toISOString()

    # had to check for a moment object differently.
    if obj._isAMomentObject
        localizedObjects.push obj
        return obj.toISOString()


    # only add complex objects to that list to prevent circular references
    # this also stops equal number values from not being localized
    if localizedObjects.indexOf(obj) > -1
        return obj

    # no need to localize files
    if obj instanceof File
        localizedObjects.push obj
        return obj

    # if it's an array
    if obj instanceof Array
        for item, index in obj
            obj[index] = _localizeObject item, localizedObjects

        return obj

    # not needed
    if obj instanceof FileList
        return obj

    # if it's an "object"
    for property, value of obj
        if value is null    # can't be undefined...
            continue
        # ignore "private" properties
        if property.indexOf('_') is 0
            continue

        obj[property] = _localizeObject value, localizedObjects

    return obj

module.exports = {
    get,
    getStatic,
    post,
    postJson,
    put,
    putJson,
    formDataPost,
    uploadFiles,
    antiForgeryPost,
    antiForgeryPostJson,
    antiForgeryFormDataPost,
    antiForgeryUploadFiles,
    readAntiForgeryHeader,
    localizeObject,
    maybeAddBackend
}
