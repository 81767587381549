# CoffeeScript

{resolve} = require 'src/js/infrastructure/setup-registry'
{waitscreen, notifications, backend} = require 'src/js/infrastructure'
{DataAPI} = require 'src/coffee/infrastructure/data-api'
{get, antiForgeryPost} = require 'src/coffee/infrastructure/ajax'

{ensureLocale} = require 'src/js/i18n'

{setPageInstance: setFragmentLoadingPageInstance} = require 'src/coffee/helpers/async/fragment-loading'
{reduceHeader, expandHeader} = require 'src/coffee/helpers/layout'

{setPageInstance: setInfoClickPageInstance} = require 'src/coffee/controls/map/plugins/info-click'
{setPageInstance: setModalPageInstance, clearModals} = require 'src/coffee/controls/modals'

{requireAreaScripts} = require './require-area-scripts'

{setupNotificationsPanel} = require 'src/coffee/infrastructure/ixmap/notifications-panel'

class Page
    constructor: (root, options = {}) ->
        setModalPageInstance this
        setFragmentLoadingPageInstance this
        setInfoClickPageInstance this

        @contentSelector = options.contentSelector or '#content'
        @ajaxContentSelector = options.ajaxContentSelector or '#default-ajax-target'

        @api = new DataAPI this

        $.ajaxSetup
            timeout: 6000000
            cache: false

        $(document).ajaxError @onError

        setTimeout @registerGlobalEvents, 0

        @setup(root)
        .then @updateLayoutAfterLoad
        .done -> waitscreen.hide()
        .fail -> waitscreen.show(true)

    updateLayoutAfterLoad: =>
        regex = /(?:[\/]{2})?[^\/]*?(\/(?!\/)\S*)/g
        match = regex.exec(location.href)
        if match and match.length == 2
            @updateLayout($(), match[1])

    setup: (root) ->
        # link hijacking
        $(root).on 'click.ajax-hijacking', "a[href]:not([href^=#],[href^='http://'],[href^='https://'],[href^='tel:'],[href^='mailto:'],[data-ajax='false'])", @onLinkClicked
        $(root).on 'click.ajax-hijacking-external', "a[href^='http://']:not([data-ajax='false']),a[href^='https://']:not([data-ajax='false'])", ->
            window.open $(this).attr('href'), '_blank'
            return false

        # bootstrap file input wrapper
        $(root).on 'change', '.btn-file input:file', (event) ->
            $input = $ this
            i = this.files?.length or 1
            label = $input.val().replace(/\\/g, '/').replace(/.*\//, '')
            $text = $input.parents('.input-group').find('input:text')
            if i > 1
                label = $text.data('msg')?.replace('{0}', i)

            $text.val label

        # controls in main content
        setupDone = $.Deferred()

        @ensureAreaChunkIsLoaded $(document)
        .then =>
            @executeCustomSetup $ '#scripts' # TODO investigate if this even makes sense

            @applySetupPipeline $('#content'),
                partial: false
                promise: setupDone.promise()
            .then =>
                $breadcrumbs = $('.navbar-breadcrumbs .container')
                if $breadcrumbs.length and $breadcrumbs[0].firstElementChild
                    @showBreadcrumbs()
            .then -> setupDone.resolve()

#        @observer ?= new MutationObserver @onDomChange
#
#        target = document.querySelector '#content'#'#default-ajax-target'
#        options =
#            childList: true
#            subtree: true
#
#        @observer.observe target, options

        $(window).on 'popstate', (event) =>
            return if location.pathname.startsWith '/DlgCert'

            @get location.href, false
            clearModals()
            return false

        return setupDone.promise()

#    onDomChange: (mutationRecords, observer) =>
#        console.log 'mutation event'
#        for record in mutationRecords
#            console.log 'mutation:'
#            console.log record.target
#            console.log record.addedNodes

    ensureAreaChunkIsLoaded: ($content) ->
        $area = $content.find('div[data-asp-area]')
        if not $area.length
            $area = $content.filter('div[data-asp-area]')
        area = $area.data('asp-area')
        $area.remove()

        deferred = $.Deferred()

        requireAreaScripts(area, deferred.resolve)

        return deferred.promise()

    setupFragment: ($fragment, options = {}) ->
        deferred = $.Deferred()

        options.partial ?= true
        options.promise ?= deferred.promise()

        $wrapper = $ '<div class="hidden">'
        .appendTo 'body'
        .append $fragment

        @applySetupPipeline $wrapper, options
        .then ->
            $fragment = $wrapper.contents().detach()
            $wrapper.remove()
            deferred.resolve($fragment)

        return deferred.promise()

    applySetupPipeline: ($fragmentWrapper, options) ->
        ensureLocale()
        .then => @api.scan $fragmentWrapper, options.promise
        .then => @transferBreadcrumbs $fragmentWrapper, options.partial
        .then => @executeCustomSetup $fragmentWrapper, options.promise
        .then => @transferModals $fragmentWrapper

    transferBreadcrumbs: ($fragmentWrapper, partial) ->
        $newBreadcrumbs = $fragmentWrapper.find '.hidden-breadcrumbs'

        $existingBreadcrumbs = $ '.navbar-breadcrumbs .container'

        if $newBreadcrumbs.length and $newBreadcrumbs.length and $newBreadcrumbs[0].firstElementChild
            $existingBreadcrumbs.empty().append $newBreadcrumbs.contents()

        if $newBreadcrumbs.length is 0 and not partial
            @hideBreadcrumbs()
        else
            @showBreadcrumbs()

    transferModals: ($fragmentWrapper) ->
        # note: doesn't work for download dialogs - would require a complete rewrite, that's why clearModals should be done first.
        $oldModals = $('#modals > .modal')
        $newModals = $fragmentWrapper.find('.modal:not(.keep-inline)')

        oldIds = (x.id for x in $oldModals)
        conflictingIds = (x.id for x in $newModals when x.id in oldIds)

        for id in conflictingIds
            $("#modals > [id=#{id}]").remove()

        $('#modals').append $newModals

    executeCustomSetup: ($fragmentWrapper, promise) ->
        alreadyExecuted = []

        $fragmentWrapper
        .find '.setup'
        for element in $fragmentWrapper.find '.setup'
            $element = $ element

            functionName = $element.text()
            setupFunction = resolve(functionName)

            if typeof setupFunction isnt 'function'
                console.log 'invalid setup function specified: ' + functionName
                continue

            if functionName in alreadyExecuted
                console.log "setup function '" + functionName + "' was specified more than once, duplicates ignored"
                continue

            setupFunction($fragmentWrapper, promise)
            alreadyExecuted.push functionName

    onLinkClicked: (event) =>
        $link = $ event.currentTarget
        url = $link.attr 'href'
        $('.dropdown-menu').remove()
        @get url
        return false

    post: (url, data, history = true, localize = true) ->
        promise = antiForgeryPost url, data, null, localize
        .done @onSuccess url, history

        delayedWaitscreen = =>
            waitscreen.waitFor promise if promise.state() is 'pending'

        setTimeout delayedWaitscreen, 150

        return promise

    get: (url, history = true) ->
        promise = get url
        .done @onSuccess url, history

        delayedWaitscreen = =>
            waitscreen.waitFor promise if promise.state() is 'pending'

        setTimeout delayedWaitscreen, 150

        return promise

    onSuccess: (url, history) ->
        return (data, textStatus, jqXHR) =>
            $data = $ data

            partial = not ($data.length is 1 and $data.attr('id') is 'content')

            $target = if partial then $('#default-ajax-target') else $('#content')

            unless partial
                $data = $data.contents()
                @updateLayout($data, url)

            #reason for last: ajaxlayout has a default title of "Welcome", preventing us from actually setting the title from the view
            title = $data.find('title').last().text()
            if title
                document.title = title

            addedToDom = $.Deferred()

            @ensureAreaChunkIsLoaded $data
            .then =>
                @setupFragment $data,
                    partial: partial
                    promise: addedToDom.promise()
                .then ($fragment) => @addFragmentToDom $fragment, $target
                .then => @record url if history and not (partial or backend.getServer())
                .done -> addedToDom.resolve()

    updateLayout: ($data, url) =>
        @location = url

        home = url.split('/').filter((x) -> x).length is 0

        if not home and not $('header').hasClass('reduced')
            setTimeout(reduceHeader, 500)
        if home and $('header').hasClass('reduced')
            setTimeout(expandHeader, 500)

        keepOldTree = $data.find('.easytree').data('keep-tree') and $('.easytree').data('tree-id') is $data.find('.easytree').data('tree-id')

        if keepOldTree
            $('#tree-container').appendTo($(document.body)).attr('id', 'previous-tree-container').hide()

    addFragmentToDom: ($fragment, $target) ->
        if $target.hasClass 'fade'
            fadeFinished = $.Deferred()
            $target.removeClass 'in'
            f = ->
                $target.addClass 'in'
                $target.find('.easytree').trigger 'unload'
                $target.empty().append($fragment)
                fadeFinished.resolve()
            setTimeout f, 200
            return fadeFinished.promise()
        else
            $target.find('.easytree').trigger 'unload'
            $target.empty().append($fragment)

    onError: (event, jqXHR, settings, thrownError) ->

        try
          hasJson = jqXHR.responseJSON or JSON.parse jqXHR.responseText

        unless hasJson
            notifications.criticalError jqXHR.responseText, thrownError
        return true

    record: (url, data = {}) ->
        if url
            # console.log "push with href=#{url}"
            history.pushState data, '', url

    showBreadcrumbs: ->
        $('.navbar-breadcrumbs').removeClass 'collapsed'
        # $('.combined-navigation-wrapper').css 'min-height', '120px'

    hideBreadcrumbs: ->
        $('.navbar-breadcrumbs').addClass 'collapsed'
        # $('.combined-navigation-wrapper').css 'min-height', ''

    showMarkers: ($markers) =>
        @hideMarkers()
        for marker, i in $markers
            $marker = $ marker
            $marker.addClass 'visible'
            $marker.text i + 1

            target = $marker.data 'target'
            $target = if target then $(target) else $marker.next()

            if not $target.length
                $target = $marker.parent()

            offset = $target.offset()

            offset.top -= 12
            offset.left -= 12

            $marker.offset offset

    showPageMarkers: (type) => @showMarkers $(".page-marker[type=#{type}][data-id]:not([data-step])")
    showPageMarkerSteps: (type, id) => @showMarkers $(".page-marker[type=#{type}][data-id=#{id}][data-step]")

    hideMarkers: ->
        $('.page-marker').removeClass('visible')

    registerGlobalEvents: =>
        setupNotificationsPanel $('#notifications')

module.exports.__esModule = true
module.exports.default = Page
