
export const currentLocalDistrict = (state) => {
  return state.currentLocalDistrict ? state.currentLocalDistrict : {}
}

export const hasCurrentLocalDistrict = (state) => {
  return !!state.currentLocalDistrict
}

export const localDistricts = (state) => {
  return state.localDistricts ? state.localDistricts : []
}

export const currentArea = (state) => {
  return state.currentArea ? state.currentArea : {}
}

export const hasCurrentArea = (state) => {
  return !!state.currentArea
}

export const currentCadastralDistrict = (state) => {
  return state.currentCadastralDistrict
}

export const hasCurrentCadastralDistrict = (state) => {
  return !!state.currentCadastralDistrict
}

export const cadastralDistricts = (state) => {
  return state.cadastralDistricts ? state.cadastralDistricts : []
}

export const currentLandParcelNumber = (state) => {
  return state.currentLandParcelNumber
}

export const landParcelNumbers = (state) => {
  return state.landParcelNumbers ? state.landParcelNumbers : []
}

export const segments = (state) => {
  return state.segments ? state.segments : []
}

export const selectedSegments = (state) => {
  return state.selectedSegments ? state.selectedSegments : []
}

export const isEditAreaModalOpen = (state) => {
  return state.isEditAreaModalOpen
}

export const isDeleteAreaModalOpen = (state) => {
  return state.isDeleteAreaModalOpen
}
