export const contactTypes = (state) => {
  return state.contactTypes ? state.contactTypes : []
}

export const countries = (state) => {
  return state.countries ? state.countries : []
}

export const countriesWithRegions = (state) => {
  return state.countriesWithRegions[state.currentSelectedCountryUID] ? state.countriesWithRegions[state.currentSelectedCountryUID] : []
}
