import queryData from '../api/queryData'
import newGuid from 'src/js/helpers/new-guid'

export const selectArea = ({commit}, area) => {
  commit('selectArea', area)
}

export const createAreaRequest = ({commit}) => {
  let newArea = {
    uid: newGuid(),
    landParcelIdentifier: '',
    landParcelNumber: '',
    sizeActual: 0,
    sizeContractual: 0,
    usage: {},
    groundpoints: 0,
    fkLandParcel: '',
    hasAreaChanges: false
  }

  commit('openEditArea', newArea)
}

export const editAreaRequest = ({commit}, area) => {
  commit('selectArea', area)
  commit('updateContractualArea', area.sizeContractual)
  commit('realEstateContractLease/amendments/updateContractualArea', area, {root: true})
  commit('realEstateContractLease/contracts/setContractAsChanged', null, {root: true})
  commit('realEstateContractLease/contracts/setAreasChanged', null, {root: true})
}

export const createOrEditAreaSuccess = ({commit, state}) => {
  let payload = {
    segments: state.selectedSegments,
    localDistrict: state.currentLocalDistrict,
    cadastralDistrict: state.currentCadastralDistrict
  }

  commit('realEstateContractLease/amendments/addAreasToAmendment', payload, {root: true})
  commit('closeEditArea')
  commit('resetAreas')

  commit('realEstateContractLease/contracts/setContractAsChanged', null, {root: true})
  commit('realEstateContractLease/contracts/setAreasChanged', null, {root: true})
}

export const addSegmentsFromCreateModalToLeaseContract = ({commit}, landparcel) => {
  commit('realEstateContractLease/amendments/addNewlyCreatedSegmentsToAmendment', landparcel, {root: true})

  commit('realEstateContractLease/contracts/setContractAsChanged', null, {root: true})
  commit('realEstateContractLease/contracts/setAreasChanged', null, {root: true})
}

export const createOrEditAreaFailure = ({commit}) => {
  commit('closeEditArea')
  commit('resetAreas')
}

export const deleteAreaRequest = ({commit, state}) => {
  if (!state.currentArea) {
    throw new Error('No area selected')
  }
  commit('openDeleteArea')
}

export const deleteAreaSuccess = ({commit, state}) => {
  if (!state.currentArea) {
    throw new Error('No area selected')
  }

  commit('realEstateContractLease/amendments/deleteAreaFromAmendment', state.currentArea, {root: true})
  commit('closeDeleteArea')

  commit('realEstateContractLease/contracts/setContractAsChanged', null, {root: true})
  commit('realEstateContractLease/contracts/setAreasChanged', null, {root: true})
}

export const deleteAreaFailure = ({commit}) => {
  commit('closeDeleteArea')
}

export const selectedLocalDistrict = ({commit, rootGetters}, localDistrict) => {
  if (!localDistrict) {
    commit('selectedLocalDistrict', {localDistrict: null, cadastralDistrict: []})
  } else { // chane localDistrict.value to localDistrict.uid of local distict is ra model type and not a item from picker anymore
    queryData.getCadastralDistrictsByLocalDistrict(localDistrict.value, rootGetters['realEstateContractLease/navigation/selectedOrgUnit'].tenantId, (cadastralDistricts) => {
      commit('selectedLocalDistrict', {localDistrict, cadastralDistricts})
    })
  }
}

export const selectedCadastralDistrict = ({commit, rootGetters}, cadastralDistrict) => {
  if (!cadastralDistrict) {
    commit('selectedCadastralDistrict', {cadastralDistrict: null, landParcelNumbers: [], segments: []})
  } else {
    queryData.getLandParcelNumbersByCadastralDistrict(cadastralDistrict.uid, rootGetters['realEstateContractLease/navigation/selectedOrgUnit'].tenantId, (landParcelNumbers) => {
      queryData.getSegmentsByLandParcelNumberId(cadastralDistrict.uid, null, (segments) => {
        commit('selectedCadastralDistrict', {cadastralDistrict, landParcelNumbers, segments})
      })
    })
  }
}

export const selectLandParcelNumber = ({commit}, {cadastralDistrict, landParcelNumber}) => {
  if (!landParcelNumber) {
    queryData.getSegmentsByLandParcelNumberId(cadastralDistrict.uid, null, (segments) => {
      commit('selectLandParcelNumber', {landParcelNumber: null, segments: segments})
    })
  } else {
    queryData.getSegmentsByLandParcelNumberId(cadastralDistrict.uid, landParcelNumber.uid, (segments) => {
      commit('selectLandParcelNumber', {landParcelNumber, segments})
    })
  }
}

export const selectSegments = ({commit}, segments) => {
  if (!segments) return

  commit('selectSegments', segments)
}
