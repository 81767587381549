import Vue from 'vue'

const mutations = {
  recieveContacts (state, contacts) {
    state.contacts = contacts
  },
  selectContact (state, contact) {
    state.currentContact = contact
    state.isEditChanged = false
  },
  createNewContactRequest (state, orgUnitId) {
    state.currentContact = null

    state.newContact = {
      type: null,
      fkDataOwner: orgUnitId,
      name: '',
      nameAdditional: '',
      address: {
        street: '',
        postOfficeBox: '',
        country: null,
        region: null,
        postalCode: '',
        city: '',
        localCentre: ''
      },
      communications: {
        phone: '',
        phoneSecond: '',
        phoneThird: '',
        fax: '',
        faxSecond: '',
        eMail: '',
        eMailSecond: '',
        website: ''
      },
      info: '',
      bankingAccounts: []
    }

    state.isCreateContact = true
    state.isEditChanged = false
  },

  createNewContactSuccess (state, contactId) {
    if (!state.newContact) {
      throw new Error('No new contact for processing')
    }

    state.newContact.uid = contactId

    Vue.set(state, 'currentContact', state.newContact)
    state.isCreateContact = false

    state.newContact = null
    state.isEditChanged = false
  },
  createNewContactFailure (state) {
    if (!state.newContact) {
      throw new Error('No new contact for processing')
    }

    state.isCreateContact = false

    state.newContact = null
    state.isEditChanged = false
  },

  editContactRequest (state) {
    if (!state.currentContact) {
      throw new Error('No contact selected')
    }

    state.isEditContact = true
    state.isEditChanged = false
  },

  editContactSuccess (state) {
    if (!state.currentContact) {
      throw new Error('No contact selected')
    }

    state.isEditContact = false
    state.isEditChanged = false
  },
  editContactFailure (state) {
    state.isEditContact = false

    state.currentContact = null
    state.isEditChanged = false
  },
  removeCurrentContact (state) {
    Vue.delete(state.currentContact, 'contact')
  },
  addOrUpdateBankingAccountToContact (state, bankingAccount) {
    const currentContact = state.currentContact ? state.currentContact : state.newContact

    if (!currentContact) {
      throw new Error('No contact selected')
    }

    const index = currentContact.bankingAccounts.findIndex(x => x.uid === bankingAccount.uid)

    if (index < 0) {
      currentContact.bankingAccounts.push(bankingAccount)
    } else {
      Vue.set(currentContact.bankingAccounts, index, bankingAccount)
    }

    state.isEditChanged = true
  },
  removeBankingAccountFromContact (state, bankingAccount) {
    const currentContact = state.currentContact ? state.currentContact : state.newContact

    if (!currentContact) {
      throw new Error('No contact selected')
    }

    const index = currentContact.bankingAccounts.findIndex(x => x.uid === bankingAccount.uid)
    currentContact.bankingAccounts.splice(index, 1)

    state.isEditChanged = true
  },
  setContactAsChanged (state) {
    state.isEditChanged = true
  },
  setContactAsUnchanged (state) {
    state.isEditChanged = false
  }
}

export default mutations
