import axios from 'axios'
import store from '@store'

export function asQueuedRequest (options, f) {
  // const {id} = options
  // console.log(`[asQueuedRequest] ${id}, starting`)

  return store.dispatch('dataLoading/start', options)
  .then(startOptions => {
    const {denied, done, waitInQueue, originalInputs} = startOptions

    if (denied) {
      // console.log(`[asQueuedRequest] ${id}, start denied`)
      return
    }

    // console.log(`[asQueuedRequest] ${id}, start accepted, calling inner function`)

    return f({waitInQueue, originalInputs})
      .then(result => {
        // console.log(`[asQueuedRequest] ${id}, inner function finished, finishing request`)
        done()
        return result
      })
      .catch(error => {
        // console.error(`[asQueuedRequest] ${id}, inner function errored, finishing request`)
        done(error)
        throw error
      })
  })
}

const replaceInputPlaceholders = (url, inputs) => Object.keys(inputs)
  .reduce((url, key) => url.replace(new RegExp(`{${key}}`, 'g'), inputs[key]), url)

function smarterAxiosRequest (urlOrUrls, options, axiosOptions) {
  const {sharedCache, onResult} = options

  return asQueuedRequest(options, ({originalInputs, waitInQueue}) => {
    // console.log(`[smarterGet] ${id}, starting`)
    const urlToRequest = url => axios({
      url: replaceInputPlaceholders(url, originalInputs),
      ...axiosOptions
    }).then(response => response.data)

    const requestPromise = Array.isArray(urlOrUrls)
      ? Promise.all(urlOrUrls.map(urlToRequest))
      : urlToRequest(urlOrUrls)

    return requestPromise.then(result => {
      // console.log(`[smarterGet] ${id}, request finished`)
      return waitInQueue.then(({inputsChanged}) => {
        // console.log(`[smarterGet] ${id}, no longer waiting, processing results`)
        if ((/* discardResultOnInputChange || */sharedCache) && inputsChanged) {
          // console.log(`[smarterGet] ${id}, inputs changed, discarding result`)
          return
        }

        if (onResult) {
          const maybePromise = onResult(result)

          const definitelyPromise = maybePromise instanceof Promise
            ? maybePromise
            : Promise.resolve(maybePromise)

          return definitelyPromise.then(finalResult => {
            // console.log(`[smarterGet] ${id}, result callback finished, returning to action`)
            return finalResult
          })
        } else {
          // console.log(`[smarterGet] ${id}, no result callback, returning to action`)
          return result
        }
      })
    })
  })
}

export const smarterGet = (urlOrUrls, options) =>
  smarterAxiosRequest(urlOrUrls, options, Object.assign({method: 'get'}, options.axios))
export const smarterDelete = (urlOrUrls, options) =>
  smarterAxiosRequest(urlOrUrls, options, Object.assign({method: 'delete'}, options.axios))
export const smarterPost = (urlOrUrls, data, options) =>
  smarterAxiosRequest(urlOrUrls, {...options, expiry: 0}, Object.assign({method: 'post', data}, options.axios))
export const smarterPut = (urlOrUrls, data, options) =>
  smarterAxiosRequest(urlOrUrls, {...options, expiry: 0}, Object.assign({method: 'put', data}, options.axios))
