import Vue from 'vue'

const requestHistoryDefaults = () => ({
  running: [],
  completed: [],
  error: null
})

const makeInputEquivalencyComparer = (a, validAncestors = []) => b => {
  const equivalent = key => validAncestors.length && key === 'orgUnitId'
    ? a[key] === b[key] || validAncestors.includes(b[key])
    : a[key] === b[key]

  return a && b && Object.keys(a).every(equivalent)
}

const getAncestorIds = entity => {
  const ancestorIds = []
  while (entity) {
    if (entity.parent) {
      ancestorIds.push(entity.parent.id)
    }
    entity = entity.parent
  }
  return ancestorIds
}

let counter = 0

export default {
  namespaced: true,
  state: {
    requests: {},
    requestsById: {},
    runningCounts: {},
    erroredRequests: {}
  },
  mutations: {
    start (state, {id, requestId, requestData}) {
      if (!state.requests[id]) {
        Vue.set(state.requests, id, requestHistoryDefaults())
      }

      state.requests[id].running.push(requestData)

      Vue.set(state.runningCounts, id, (state.runningCounts[id] || 0) + 1)
      Vue.set(state.requestsById, requestId, requestData)
    },
    finish (state, {requestId, error}) {
      const requestData = state.requestsById[requestId]

      const {id, startedAt, originalInputs} = requestData

      const {running, completed} = state.requests[id]

      if (!running.length) {
        throw new Error(`[dataLoading/finish] request ${requestId} is trying to finish, but no requests are running`)
      }

      running.shift()

      if (running.length) {
        running[0].stopWaitingInQueue()
      }

      Vue.set(state.runningCounts, id, state.runningCounts[id] - 1)
      Vue.delete(state.requestsById, requestId)

      state.requests[id].lastCompletedInputs = originalInputs
      if (error) {
        state.requests[id].error = true
        Vue.set(state.erroredRequests, id, true)
      } else {
        state.requests[id].error = null
        Vue.delete(state.erroredRequests, id)
      }

      const inputsAreIdentical = makeInputEquivalencyComparer(originalInputs)
      const requestsWithDifferentInputs = x => !inputsAreIdentical(x.originalInputs)

      const completionData = {
        originalInputs,
        startedAt,
        completedAt: Date.now(),
        error
      }

      state.requests[id].completed = completed.filter(requestsWithDifferentInputs).concat([completionData])
    },
    invalidate (state, id) {
      const {completed} = state.requests[id] || requestHistoryDefaults()

      completed.forEach(request => {
        request.completedAt = -Infinity
      })

      Vue.delete(state.erroredRequests, id)
    }
  },
  actions: {
    start ({state, commit, rootState, rootGetters}, {id, expiry = 0, inputs = {}, sharedCache, subtreeInclusive}) {
      const {running, completed} = state.requests[id] || requestHistoryDefaults()

      const getCurrentInputs = () => Object.keys(inputs)
        .reduce((allInputs, key) => Object.assign(allInputs, {[key]: inputs[key](rootState)}), {})

      const now = Date.now()
      const freshnessThreshold = now - expiry * 1000

      const originalInputs = getCurrentInputs()

      const considerHierarchy = subtreeInclusive && originalInputs.orgUnitId

      const getRelevantAncestors = () => {
        if (!considerHierarchy) return []

        const entities = rootGetters['fieldRecordSystem/allEntities']
        const entity = entities.find(x => x.id === originalInputs.orgUnitId)
        return getAncestorIds(entity)
      }

      const relevantAncestors = getRelevantAncestors()

      const inputsAreIdentical = makeInputEquivalencyComparer(originalInputs)
      const inputsCoverSameData = makeInputEquivalencyComparer(originalInputs, relevantAncestors)

      const precedingRequests = completed.filter(x => !x.error).concat(running)

      // check running requests for redundancy
      const equivalentPrecedingRequests = precedingRequests.reduce((requests, requestData) => {
        const isEquivalent = sharedCache
          ? inputsAreIdentical(requestData.originalInputs)
          : inputsCoverSameData(requestData.originalInputs)

        if (isEquivalent) {
          return requests.concat([requestData])
        }
        if (sharedCache && !isEquivalent) {
          // for shared cache location only requests newer than the last non-equivalent request can be considered, since those non-equivalent requests would overwrite the cache with incorrect data
          return []
        }
        return requests
      }, [])

      const lastEquivalentRequest = equivalentPrecedingRequests[equivalentPrecedingRequests.length - 1]

      // variant 1: accept any running request as fresh enough
      const dataStillFresh = lastEquivalentRequest &&
        (!lastEquivalentRequest.completedAt || lastEquivalentRequest.completedAt > freshnessThreshold)
      // variant 2: only accept running requests if they were started after the freshness threshold
      // const dataStillFresh = lastEquivalentRequest &&
      //   (lastEquivalentRequest.completedAt > freshnessThreshold || lastEquivalentRequest.startedAt > freshnessThreshold)

      if (dataStillFresh) {
        // console.log(`[start] ${id} denied`)
        return {
          denied: true,
          done: () => {},
          originalInputs,
          waitInQueue: Promise.resolve({
            inputsChanged: false
          })
        }
      }

      // console.log(`[start] ${id} accepted`)

      const requestId = counter++

      let resolveWaitInQueue

      const waitInQueue = new Promise(resolve => {
        resolveWaitInQueue = resolve
      })

      const stopWaitingInQueue = () => {
        const finalInputs = getCurrentInputs()
        const inputsChanged = Object.keys(finalInputs).some(key => originalInputs[key] !== finalInputs[key])
        // TODO add info if new inputs are hierarchically contained in old inputs or other way around
        resolveWaitInQueue({inputsChanged})
      }

      const isFirstRequest = !running.length

      const requestData = {
        id,
        originalInputs,
        stopWaitingInQueue,
        startedAt: now,
        expiry
      }

      commit('start', {id, requestId, requestData})

      if (isFirstRequest) {
        resolveWaitInQueue({
          inputsChanged: false
        })
      }

      const done = error => {
        commit('finish', {requestId, error})
      }

      return {
        denied: false,
        done,
        originalInputs,
        waitInQueue
      }
    }
  }
}
