import axios from 'axios'

export default {
  namespaced: true,
  state: {
    selectedRecommendedProductIds: [],
    selectedProductIds: []
  },
  getters: {
  },
  mutations: {
    setSelectedRecommendedProductIds (state, productId) {
      if (state.selectedRecommendedProductIds.includes(productId)) {
        const index = state.selectedRecommendedProductIds.findIndex(id => id === productId)
        if (index < 0) return
        state.selectedRecommendedProductIds.splice(index, 1)
      } else {
        state.selectedRecommendedProductIds.push(productId)
      }
    },
    setSelectedProductIds (state, productId) {
      if (state.selectedProductIds.includes(productId)) {
        const index = state.selectedProductIds.findIndex(id => id === productId)
        if (index < 0) return
        state.selectedProductIds.splice(index, 1)
      } else {
        state.selectedProductIds.push(productId)
      }
    },
    resetSelectedProductIds (state) {
      state.selectedProductIds = []
    },
    resetSelectedRecommendedProductIds (state) {
      state.selectedRecommendedProductIds = []
    }
  },
  actions: {
    setRecommendedProducts ({rootState, state, commit}, ids) {
      if (!ids.length) return

      ids.forEach(id => commit('masterData/setRecommendedState', id, {root: true}))

      return axios.post('/api/v2/master-data/products/recommended/insert', ids)
      .then(() => {
        commit('resetSelectedProductIds')
      })
      .catch(() => {
        ids.forEach(id => commit('masterData/setRecommendedState', id, {root: true}))
      })
    },
    deleteRecommendedProducts ({rootState, state, commit}, ids) {
      if (!ids.length) return

      ids.forEach(id => commit('masterData/setRecommendedState', id, {root: true}))

      return axios.post('/api/v2/master-data/products/recommended/delete', ids)
      .then(() => {
        commit('resetSelectedRecommendedProductIds')
      })
      .catch(() => {
        ids.forEach(id => commit('masterData/setRecommendedState', id, {root: true}))
      })
    }
  }
}
