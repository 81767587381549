# CoffeeScript

findNode = (nodes, condition) ->
    for node in nodes
        if condition(node)
            return node

        continue unless node.children

        result = findNode node.children, condition

        return result if result
    return null


findParent = (nodes, childNodeId) ->
    for node in nodes
        continue unless node.children
        for child in node.children
            if child.id is childNodeId
                return node
            continue unless child.children
            result = findParent node.children, childNodeId
            return result if result
    return null


getTree = -> $('.easytree').first().data('easytree')


applyRecursive = (nodes, f) ->
    for node in nodes or []
        f node

        applyRecursive node.children, f


deleteFromTree = (id) ->
    tree = getTree()
    nodes = tree.getAllNodes()
    node = findNode nodes, (n) -> n.data.uid is id
    tree.removeNode node
    tree.rebuildTree()


openNodeChain = (nodes, id) ->
    # depth first shortcut on find search
    for node in nodes
        if node.data.uid == id
            node.isExpanded = true
            node.isActive = false
            return node
        else
            continue unless node.children

            foundNode = openNodeChain(node.children, id)
            if(foundNode)
                node.isExpanded = true
                return foundNode

    return undefined


selectNode = (id) ->
    tree = getTree()
    nodes = tree.getAllNodes()
    targetNode = openNodeChain(nodes, id)
    tree.rebuildTree()
    if(targetNode)
        $('#' + targetNode.id).click()


module.exports = {
    findNode,
    findParent,
    getTree,
    applyRecursive,
    deleteFromTree,
    selectNode
}
