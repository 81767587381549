import axios from 'axios'
import {lookupById} from '@helpers/reducers'
import {flattenEntityForest, filters} from '@helpers/tree'

export default {
  namespaced: true,
  state: {
    tree: []
  },
  getters: {
    tree (state) {
      return state.tree
    },
    flattenedEntities (state) {
      return flattenEntityForest(state.tree)
    },
    entityLookup (state, getters) {
      return getters.flattenedEntities.reduce(lookupById, {})
    },
    parentLookup (state, getters) {
      const lookup = {}
      getters.flattenedEntities.filter(filters.orgUnits).forEach(orgUnit => {
        orgUnit.children.forEach(child => {
          lookup[child.id] = orgUnit.id
        })
      })
      return lookup
    }
  },
  mutations: {
    setTree (state, tree) {
      state.tree = tree
    }
  },
  actions: {
    async loadTree ({commit, rootState}) {
      const {currentView} = rootState.poultryManagement.ui
      return axios.get(`/api/PoultryManagement/tree/${currentView}`)
        .then(response => response.data)
        .then(tree => commit('setTree', tree))
    }
  }
}
