import {get, postJson} from 'src/coffee/infrastructure/ajax'
import axios from 'axios'

const convertToNode = (orgUnit) => {
  return {
    uid: orgUnit.content ? orgUnit.content.uid : '',
    name: orgUnit.content ? orgUnit.content.name : '',
    template: orgUnit.content ? orgUnit.content.template : '',
    archived: orgUnit.content ? orgUnit.content.archived : '',
    children: (orgUnit.children !== undefined) ? orgUnit.children.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0).map(convertToNode) : []
  }
}

export default {
  cancelContracts (orgUnitId, contractIds) {
    return postJson('/RealEstate/Lease/CancelContracts', {entityId: orgUnitId, contractIds: [...contractIds]})
  },
  getAppointmentByOrgUnitId ({orgUnitId, start, end}, cb) {
    get('/RealEstate/Appointment/GetAppointmentsForEntity', {orgUnitId: orgUnitId, start: start, end: end})
    .done((result) => cb(result))
    .fail(() => {
      cb(new Array(0))
    })
  },
  getCanEditAppointment (orgUnitId, cb) {
    get('/RealEstate/Appointment/GetCanEditAppointment', {orgUnitId})
    .done((result) => cb(result))
    .fail(() => {
      // eslint-disable-next-line standard/no-callback-literal
      cb(false)
    })
  },
  getTreeNodes (cb) {
    get('/RealEstate/Contract/Tree')
    .done((result) => {
      cb(result.map(convertToNode).sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
    })
    .fail(() => {
      // eslint-disable-next-line standard/no-callback-literal
      cb(false)
    })
  },
  extendContracts ({contractIds}) {
    return axios.post('/RealEstate/Lease/ExtendContracts', contractIds)
  }
}
