const mutations = {
  recieveAppointments (state, appointments) {
    state.appointments = appointments
  },
  canEditAppointments (state, canEdit) {
    state.canEditAppointments = canEdit
  },
  selectAppointments (state, appointments) {
    state.currentAppointments = appointments || []
  }
}

export default mutations
