export default {
  blank: '',
  overview: 'Overview',
  error: 'Error',
  instableFlock: 'InstableFlock',
  outstableFlock: 'OutstableFlock',
  flockManagement: 'FlockManagement',
  feed: 'ManageFeed',
  feeds: 'FeedManagement',
  poultryHouseManagement: 'PoultryHouseManagement'
}
