import {makeSetters} from '@helpers/vuex/mutations'

export default {
  namespaced: true,

  state: {
    current: {
      commonData: {},
      facilityData: {},
      financeData: {},
      landParcelData: {},
      permissions: {
        purchaseContractEditFinances: null,
        purchaseContractEditGeneral: null,
        purchaseContractEditLandParcel: null,
        purchaseContractViewFacilities: null,
        purchaseContractViewFinances: null,
        purchaseContractViewGeneral: null,
        purchaseContractViewLandParcel: null
      }
    },

    total_area: 0,
    number_contracts: 0,
    number_landparcels: 0,
    avg_by_localdistrict: []
  },

  actions: {
  },

  mutations: {
    ...makeSetters([
      'current.commonData',
      'current.facilityData',
      'current.financeData',
      'current.landParcelData'
    ]),

    addPartnerB (state) {
      state.current.commonData.partnerB.push({})
    },

    removePartnerB (state, partner) {
      state.current.commonData.partnerB = state.current.commonData.partnerB.filter(item => item.value !== partner.value)
    },

    setLandParcelPrice (state, {parcelId, price, currency}) {
      const lp = state.current.landParcelData.find(parcel => parcel.uid === parcelId)
      lp.price = price
      lp.currency = currency
    },

    addLandParcel (state, landParcel) {
      state.current.landParcelData.push(landParcel)
    },

    removeLandParcel (state, landParcels) {
      const filteredList = state.current.landParcelData.filter(lp => !landParcels.includes(lp))
      state.current.landParcelData = filteredList
    },

    addFacility (state, facility) {
      state.current.facilityData.push(facility)
    },

    deleteFacilities (state, facilities) {
      const filteredList = state.current.facilityData.filter(facility => !facilities.includes(facility))
      state.current.facilityData = filteredList
    },

    addPayment (state, payment) {
      state.current.financeData.rates.push(payment)
    },

    deletePayments (state, payments) {
      const filteredList = state.current.financeData.rates.filter(payment => !payments.includes(payment))
      state.current.financeData.rates = filteredList
    },

    addInvoice (state, invoice) {
      state.current.financeData.invoiceNumbers.push(invoice)
    },

    deleteInvoices (state, invoices) {
      const filteredList = state.current.financeData.invoiceNumbers.filter(invoice => !invoices.includes(invoice))
      state.current.financeData.invoiceNumbers = filteredList
    },

    resetContractSummary (state) {
      state.number_contracts = 0
      state.number_landparcels = 0
      state.total_area = 0
      state.avg_by_localdistrict = []
    },

    updateSalesSummary (state, data) {
      state.number_contracts = data.summaries.number_contracts
      state.number_landparcels = data.summaries.number_landparcels
      state.total_area = data.summaries.total_area
      state.avg_by_localdistrict = data.avg_by_localdistrict
    },

    updatePurchaseSummary (state, data) {
      state.number_contracts = data.summaries.number_contracts
      state.number_landparcels = data.summaries.number_landparcels
      state.total_area = data.summaries.total_area
      state.avg_by_localdistrict = data.avg_by_localdistrict
    }
  }
}
