import Vue from 'vue'

export function defineResources (state, resources) {
  for (const locale in state.resources) {
    for (const resource of resources) {
      if (!state.resources[locale][resource]) {
        Vue.set(state.resources[locale], resource, {})
      }
    }
  }
}

export function loadTranslations (state, translations) {
  for (const locale in translations) {
    if (!state.resources[locale]) {
      throw new Error(`Locale ${locale} is not supported, cannot load resources`)
    }
    for (const resource in translations[locale]) {
      const existingTranslations = state.resources[locale][resource] || {}
      const updatedTranslations = Object.assign(existingTranslations, translations[locale][resource])
      Vue.set(state.resources[locale], resource, updatedTranslations)
    }
  }
}

export function addMissingTranslation (state, {namespace, key}) {
  const locale = state.locale

  if (!state.missingTranslations[locale]) {
    Vue.set(state.missingTranslations, locale, {})
  }
  if (!state.missingTranslations[locale][namespace]) {
    Vue.set(state.missingTranslations[locale], namespace, new Set())
  }
  if (!state.missingTranslations[locale][namespace].has(key)) {
    state.missingTranslations[locale][namespace].add(key)
  }
}

export function clearMissingTranslations (state) {
  state.missingTranslations = {}
}

export function changeLocale (state, locale) {
  if (state.supportedLocales.includes(locale)) {
    state.locale = locale
    state.translations = state.resources[locale]
  } else {
    throw new Error(`locale '${locale}' not supported`)
  }
}

export function toggleEditMode (state) {
  state.editMode = !state.editMode
}

export function startEditing (state, {key, history, context}) {
  const newValues = state.supportedLocales.reduce((obj, locale) => Object.assign(obj, {[locale]: null}), {})
  state.editing = {
    key,
    history,
    newValues,
    context
  }
}

export function setTranslation (state, {lang, value}) {
  const values = {...state.editing.newValues}

  if (!value) {
    delete values[lang]
  } else {
    values[lang] = value
  }

  state.editing.newValues = values
}

export function stopEditing (state) {
  state.editing = {
    key: null,
    history: null,
    newValues: null
  }
}

export function rememberUsage (state, fullKey) {
  console.error(`[CORE-877] not implemented yet, would remember usage of '${fullKey}' now`)
}
