import axios from 'axios'
import epsgCodes from 'src/js/data/epsg-codes'

export default {
  namespaced: true,
  state: {
    userEpsgCodes: []
  },
  getters: {
    epsgCodes (state) {
      return epsgCodes.map(x => x.code).concat(state.userEpsgCodes)
    }
  },
  mutations: {
    addEpsgCode (state, code) {
      state.userEpsgCodes.push(code)
    }
  },
  actions: {
    guessEpsgForShape ({commit}, files) {
      if (!files) return

      let bodyFormData = new FormData()
      const fileArray = [...files]
      fileArray.forEach(file => {
        bodyFormData.append('files', file)
      })

      return axios.post('/api/v2/geo/shape/epsg', bodyFormData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => {
        if (response.data) {
          const code = parseInt(response.data)
          if (!epsgCodes.find(x => x.code === code)) {
            commit('addEpsgCode', code)
          }
          return code
        }
      })
      .catch(error => {
        console.error(error)
        return null
      })
    }
  }
}
