import moment from 'moment'

import loadCldr from './loaders/cldr'
import loadDataTables from './loaders/datatables-translations'
import loadJQueryValidate from './loaders/jquery-validate-translations'
import loadLocale from './loaders/locale-cookie'

import store from '@store'
import {recreate as recreateParsersAndFormatters} from './conversion'

const dataTablesTranslations = {}
const loadedLanguages = []

let currentLocale = null
let currentLanguage = null

async function loadLanguageData ({language}) {
  const alreadySet = language === currentLanguage
  const alreadyLoaded = loadedLanguages.includes(language)

  const cacheDataTablesTranslations = translations => {
    dataTablesTranslations[language] = translations
  }

  await Promise.all([
    alreadySet || alreadyLoaded ? null : loadCldr(language),
    alreadySet || alreadyLoaded ? null : loadDataTables(language).then(cacheDataTablesTranslations),
    alreadySet ? null : loadJQueryValidate(language)
  ])

  loadedLanguages.push(language)
}

const setLanguage = ({locale, language}) => {
  moment.locale(language)
  Globalize.locale(language)
  currentLocale = locale
  currentLanguage = language
}

const ensureLocale = () => {
  const desiredLocale = loadLocale()

  return changeLocale(desiredLocale)
}

async function changeLocale (desiredLocale) {
  await loadLanguageData(desiredLocale)
  setLanguage(desiredLocale)
  recreateParsersAndFormatters()
  store.commit('i18n/changeLocale', desiredLocale.locale)
}

const getDataTablesTranslations = () => dataTablesTranslations[currentLanguage]
const getCurrentLocale = () => currentLocale
const getCurrentLanguage = () => currentLanguage

export {
  ensureLocale,
  changeLocale,
  getDataTablesTranslations,
  getCurrentLocale,
  getCurrentLanguage
}
