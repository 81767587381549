import moment from 'moment'
import axios from 'axios'

function prefixWithNamespace (value) {
  return 'Areas.PoultryManagement.SR_PoultryManagement.' + value
}

const instance = axios.create({
  baseURL: '/api/PoultryManagement/production/poultryHouse'
})
const state = {
  farmId: '',
  poultryHouseNumber: 0,
  numberFemales: 0,
  numberMales: 0,
  dailyMessages: [],
  flocks: [],
  contactName: '',
  contactPhone: '',
  dayOfLiving: 0,
  genderSpecificFoodDate: moment()
}

const getters = {
  getStableNumber (state) { return state.poultryHouseNumber },
  getNumberOfFemales (state) { return state.numberFemales },
  getNumberOfMales (state) { return state.numberMales },
  getDailyMessages (state) { return state.dailyMessages },
  getContactName (state) { return state.contactName },
  getContactPhone (state) { return state.contactPhone },
  getFlocks (state) { return state.flocks },
  getDayOfLiving (state) { return state.dayOfLiving },
  getGenderSpecificFoodDate (state) { return state.genderSpecificFoodDate }
}

const mutations = {
  // TODO find a better way to load and set data
  setData (state, value) {
    for (let prop in value) {
      if (prop === 'feeds' || prop === 'antibiotica') continue
      state[prop] = value[prop]
    }
    state.antibiotica.antibioticas = value.antibioticas.map(anti => ({from: moment(anti.from), to: moment(anti.to)}))
    state.feed.feeds = value.feeds.map(feed => ({timeRange: moment(feed.timeRange), amountFemale: feed.amountFemale, amountMale: feed.amountMale}))
  },
  setContactName (state, value) { state.contactName = value },
  setContactPhone (state, value) { state.contactPhone = value },
  setDayOfLiving (state, value) { state.dayOfLiving = value },
  setGenderSpecificFoodDate (state, value) { state.genderSpecificFoodDate = value }
}

const actions = {
  submit ({state}) {
    return instance.post('', {
      farmId: state.farmId,
      poultryHouseNumber: state.poultryHouseNumber,
      flocks: state.flocks,
      contactName: state.contactName,
      contactPhone: state.contactPhone,
      dayOfLiving: state.dayOfLiving,
      genderSpecificFoodDate: state.genderSpecificFoodDate.toISOString(true),
      antibioticas: state.antibiotica.antibioticas,
      feeds: state.feed.feeds
    })
  }
}

const antibiotica = {
  state: {
    antiboticasColumns: {
      from: {title: prefixWithNamespace('from'), sortable: false},
      to: {title: prefixWithNamespace('to'), sortable: false},
      actions: {title: '', sortable: false, localize: false}
    },
    antibioticas: []
  },
  getters: {
    getAntiboticasColumns (state) { return state.antiboticasColumns },
    getAntibioticas (state) { return state.antibioticas }
  },
  mutations: {
    addUseOfAntibiotica (state) {
      state.antibioticas.push({
        from: moment.utc(),
        to: moment.utc().add(1, 'day')
      })
    },
    deleteAntiboticaUse (state, antibiotica) {
      const deleteIndex = state.antibioticas.indexOf(antibiotica)
      if (feed === -1) return
      state.antibioticas.splice(deleteIndex, 1)
    },
    updateFromDate (state, {item, from}) {
      const idx = state.antibioticas.indexOf(item)
      if (idx === -1) return
      state.antibioticas[idx].from = from
    },
    updateToDate (state, {item, to}) {
      const idx = state.antibioticas.indexOf(item)
      if (idx === -1) return
      state.antibioticas[idx].to = to
    }
  }
}

const feed = {
  state: {
    feedColumns: {
      timeRange: {title: prefixWithNamespace('timeRange'), sortable: false},
      amountFemale: {title: prefixWithNamespace('amountFemale'), sortable: false},
      amountMale: {title: prefixWithNamespace('amountMale'), sortable: false},
      actions: {title: '', sortable: false, localize: false}
    },
    feeds: []
  },
  getters: {
    getFeedColumns (state) { return state.feedColumns },
    getFeeds (state) { return state.feeds }
  },
  mutations: {
    addFeed (state) {
      const compare = (left, right) => left.timeRange.isSame(right.timeRange)
        ? 0
        : left.timeRange.isBefore(right.timeRange)
          ? 1
          : -1
      const startDate = state.feeds.length === 0
        ? moment()
        : moment(state.feeds.sort(compare)[0].timeRange).add(1, 'day')

      state.feeds.push({
        timeRange: startDate,
        amountFemale: 0,
        amountMale: 0
      })
    },
    deleteFeed (state, feed) {
      const deleteIndex = state.feeds.indexOf(feed)
      if (feed === -1) return
      state.feeds.splice(deleteIndex, 1)
    },
    updateTimeRange (state, {item, timeRange}) {
      const idx = state.feeds.indexOf(item)
      if (idx === -1) return
      state.feeds[idx].timeRange = timeRange
    },
    updateAmountFemale (state, {item, newAmount}) {
      const idx = state.feeds.indexOf(item)
      if (idx === -1) return
      state.feeds[idx].amountFemale = newAmount
    },
    updateAmountMale (state, {item, newAmount}) {
      const idx = state.feeds.indexOf(item)
      if (idx === -1) return
      state.feeds[idx].amountMale = newAmount
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules: {
    antibiotica,
    feed
  }
}
