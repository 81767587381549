import queryData from '../api/queryData'

export const selectOrgUnit = ({commit}, {orgUnit, start, end}) => {
  commit('selectOrgUnit', {uid: orgUnit.uid})
  queryData.getAppointmentByOrgUnitId({orgUnitId: orgUnit.uid, start, end}, (appointments) => {
    commit('realEstateContractLeaseAppointment/appointments/recieveAppointments', appointments, {root: true})
  })
  queryData.getCanEditAppointment(orgUnit.uid, (result) => {
    commit('realEstateContractLeaseAppointment/appointments/canEditAppointments', result, {root: true})
  })
}
