import {makeSetters} from '@helpers/vuex/mutations'
import newGuid from '@helpers/new-guid'

export default {
  namespaced: true,
  state: {
    current: {
      localDistrict: null,
      cadastralDistrict: null,
      number: null,
      businessObject: null,
      size: 0,
      segmentations: [{
        segments: []
      }],
      owner: null,
      landRegisterCommunity: null,
      landRegister: null,
      remark: null,
      mortgages: [],
      easements: [],
      contracts: [],
      history: [],
      landParcelPredecessors: [],
      landParcelSuccessors: [],
      assignedEntities: []
    },

    totalEntitlement: 0,

    entitlementByUsage: [],

    entitlementByType: []
  },

  actions: {

  },

  getters: {

  },

  mutations: {
    ...makeSetters([
      'current.localDistrict',
      'current.cadastralDistrict',
      'current.number',
      'current.businessObject',
      'current.size',
      'current.segmentations',
      'current.owner',
      'current.landRegisterCommunity',
      'current.landRegister',
      'current.remark'
    ]),

    appendSegment (state, segment) {
      segment.uid = newGuid()
      state.current.segmentations[0].segments = state.current.segmentations[0].segments.concat(segment)
    },

    removeSegment (state, segment) {
      state.current.segmentations[0].segments = state.current.segmentations[0].segments.filter(seg => seg.uid !== segment.uid)
    },

    updateSegment (state, segment) {
      const without = state.current.segmentations[0].segments.filter(seg => seg.uid !== segment.uid)
      state.current.segmentations[0].segments = without.concat(segment)
    },

    appendMortgage (state, mortgage) {
      mortgage.uid = newGuid()
      state.current.mortgages = state.current.mortgages.concat(mortgage)
    },

    removeMortgage (state, mortgage) {
      state.current.mortgages = state.current.mortgages.filter(seg => seg.uid !== mortgage.uid)
    },

    updateMortgage (state, mortgage) {
      const without = state.current.mortgages.filter(seg => seg.uid !== mortgage.uid)
      state.current.mortgages = without.concat(mortgage)
    },

    appendEasement (state, easement) {
      easement.uid = newGuid()
      state.current.easements = state.current.easements.concat(easement)
    },

    removeEasement (state, easement) {
      state.current.easements = state.current.easements.filter(seg => seg.uid !== easement.uid)
    },

    updateEasement (state, easement) {
      const without = state.current.easements.filter(seg => seg.uid !== easement.uid)
      state.current.easements = without.concat(easement)
    },

    addSuccessor (state, successor) {
      state.current.landParcelSuccessors = state.current.landParcelSuccessors.concat(successor)
    },

    removeSuccessor (state, successor) {
      state.current.landParcelSuccessors = state.current.landParcelSuccessors.filter(suc => suc.uid !== successor.uid)
    },

    addPredecessor (state, predecessor) {
      state.current.landParcelPredecessors = state.current.landParcelPredecessors.concat(predecessor)
    },

    removePredecessor (state, predecessor) {
      state.current.landParcelPredecessors = state.current.landParcelPredecessors.filter(pre => pre.uid !== predecessor.uid)
    },

    setAggregationData (state, data) {
      state.totalEntitlement = data.entitlementByType.total
      state.entitlementByUsage = data.entitlementByUsage
      state.entitlementByType = [
        {type: 'Eigentum', sum: data.entitlementByType.owned},
        {type: 'Gepachtet', sum: data.entitlementByType.leased},
        {type: 'Getauscht', sum: '0'}]
    }
  }
}
