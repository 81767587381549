const getters = {

  availableDmsReferenceTypes (state) {
    return state.masterData.dmsReferenceTypes
  },

  availableCountries (state) {
    return state.masterData.countries
  },

  availableUsageTypes (state) {
    return state.masterData.usageTypes
  },

  availableCurrencies (state) {
    return state.masterData.currencies
  },

  availableAreas (state) {
    return state.areas.available
  },

  currentArea (state) {
    return state.areas.current
  },

  availableOrgUnits (state) {
    return state.orgUnits.available
  },

  currentOrgUnit (state) {
    return state.orgUnits.current
  },

  leftSidebarPinned (state) {
    return state.ui.leftSidebarPinned
  },

  totalItemsForEntity (state) {
    return state.tableData.totalItemsForEntity
  },

  rightSidebarPinned (state) {
    return state.ui.rightSidebarPinned
  },

  rows (state) {
    return state.tableData.rows
  },

  selection (state) {
    return state.tableData.selection
  },

  tableSelectionData (state) {
    return state.tableData.selectionData
  },

  endOfDataReached (state) {
    return state.tableData.endOfDataReached
  },

  currentState (state) {
    if (state && state.areas && state.areas.current && (state.areas.current.id === 'purchases' || state.areas.current.id === 'sales')) {
      return state['contract']
    } else if (state && state.areas && state.areas.current && state[state.areas.current.id]) {
      return state[state.areas.current.id].current
    } else return null
  }
}

export default getters
