import moment from 'moment'

const isNotValid = (value) => {
  return value == null || value === undefined || value === ''
}

export default {
  validateContract (contract) {
    const errorMessages = []

    const validate = (validationSubject, category, errorMessage) => {
      if (isNotValid(validationSubject)) {
        errorMessages.push({category: category, errorMsg: errorMessage})
      }
    }

    validate(contract.certificateNumber, 'Common', 'Allgemein Tab: Urkundennummer fehlt')
    validate(contract.contractDate, 'Common', 'Allgemein Tab: Vertragsdatum fehlt')

    contract.amendments.forEach((amendment) => {
      if (isNotValid(amendment.leaser) || amendment.leaser.length === 0) {
        errorMessages.push({category: 'Common', errorMsg: `Allgemein Tab: Nachtrag "${moment(amendment.validOn).format('LL')}" Pächter fehlt`})
      } else {
        amendment.leaser.forEach((leaserEntry) => {
          if (isNotValid(leaserEntry) || isNotValid(leaserEntry.value)) {
            errorMessages.push({category: 'Common', errorMsg: `Allgemein Tab: Nachtrag "${moment(amendment.validOn).format('LL')}" Pächter fehlt`})
          }
        })
      }
      if (isNotValid(amendment.lessor) || amendment.lessor.length === 0) {
        errorMessages.push({category: 'Common', errorMsg: `Allgemein Tab: Nachtrag "${moment(amendment.validOn).format('LL')}" Verpächter fehlt`})
      } else {
        amendment.lessor.forEach((lessorEntry) => {
          if (isNotValid(lessorEntry) || isNotValid(lessorEntry.value)) {
            errorMessages.push({category: 'Common', errorMsg: `Allgemein Tab: Nachtrag "${moment(amendment.validOn).format('LL')}" Verpächter fehlt`})
          }
        })
      }
    })

    if (contract.paymentRecipients) {
      contract.paymentRecipients.forEach((recipient) => {
        validate(recipient.recipient, 'Financial', 'Zahlungen Tab: Zahlungsempfänger fehlt (Zahlungsempfänger)')
        validate(recipient.iban, 'Financial', 'Zahlungen Tab: IBAN fehlt (Zahlungsempfänger)')
        validate(recipient.paymentMode, 'Financial', 'Zahlungen Tab: Zahlungsmodus fehlt (Zahlungsempfänger)')
        validate(recipient.currency, 'Financial', 'Zahlungen Tab: Währung fehlt (Zahlungsempfänger)')
        validate(recipient.typeOfPayment, 'Financial', 'Zahlungen Tab: Art der Zahlung fehlt (Zahlungsempfänger)')

        if (isNotValid(recipient.flatSum) || isNotValid(recipient.share) || (recipient.flatSum > 0 && recipient.share > 0) || (recipient.flatSum === 0 && recipient.share === 0)) {
          errorMessages.push({category: 'Financial', errorMsg: 'Zahlungen Tab: Es muss genau eines von "Anteil" und "Summe je Zahlung" größer als 0 und das andere gleich 0 sein (Zahlungsempfänger)'})
        }
      })
    }

    if (contract.payments) {
      contract.payments.forEach((payment) => {
        validate(payment.recipient, 'Financial', 'Zalungen Tab: Zahlungsempfänger fehlt (Zahlung)')
        validate(payment.currency, 'Financial', 'Zahlungen Tab: Währung fehlt (Zahlung)')
        validate(payment.dateDue, 'Financial', 'Zahlungen Tab: Fälligkeitsdatum fehlt (Zahlung)')
        validate(payment.typeOfPayment, 'Financial', 'Zahlungen Tab: Art der Zahlung fehlt (Zahlung)')
        validate(payment.currency, 'Financial', 'Zahlungen Tab: Währung fehlt (Zahlung)')

        if (isNotValid(payment.amount) || payment.amount <= 0) {
          errorMessages.push({category: 'Financial', errorMsg: 'Zahlungen Tab: Betrag fehlt oder ungültig (Zahlung)'})
        }
      })
    }

    contract.terms.forEach((term) => {
      validate(term.currency, 'RunTimes', 'Laufzeiten Tab: Währung fehlt')
      validate(term.start, 'RunTimes', 'Laufzeiten Tab: Startdatum fehlt')
      validate(term.end, 'RunTimes', 'Laufzeiten Tab: Vertragsende fehlt')
      validate(term.leasePrice, 'RunTimes', 'Laufzeiten Tab: Pachtpreis fehlt')
    })

    return errorMessages
  }
}
