import translate from 'src/js/i18n/translate'

export default function (key) {
  switch (key) {
  case 'pack':
  case 'pack/ha':
  case 'piece':
  case 'piece/ha':
  case 'K/m²':
  case 'K/ha':
  case 'plants':
  case 'plants/m²':
    return translate(`frs.units.${key}`)
  default:
    return key
  }
}
