# CoffeeScript

{copySelectionToOtherGrid} = require 'src/coffee/controls/grid/helpers'
{setup: setupCombobox} = require './setup-functions/combobox'
{setup: setupForm} = require './setup-functions/form'
{setup: setupTree} = require './setup-functions/tree'
{setup: setupDatetimepicker} = require './setup-functions/datetimepicker'
{setup: setupColorPicker} = require './setup-functions/color-picker'
{setup: setupRangePicker} = require './setup-functions/range-picker'

class DataAPI
    constructor: (page) ->
        @page = page

    scan: ($ctx, promise) =>
        defaultOptions = ->
            promises:
                addedToDom: promise

        dataAttributeLookup =
            datetimepicker: setupDatetimepicker
            grid: (parameters) => @asyncSetup parameters, 'grid'
            'grid-copy-selection-button': @setupGridCopySelectionButton
            combobox: setupCombobox
            'range-picker': setupRangePicker
            tree: setupTree
            mapcontrol: (parameters) => @asyncSetup parameters, 'map'
            'color-picker': setupColorPicker

        invalidSetupFunctionWarning = (controlType) ->
            console.warn("Invalid data-setup-control value: #{controlType}")

        getSetupFunction = (element) =>
            controlType = element.getAttribute 'data-setup-control'
            if controlType
                return dataAttributeLookup[controlType] or invalidSetupFunctionWarning controlType
            if element.getAttribute('data-toggle') is 'popover'
                return @setupPopover
            if element.tagName is 'FORM'
                return setupForm

        applySetupFunction = (element) ->
            f = getSetupFunction(element)
            parameters = {
                $ctx,
                $element: $(element),
                options: defaultOptions()
            }
            return f?(parameters)

        promises = $ctx.find('[data-setup-control], [data-toggle="popover"], form')
        .toArray()
        .map applySetupFunction

        return $.when promises...

    asyncSetup: (parameters, controlType) ->
        deferred = $.Deferred()

        execute = (setup) ->
            setup parameters
            deferred.resolve()

        switch controlType
            when 'grid'
                require.ensure './setup-functions/grid', ->
                    {setup} = require './setup-functions/grid'
                    execute setup
            when 'map'
                require.ensure './setup-functions/map', ->
                    {setup} = require './setup-functions/map'
                    execute setup

        return deferred.promise()

    setupGridCopySelectionButton: ({$element, options, $ctx = $(document)}) ->
        from = '#' + $element.data 'from'
        to = '#' + $element.data 'to'
        compareData = $element.data 'compare-data'

        $element.on 'click', (event) ->
            copySelectionToOtherGrid from, to, compareData

        options.promises.addedToDom.then ->
            grid = $(from).staticgrid()[0]
            grid.bindToSelection $element
        #setTimeout bindToGrid, 0

        return $.Deferred().resolve().promise()

    setupPopover: ({$element, options, $ctx = $(document)}) ->
        $content = $element.children '.popover-content'
        if $content.length
            $element.attr 'data-content', $content.html()
            $element.attr 'data-html', 'true'
            $content.remove()

        timeout = $element.data 'timeout'

        popover = $element.popover()

        # automagically hides the popover after the given time
        if parseInt(timeout) > 0
            popover.on 'mouseleave', ->
                setTimeout ->
                    $element.popover 'destroy'

                    # add a second timeout to prevent double clicking misbehavior
                    setTimeout ->
                        $element.popover()
                    , 200
                , timeout

        else
            $element.popover()

        return $.Deferred().resolve().promise()

hidePopover = ($element) ->
    $element.popover('hide')


module.exports = {
    DataAPI
}

module.exports.__esModule = true
module.exports.default = DataAPI
