import Vue from 'vue'
import {makeSetters} from '@helpers/vuex/mutations'

import makeTemplate from './make-template'

// import newGuid from 'src/js/helpers/new-guid'

const mutations = {
  ...makeSetters([
    'ui.groupKey',
    'cropTypes',

    // common product props
    'editBuffer',
    'editBuffer.name',
    'editBuffer.note',
    'editBuffer.tags',

    // protection props
    'editBuffer.category',
    'editBuffer.activeSubstance',
    'editBuffer.approvalNumber',
    'editBuffer.usage',

    // seed props
    'editBuffer.cropId',
    'editBuffer.comment',
    'editBuffer.quality',
    'editBuffer.minSeedRate',
    'editBuffer.maxSeedRate',
    'editBuffer.maizeMatrix',
    'editBuffer.bsA_Culture',
    'editBuffer.bsA_Culture_Short',
    'editBuffer.bsA_Number',
    'editBuffer.mixtureComponents',

    // fertilizer props
    'editBuffer.isOrganic',
    'editBuffer.isLiquid',
    'editBuffer.short',
    'editBuffer.density',
    'editBuffer.nitrogen',
    'editBuffer.phosphorus',
    'editBuffer.magnesium',
    'editBuffer.lime',
    'editBuffer.potassium',
    'editBuffer.sulfur',
    'editBuffer.iron',
    'editBuffer.manganese',
    'editBuffer.zinc',
    'editBuffer.boron',
    'editBuffer.copper',
    'editBuffer.molybdenum',
    'editBuffer.drySubstrateAmount',
    'editBuffer.ammonium',
    'editBuffer.nitrate',
    'editBuffer.nitrogenAmide',
    'editBuffer.isComposted',
    'editBuffer.consistency',
    'editBuffer.fertilizerSource',
    'editBuffer.tanContent',
    'editBuffer.minimumEfficiency'
  ]),
  deleteVulnerability (state, vulnerability) {
    Vue.delete(state.editBuffer.vulnerabilities, vulnerability)
    if (!Object.keys(state.editBuffer.vulnerabilities).length) {
      state.editBuffer.vulnerabilities = null
    }
  },
  setVulnerability (state, vulnerability) {
    if (!state.editBuffer.vulnerabilities) {
      Vue.set(state.editBuffer,
        'vulnerabilities',
        {})
    }
    Vue.set(state.editBuffer.vulnerabilities,
      vulnerability.name,
      {
        characteristic: vulnerability.name,
        value: vulnerability.value,
        cropId: null,
        productId: null
      })
  },
  setVulnerabilityValue (state, vulnerability) {
    state.editBuffer.vulnerabilities[vulnerability.name].value = vulnerability.value
  },
  setSelectedProductGroup (state, type) {
    state.ui.selectedType = type
    state.ui.selectedProductIds = []
    state.ui.groupKey = null
  },
  createNewProduct (state, {orgUnitId, source} = {}) {
    state.editBuffer = makeTemplate(state.ui.selectedType)

    if (source) {
      state.editBuffer.source = source
    }
    if (orgUnitId) {
      state.editBuffer.orgUnitId = orgUnitId
    }

    state.ui.selectedProductIds = []
  },
  selectExclusive (state, {id, products}) {
    state.ui.selectedProductIds = [id]
    state.editBuffer = JSON.parse(JSON.stringify(products[id]))
    state.ui.productHash = Object.values(state.editBuffer).join(',')
  },
  clearSelection (state) {
    state.ui.selectedProductIds = []
    state.editBuffer = null
  },
  toggleSelection (state, {id, products}) {
    const index = state.ui.selectedProductIds.indexOf(id)

    if (index === -1) {
      state.ui.selectedProductIds.push(id)
    } else {
      state.ui.selectedProductIds.splice(index, 1)
    }

    if (state.ui.selectedProductIds.length === 1) {
      const product = products[state.ui.selectedProductIds[0]]
      state.editBuffer = JSON.parse(JSON.stringify(product))
    } else {
      state.editBuffer = null
    }
  }
}

export default mutations
