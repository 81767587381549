import contacts from './contacts'
import bankingAccounts from './bankingAccounts'
import navigation from './navigation'
import save from './save'

import mutations from './mutations'
import * as getters from './getter'
import * as actions from './actions'

const state = {
  contactTypes: [],
  countries: [],
  countriesWithRegions: [],
  currentSelectedCountryUID: null
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules: {
    contacts,
    bankingAccounts,
    navigation,
    save
  }
}
