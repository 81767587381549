/**
 * Mocking client-server processing
 */

import {get} from 'src/coffee/infrastructure/ajax'
// import moment from 'moment'

const convertToNode = (orgUnit) => {
  return {
    uid: orgUnit.content ? orgUnit.content.uid : '',
    name: orgUnit.content ? orgUnit.content.name : '',
    template: orgUnit.content ? orgUnit.content.template : '',
    archived: orgUnit.content ? orgUnit.content.archived : '',
    children: (orgUnit.children !== undefined && orgUnit.children.filter !== undefined) ? orgUnit.children.filter(x => x.children).map(convertToNode) : []
  }
}

export default {
  getContactsByOrgUnitId (orgUnitId, cb) {
    get('/RealEstate/Contacts/LoadContacts', {orgUnitId: orgUnitId})
    .done((result) => {
      cb(result)
    })
    .fail(() => {
      cb(new Array(0))
    })
  },

  getContactById (uid, cb, errorCb) {
    get('/RealEstate/Contacts/LoadContact', {id: uid})
    .done((result) => {
      cb(result)
    })
    .fail(errorCb)
  },

  getTreeNodes (cb) {
    get('/RealEstate/Tenants')
    .done((result) => {
      cb(result.map(convertToNode))
    })
    .fail(() => {
      cb(new Array(0))
    })
  },

  getPaymentModes (entityId, cb) {
    get('/RealEstate/PaymentMode/GetPaymentModes', {entityId})
    .done((result) => cb(result))
    .fail(() => {
      cb(new Array(0))
    })
  },

  getContactTypes (cb) {
    get('/RealEstate/Contacts/ContactTypes')
    .done((result) => cb(result))
    .fail(() => {
      cb(new Array(0))
    })
  },

  getBanksForCurrentTenant (orgUnitId, cb) {
    get('/RealEstate/MasterDataBank/GetBanksSelectize', {entityId: orgUnitId})
    .done((result) => cb(result))
    .fail(() => {
      cb(new Array(0))
    })
  },

  getCountries (cb) {
    get('/RealEstate/Country/GetSelectableCountries')
    .done((result) => cb(result))
    .fail(() => {
      cb(new Array(0))
    })
  },

  getCountriesWithRegions (cb) {
    get('/RealEstate/Region/CountriesWithRegions')
    .done((result) => cb(result))
    .fail(() => {
      cb(new Array(0))
    })
  },

  getCurrentTenant (orgUnitId, cb) {
    get(`/RealEstate/Tenants/GetCurrentTenant?orgUnitId=${orgUnitId}`)
    .done((result) => cb(result))
    .fail(() => {
      cb(null)
    })
  }
}
