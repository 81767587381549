import navigation from './navigation'
import mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'

const state = {
  payments: []
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules: {
    navigation
  }
}
