# CoffeeScript

require 'legacy-scripts/vendor/jquery/jquery.easytree.js'

{applyRecursive} = require('./helpers')

do (jQuery) ->
    jQuery.fn.tree = (options) ->
        $element = $ this

        existing = $element.data 'easytree'
        return existing if existing

        if options.process
            applyRecursive options.data, (node) -> options.process(node)

        easytree = $element.easytree options
        $element.data 'easytree', easytree
        return easytree
