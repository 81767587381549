import {get} from 'src/coffee/infrastructure/ajax'
import moment from 'moment'

const convertToNode = (orgUnit) => {
  return {
    uid: orgUnit.content ? orgUnit.content.uid : '',
    name: orgUnit.content ? orgUnit.content.name : '',
    template: orgUnit.content ? orgUnit.content.template : '',
    archived: orgUnit.content ? orgUnit.content.archived : '',
    children: (orgUnit.children !== undefined) ? orgUnit.children.map(convertToNode).sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0) : []
  }
}
export default {
  getPayments ({orgUnitId, start, end, onlyUnpaid}, cb) {
    get('/RealEstate/Payments/GetPayments', {entityId: orgUnitId, from: moment(start).local().format(), to: moment(end).local().format(), onlyUnpaid: onlyUnpaid})
    .done((result) => {
      cb(result)
    })
    .fail(() => {
      cb(new Array(0))
    })
  },
  getTreeNodes (cb) {
    get('/RealEstate/Contract/Tree')
    .done((result) => {
      cb(result.map(convertToNode).sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
    })
    .fail(() => {
      cb(new Array(0))
    })
  }
}
