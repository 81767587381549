import queryData from '../api/queryData'

export const selectOrgUnit = ({commit}, orgUnit) => {
  commit('realEstateContractLease/contracts/selectContract', null, {root: true})
  if (orgUnit) {
    var prom = queryData.getCurrentTenant(orgUnit.uid, (currentTenantId) => {
      commit('selectOrgUnit', {
        id: orgUnit.uid,
        tenantId: currentTenantId
      })
    })

    queryData.getPaymentModes(orgUnit.uid, (paymentModes) => {
      commit('realEstateContractLease/finances/recievePaymentModes', paymentModes, {root: true})
    })

    return prom
  } else {
    return Promise.resolve(commit('selectOrgUnit', null))
  }
}
