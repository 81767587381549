import IxRes from '@components/IxRes'

import i18n from './i18n'

export let Vue
let installed
let $i18n

export default {
  install (_Vue, options) {
    if (installed && _Vue === Vue) return

    installed = true

    Vue = _Vue
    const {store} = options

    $i18n = new Vue({
      ...i18n,
      store
    })

    Object.defineProperty(Vue.prototype, '$i18n', {
      get () {
        return $i18n
      }
    })

    Vue.component('IxRes', IxRes)
  }
}
