import {makeSetters} from '@helpers/vuex/mutations'

export default {
  namespaced: true,

  state: {
    current: {
      uid: null,
      name: '',
      type: null,
      property: null,
      potentialForUse: null,
      photovoltaicSystemState: null,
      region: '',
      remark: '',
      dmsReferences: [],
      parts: null,
      coverageAmountAllocatedProportionally: 0,
      insuranceRelatedData: ''
    }
  },

  actions: {
  },

  mutations: {
    ...makeSetters([
      'current.uid',
      'current.name',
      'current.type',
      'current.property',
      'current.potentialForUse',
      'current.photovoltaicSystemState',
      'current.region',
      'current.remark',
      'current.dmsReferences',
      'current.parts',
      'current.coverageAmountAllocatedProportionally',
      'current.insuranceRelatedData'
    ])
  }
}
