import Vue from 'vue'

import {followPath, splitPropertyPath, splitPropertyPathSegments} from './common'

export const makeSetters = propertyPaths => propertyPaths.reduce((mutations, propertyPath) => {
  const propertyName = splitPropertyPath(propertyPath)[1]

  const name = `set${propertyName[0].toUpperCase()}${propertyName.substring(1)}`

  mutations[name] = set(propertyPath)

  return mutations
}, {})

export function set (propertyPath) {
  const [path, propertyName] = splitPropertyPath(propertyPath)

  return (state, value) => {
    const object = followPath(state, path)

    object[propertyName] = value
  }
}

export function push (propertyPath) {
  const path = splitPropertyPathSegments(propertyPath)

  return (state, value) => {
    const object = followPath(state, path)

    object.push(value)
  }
}

export function extend (propertyPath) {
  const path = splitPropertyPathSegments(propertyPath)

  return (state, value) => {
    const object = followPath(state, path)

    object.push(...value)
  }
}

export function assign (propertyPath) {
  const path = splitPropertyPathSegments(propertyPath)

  return (state, value) => {
    const object = followPath(state, path)

    if (Array.isArray(value)) {
      value.forEach(x => {
        Vue.set(object, x.id, x)
      })
    } else {
      for (const key in value) {
        Vue.set(object, key, value[key])
      }
    }
  }
}
