import {pick} from 'lodash'

import {
  levels,
  determineLevel,
  nutrientRemovalProperties,
  productShareProperties
} from './helpers'

const getLevelFromId = (rootState, id) => {
  if (rootState.masterData.cropHarvestQualities[id]) {
    return 'quality'
  }
  if (rootState.masterData.cropUsages[id]) {
    return 'usage'
  }
  if (rootState.masterData.crops[id]) {
    return 'crop'
  }
  return null
}

export function combinedLookup (state, getters, rootState) {
  return Object.assign(
    {},
    rootState.masterData.crops,
    rootState.masterData.cropUsages,
    rootState.masterData.cropHarvestQualities
  )
}

export function location (state, getters, rootState) {
  const selectionLevel = getLevelFromId(rootState, state.selection)
  const bufferLevel = determineLevel(state.editBuffer)

  const item = getters.combinedLookup[state.selection] || {}

  return {
    selectionLevel,
    bufferLevel,
    cropId: item.cropUsageId
      ? getters.combinedLookup[item.cropUsageId].cropId
      : item.cropId || item.id || null,
    cropUsageId: item.cropId ? item.id : item.cropUsageId || null,
    cropHarvestQualityId: item.cropUsageId ? item.id : null
  }
}

export function creationContext (state, getters) {
  // NOTE currently always new child of selected
  const level = getters.location.selectionLevel === 'quality'
    ? 'quality'
    : levels[levels.indexOf(getters.location.selectionLevel) + 1]

  if (!level) return null

  const parentId = level === 'quality'
    ? getters.location.cropUsageId
    : state.selection

  return {
    level,
    parentId
  }
}

export function countsById (state, getters, rootState, rootGetters) {
  const lookup = {
    null: {
      crop: rootGetters['masterData/cropArray'].length,
      usage: rootGetters['masterData/cropUsageArray'].length,
      quality: rootGetters['masterData/cropHarvestQualityArray'].length
    }
  }

  const ensureCrop = id => {
    if (!lookup[id]) {
      lookup[id] = {usage: 0, quality: 0}
    }
  }

  const ensureUsage = id => {
    if (!lookup[id]) {
      lookup[id] = {quality: 0}
    }
  }

  for (const crop of rootGetters['masterData/cropArray']) {
    ensureCrop(crop.id)
  }
  for (const usage of rootGetters['masterData/cropUsageArray']) {
    if (!lookup[usage.id]) {
      lookup[usage.id] = {quality: 0}
    }
    ensureCrop(usage.cropId)
    lookup[usage.cropId].usage++
  }
  for (const quality of rootGetters['masterData/cropHarvestQualityArray']) {
    const usageId = quality.cropUsageId

    ensureUsage(usageId)
    lookup[usageId].quality++

    const cropId = getters.combinedLookup[usageId] ? getters.combinedLookup[usageId].cropId : null

    if (cropId) {
      ensureCrop(cropId)
      lookup[cropId].quality++
    }
  }

  return lookup
}

export function nutrientRemoval (state) {
  return pick(state.editBuffer, nutrientRemovalProperties)
}

export function productShares (state) {
  return pick(state.editBuffer, productShareProperties)
}
