import mutations from './mutations'
// import * as actions from './actions'
import * as getters from './getters'

const state = {
  appointments: [],
  currentAppointments: [],
  canEditAppointments: false
}

export default {
  namespaced: true,
  state,
  mutations,
  // actions,
  getters
}
