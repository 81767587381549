import {format, parse, afterRecreate} from 'src/js/i18n/conversion'
import translate from 'src/js/i18n/translate'
import translateUnit from 'src/js/i18n/translate-unit'

export default {
  data () {
    return {
      parse,
      format
    }
  },
  methods: {
    translate,
    translateUnit
  },
  created () {
    Object.assign(this, {format, parse})

    this.removeListener = afterRecreate(({parse, format}) => {
      Object.assign(this, {parse, format})
    })
  },
  beforeDestroy () {
    this.removeListener()
  },
  render () {
    return null
  }
}
