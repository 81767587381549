require 'selectize'

{getCurrentLanguage} = require 'src/js/i18n'

comboboxLabeledOption = (labelField) ->
    return (data, escape) ->
        text = escape text
        label = escape data[labelField]
        return """
            <div>
                <span>#{escape(data.text)}</span>
                <span class='combobox-option-label'>#{label}</span>
            </div>
        """

comboboxLabeledItem = (labelField) ->
    return (data, escape) ->
        text = escape text
        label = escape data[labelField]

        if data.label
            return """
                <div>
                    <span>#{escape(data.text)}</span>
                    <span class='combobox-item-label'>#{escape(data.label)}</span>
                </div>

            """
        else
            return """
                <div>
                    <span>#{escape(data.text)}</span>
                </div>
            """

setup = ({$element, options, $ctx = $(document)}) ->
    selectOptions = []
    optionGroups = []

    makeOption = (element, group) ->
        $option = $ element
        value = $option.val()
        object =
            text: $option.text()
            value: if not value then null else Parse value
        if group
            object.optgroup = group.value

        return $.extend object, $option.data()

    for e in $element.children 'option'
        selectOptions.push makeOption e

    for e in $element.children 'optgroup'
        $group = $ e
        group =
            label: $group.attr 'label'
            value: $group.attr('value') or $group.attr('label')
        for o in $group.children('option')
            selectOptions.push makeOption o, group

    dataOptions =
        options: selectOptions
        create: $element.data 'create'
        maxItems: $element.data('max-selected')
        selectOnTab: true

    labelField = $element.data 'label'

    if labelField
        dataOptions.render =
            option: comboboxLabeledOption(labelField)
            item: comboboxLabeledItem(labelField)
        dataOptions.searchField = ['text', labelField]

    multipleSelection = $element.prop('multiple') or (dataOptions.maxItems and dataOptions.maxItems > 1)

    if multipleSelection
        dataOptions.plugins = {'remove_button': {label: '<i class="fa fa-times"></i>'}}

    options = $.extend {}, dataOptions, options

    $element.selectize options

    if multipleSelection and options.maxItems
        maxSelectedMessage = switch getCurrentLanguage()
            when 'de' then 'Auswahllimit erreicht'
            when 'en' then 'Selection limit reached'

        $maxSelectionInfo = $ '<div class="selectize-max-selected-message fade">'
        .text maxSelectedMessage
        .prependTo $element.next()

        $element.on 'change', ->
            selectize = $element[0].selectize
            if selectize.items.length is options.maxItems
                $maxSelectionInfo.addClass 'in'
            else
                $maxSelectionInfo.removeClass 'in'

    return $.Deferred().resolve().promise()


module.exports = {
    setup
}

module.exports.__esModule = true
module.exports.default = setup
