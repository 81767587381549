requireAreaScripts = (area, done) ->
    switch area
        when undefined
            done()
        when 'frs'
            require.ensure '@frs/registration', ->
                require '@frs/registration'
                done()
        when 'vehicle-fleet'
            require.ensure 'src/coffee/areas/vehicle-fleet', ->
                require 'src/coffee/areas/vehicle-fleet'
                done()
        when 'dashboard'
            require.ensure 'src/coffee/areas/dashboard', ->
                require 'src/coffee/areas/dashboard'
                done()
        when 'data-transfer'
            require.ensure ['src/coffee/areas/data-transfer', 'src/vue/areas/data-transfer/osb/legacy-registration', 'src/vue/areas/data-transfer/registration'] , ->
                require 'src/coffee/areas/data-transfer'
                require 'src/vue/areas/data-transfer/osb/legacy-registration'
                require 'src/vue/areas/data-transfer/registration'
                done()
        when 'master-data'
            require.ensure 'src/vue/areas/master-data/registration', ->
                require 'src/vue/areas/master-data/registration'
                done()
        when 'uav'
            require.ensure 'src/coffee/areas/uav', ->
                require 'src/coffee/areas/uav'
                done()
        when 'real-estate'
            require.ensure 'src/coffee/areas/real-estate', ->
                require 'src/coffee/areas/real-estate'
                done()
        when 'real-estate-new'
            require.ensure 'src/vue/areas/real-estate-new/registration', ->
                require 'src/vue/areas/real-estate-new/registration'
                done()
        when 'rtk'
            require.ensure 'src/coffee/areas/rtk', ->
                require 'src/coffee/areas/rtk'
                done()
        when 'my-rtk-base'
            require.ensure 'src/coffee/areas/my-rtk-base', ->
                require 'src/coffee/areas/my-rtk-base'
                done()
        when 'administration'
            require.ensure ['src/coffee/areas/administration', 'src/vue/areas/administration/registration'], ->
                require 'src/coffee/areas/administration'
                require 'src/vue/areas/administration/registration'
                done()
        when 'poultry-management'
            require.ensure 'src/vue/areas/poultry-management/registration', ->
                require 'src/vue/areas/poultry-management/registration'
                done()
        when 'poultry-management-prototype'
            require.ensure 'src/vue/areas/poultry-management-prototype/registration', ->
                require 'src/vue/areas/poultry-management-prototype/registration'
                done()
        when 'dlg-cert'
            require.ensure '@dlg-cert/registration', ->
                require '@dlg-cert/registration'
                done()
        when 'power-bi'
            require.ensure 'src/vue/areas/power-bi/registration', ->
                require 'src/vue/areas/power-bi/registration'
                done()
        else
            console.warn "Loading area script with identifier #{area} failed"
            done()

module.exports = {
    requireAreaScripts
}

module.exports.__esModule = true
module.exports.default = requireAreaScripts
