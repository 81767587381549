import commandQueue from '../api/commandQueue'

export const saveChangesRequest = ({commit}) => {
  commit('openNavigationGuard')
}

export const closeNavigationGuard = ({commit}) => {
  commit('closeNavigationGuard')
}

export const saveChangesSuccess = ({commit, rootState}) => {
  const currentContract = rootState.realEstateContractLease.contracts.currentContract
    ? rootState.realEstateContractLease.contracts.currentContract
    : rootState.realEstateContractLease.contracts.newContract

  if (!currentContract) {
    throw new Error('No contract for saving selected')
  }

  commit('closeNavigationGuard')

  return commandQueue.save(currentContract)
    .then(() => {
      commit('realEstateContractLease/contracts/resetStatus', null, {root: true})
    })
}
