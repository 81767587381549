const mutations = {
  recieveDmsReferenceTypes (state, dmsReferenceTypes) {
    state.dmsReferenceTypes = dmsReferenceTypes
  },
  selectDmsReference (state, dmsReference) {
    state.currentDmsReference = dmsReference
  },
  openCreateOrEdit (state, dmsReference) {
    if (!dmsReference) {
      throw Error('No currement DMS-Reference')
    }

    state.editDmsReference = dmsReference
    state.isEditDmsReferenceModalOpen = true
  },
  closeCreateOrEdit (state) {
    state.isEditDmsReferenceModalOpen = false
    state.editDmsReference = null
  },
  openDeleteDmsReference (state) {
    if (!state.currentDmsReference) {
      throw new Error('No dms reference selected')
    }

    state.isDeleteDmsReferenceModalOpen = true
  },
  closeDeleteDmsReference (state) {
    state.isDeleteDmsReferenceModalOpen = false
    state.currentDmsReference = null
  }
}

export default mutations
