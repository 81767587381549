import queryData from '../api/queryData'
import commandQueue from '../api/commandQueue'
import {notifications} from 'src/js/infrastructure'
import moment from 'moment'

const setValidationState = ({commit, errors}) => {
  commit('setStatusAllChanged')
  if (errors && errors.length > 0) {
    errors.forEach((error) => {
      switch (error.category) {
      case 'Common':
        commit('setCommonError')
        break
      case 'Areas':
        commit('setAreasError')
        break
      case 'Financial':
        commit('setFinancesError')
        break
      case 'RunTimes':
        commit('setTermsError')
        break
      case 'Amendments':
        commit('setAmendmentsError')
        break
      default:
        commit('setStatusError')
      }
    })
    notifications.criticalError(errors.map(err => err.errorMsg).join('<br />'))
  }
}

export const selectContract = ({commit}, contractId) => {
  if (contractId) {
    queryData.getLeaseContractById(contractId, (result) => {
      let amendment = null

      let contract = result.contract

      if (contract != null && contract.amendments != null) {
        contract.amendments.sort((a, b) => {
          // return new Date(a.validOn) - new Date(b.validOn) // TODO find out if this proposed fix during CORE-910 is correct
        })

        const filteredAmendments = contract.amendments.filter(x => moment(x.validOn) <= moment())

        if (filteredAmendments != null && filteredAmendments.length > 0) {
          amendment = filteredAmendments[filteredAmendments.length - 1]
        } else {
          amendment = contract.amendments[0]
        }
      }

      commit('setCurrentPermissions', result.permissions)
      commit('selectContract', contract)
      commit('realEstateContractLease/amendments/selectAmendment', amendment, {root: true})
    },
    () => {
      commit('setCurrentPermissions', null)
      commit('selectContract', null)
      commit('realEstateContractLease/amendments/selectAmendment', null, {root: true})
    })
  } else {
    commit('setCurrentPermissions', null)
    commit('selectContract', null)
    commit('realEstateContractLease/amendments/selectAmendment', null, {root: true})
  }

  commit('realEstateContractLease/finances/selectPaymentRecipient', null, {root: true})
  commit('resetStatus')
}

export const createNewLeaseContractRequest = ({commit, rootState, state}) => {
  if (!rootState.realEstateContractLease.navigation.selectedOrgUnit) {
    throw new Error('No tree node selected!')
  }

  const orgUnitId = rootState.realEstateContractLease.navigation.selectedOrgUnit.id
  commit('createNewLeaseContractRequest', orgUnitId)

  const currentContract = state.currentContract ? state.currentContract : state.newContract

  let latestAmendmend = currentContract && currentContract.amendments && currentContract.amendments.length > 0 ? currentContract.amendments[currentContract.amendments.length - 1] : null

  commit('realEstateContractLease/amendments/selectAmendment', latestAmendmend, {root: true})
}

export const createNewLeaseContractSuccess = ({commit, state}) => {
  if (!state.newContract) {
    throw new Error('No contract for saving selected')
  }

  return commandQueue.save(state.newContract)
    .catch(errors => {
      setValidationState({commit, errors})
      return Promise.reject(errors)
    })
    .then((contractId) => {
      commit('setStatusSuccess')
      setTimeout(() => commit('resetStatus'), 500)

      commit('createNewLeaseContractSuccess', contractId)
    })
}

export const createNewLeaseContractFailure = ({commit}) => {
  commit('createNewLeaseContractFailure')
}

export const deleteContract = () => {
  alert('Delete !!!')
}

export const editLeaseContractRequest = ({commit}) => {
  commit('editLeaseContractRequest')
}

export const editLeaseContractSuccess = ({commit, state}) => {
  if (!state.currentContract) {
    throw new Error('No contract for saving selected')
  }

  commit('setStatusWorking')

  return commandQueue.save(state.currentContract)
    .catch(errors => {
      setValidationState({commit, errors})
      return Promise.reject(errors)
    })
    .then(() => {
      commit('setStatusSuccess')
      setTimeout(() => commit('resetStatus'), 500)

      commit('editLeaseContractSuccess')
    })
}

export const editLeaseContractFailure = ({commit, state}) => {
  if (!state.currentContract) {
    throw new Error('No contract selected')
  }

  queryData.getLeaseContractById(state.currentContract.uid, (result) => {
    commit('selectContract', result.contract)
    let amendment = null

    let contract = result.contract

    if (contract != null && contract.amendments != null) {
      amendment = contract.amendments[contract.amendments.length - 1]
    }

    commit('setCurrentPermissions', result.permissions)
    commit('realEstateContractLease/amendments/selectAmendment', amendment, {root: true})
    commit('resetStatus')
  },
  () => {
    alert('Fehler')
  })

  commit('setCurrentPermissions', null)
  commit('editLeaseContractFailure')
}

export const setContractAsChanged = ({commit}) => {
  commit('setContractAsChanged')
}

export const setCommonChanged = ({commit}) => {
  commit('setCommonChanged')
}

export const setAreasChanged = ({commit}) => {
  commit('setAreasChanged')
}

export const setFinancesChanged = ({commit}) => {
  commit('setFinancesChanged')
}

export const setTermsChanged = ({commit}) => {
  commit('setTermsChanged')
}

export const setAmendmentsChanged = ({commit}) => {
  commit('setAmendmentsChanged')
}
