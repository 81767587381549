import axios from 'axios'
// import Vue from 'vue'

import {requestResources} from './api'

const missingTranslationPrototype = {
  missing: false,
  locale: null,
  resource: null,
  key: null,
  toString () {
    return this.missing ? 'RESOURCE_NOT_FOUND' : 'RESOURCE_LOAD_FAILED'
  }
}

const newMissingTranslation = ({locale, resource, key, missing}) => Object.assign(Object.create(missingTranslationPrototype), {locale, resource, key, missing})

export const signalMissingTranslation = ({state, commit}, {namespace, key}) => {
  // first missing translation request in this frame, schedule server fetch
  console.error(`missing translation: ${namespace}.${key}, check translation preloading`)
  if (!Object.keys(state.missingTranslations).length) {
    commit('loadTranslations', {
      [state.locale]: {
        [namespace]: {
          [key]: newMissingTranslation({locale: state.locale, resource: namespace, key, missing: true})
        }
      }
    })
    // console.log('queuing load')
    // Vue.nextTick(() => {
    //   dispatch('requestTranslations', state.missingTranslations)
    //   commit('clearMissingTranslations')
    // })
  }

  // commit('addMissingTranslation', {namespace, key})
}

export const preloadTranslations = ({state, dispatch}, resources) => {
  const request = {[state.locale]: {}}
  for (const resource of resources) {
    request[state.locale][resource] = true
  }
  return dispatch('requestTranslations', request)
}

export const requestTranslations = ({commit}, requestedTranslations) => {
  return requestResources(requestedTranslations)
  .catch(error => {
    console.error(`Error occurred requesting resources: ${error}`)

    const translations = {}

    for (const locale in requestedTranslations) {
      if (!translations[locale]) {
        translations[locale] = {}
      }
      for (const resource in requestedTranslations[locale]) {
        if (!translations[locale][resource]) {
          translations[locale][resource] = {}
        }
        if (Array.isArray(requestedTranslations[locale][resource]) || requestedTranslations[locale][resource] instanceof Set) {
          for (const key of requestedTranslations[locale][resource]) {
            translations[locale][resource][key] = newMissingTranslation({locale, resource, key, missing: true})
          }
        }
      }
    }

    return translations
  })
  .then(translations => {
    for (const locale in requestedTranslations) {
      if (!translations[locale]) {
        translations[locale] = {}
      }

      const resources = new Set([...Object.keys(requestedTranslations[locale]), ...Object.keys(translations[locale])])

      for (const resource of resources) {
        if (!translations[locale][resource]) {
          translations[locale][resource] = {}
        }

        const keys = Array.isArray(requestedTranslations[locale][resource]) || requestedTranslations[locale][resource] instanceof Set
          ? requestedTranslations[locale][resource]
          : Object.keys(translations[locale][resource])

        for (const key of keys) {
          const translation = translations[locale][resource][key]

          if (!translation) {
            translations[locale][resource][key] = newMissingTranslation({locale, resource, key, missing: true})
          }
        }
      }
    }

    commit('loadTranslations', translations)
  })
}

export const getTranslationDetails = (_, key) =>
  axios
    .get(`/api/Translation/keys?key=${encodeURIComponent(key)}`)
    .then(response => response.data)

export const startEditing = ({commit}, {key, context}) => {
  axios
    .get(`/api/Translation/keys?key=${encodeURIComponent(key)}`)
    .then(response => response.data)
    .then(history => {
      commit('startEditing', {key, history, context})
    })
}

export const save = ({state, commit, dispatch}) => {
  const {key, newValues, history} = state.editing

  // TODO maybe use object filter/map
  const valuesByLang = Object.keys(newValues)
    .filter(lang => newValues[lang])
    .filter(lang => !history[lang] || !history[lang].length || newValues[lang] !== history[lang][0].value)
    .reduce((obj, lang) => Object.assign(obj, {[lang]: newValues[lang]}), {})

  return dispatch('updateTranslation', {key, valuesByLang})
  .then(() => {
    commit('stopEditing')
  })
}

export const cancel = ({commit}) => {
  commit('stopEditing')
}

export const updateTranslation = ({commit}, {key, valuesByLang}) => {
  const url = `/api/Translation/keys?key=${encodeURIComponent(key)}`

  return axios.post(url, valuesByLang)
    .then(() => {
      const translations = {}

      Object.keys(valuesByLang).forEach(lang => {
        const tokens = key.split('.')
        const resource = tokens.slice(0, -1).join('.')
        const resourceKey = tokens[tokens.length - 1]
        translations[lang] = {[resource]: {[resourceKey]: valuesByLang[lang]}}
      })

      commit('loadTranslations', translations)
    })
}
