export const flatten = (all, items) => all.concat(items)
export const toObject = (obj, entry) => {
  return Object.assign(obj, Array.isArray(entry)
    ? {[entry[0]]: entry[1]}
    : entry)
}
export const lookupById = (lookup, obj) => {
  lookup[obj.id] = obj
  return lookup
}
