import axios from 'axios'

import {makeSetters} from '@helpers/vuex/mutations'

const makeDefaultState = () => {
  return {
    data: {
      henAverage: 0,
      henUniformity: 0,
      henNumberWeighed: 0,
      roosterAverage: 0,
      roosterUniformity: 0,
      roosterNumberWeighed: 0,
      remarks: ''
    }
  }
}

const state = makeDefaultState()

const mutations = {
  ...makeSetters([
    'data.henAverage',
    'data.henUniformity',
    'data.henNumberWeighed',
    'data.roosterAverage',
    'data.roosterUniformity',
    'data.roosterNumberWeighed',
    'data.remarks'
  ]),
  setState (state, data) {
    state.data.henAverage = data.henAverage
    state.data.henUniformity = data.henUniformity
    state.data.henNumberWeighed = data.henNumberWeighed
    state.data.roosterAverage = data.roosterAverage
    state.data.roosterUniformity = data.roosterUniformity
    state.data.roosterNumberWeighed = data.roosterNumberWeighed
    state.data.remarks = data.remarks
  },
  resetState () {
    Object.assign(state, makeDefaultState())
  }
}

const actions = {
  loadAnimalWeight ({commit, rootGetters}) {
    let farm = rootGetters['poultryManagement/farmer/currentFarm']
    let poultryHouse = rootGetters['poultryManagement/farmer/currentPoultryHouse']
    let date = rootGetters['poultryManagement/farmer/selectedDate']
    axios.get('/api/poultrymanagement/farmer/animalweight', {
      params: {
        farmId: farm.id,
        poultryHouseNumber: poultryHouse.poultryHouseNumber,
        flockNumber: poultryHouse.flocks[0], // TODO: access departement instead
        date: date
      }
    })
    .then((result) => {
      if (result.data) {
        commit('setState', result.data)
      } else {
        commit('resetState')
      }
    })
  },
  saveAnimalWeight ({state, dispatch, rootGetters}) {
    let farm = rootGetters['poultryManagement/farmer/currentFarm']
    let poultryHouse = rootGetters['poultryManagement/farmer/currentPoultryHouse']
    let date = rootGetters['poultryManagement/farmer/selectedDate']
    axios.post('/api/poultrymanagement/farmer/saveAnimalWeight', {
      farmId: farm.id,
      poultryHouseNumber: poultryHouse.poultryHouseNumber,
      flockNumber: poultryHouse.flocks[0], // TODO: access departement instead
      date: date,
      ...state.data
    })
    .then(() => {
      dispatch('poultryManagement/farmer/goToFarm', farm, {root: true})
    })
  }
}

const getters = {
  getAnimalWeightObject (state) {
    return state.data
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
