import mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'

const state = {
  orgUnits: [],
  selectedOrgUnit: null,
  originalTree: null
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
