const emptyContact = () => {
  let contact = {
    type: null,
    fkDataOwner: '',
    name: '',
    nameAdditional: '',
    address: {
      street: '',
      postOfficeBox: '',
      country: null,
      region: null,
      postalCode: '',
      city: '',
      localCentre: ''
    },
    communications: {
      phone: '',
      phoneSecond: '',
      phoneThird: '',
      fax: '',
      faxSecond: '',
      eMail: '',
      eMailSecond: '',
      webSite: ''
    },
    info: '',
    bankingAccouonts: []
  }

  return contact
}

export const contacts = (state) => {
  return state.contacts ? state.contacts : []
}

export const currentContact = (state) => {
  return state.currentContact ? state.currentContact : emptyContact()
}

export const hasCurrentContact = (state) => {
  return !!state.currentContact
}

export const isCreateContact = (state) => {
  return state.isCreateContact
}

export const isEditContact = (state) => {
  return state.isEditContact
}

export const newContact = (state) => {
  return state.newContact ? state.newContact : {}
}

export const isEditChanged = (state) => {
  return state.isEditChanged
}
