import Vue from 'vue'

import {cloneDeep} from 'lodash'

import {makeSetters} from '@helpers/vuex/mutations'
import {newTranslatableString,
  nutrientRemovalProperties,
  productShareProperties
} from './helpers'

import * as getters from './getters'
import * as actions from './actions'
import makeTemplate from './make-template'

export default {
  namespaced: true,
  state: {
    editBuffer: null,
    lastTranslations: null,
    selection: null, // id of crop/usage/quality
    ui: {
      showAsOxide: false,
      // by changing the hash the ChangeDetectionMixin re-initailizes by re-loading the dynamic details component (CropForm, CropUsageForm, CropHarvestQualityForm)
      // https://youtrack.ixmap.eu/issue/ASK-1695
      editBufferHash: null
    }
  },
  getters,
  mutations: {
    ...makeSetters([
      'ui.showAsOxide',
      'lastTranslations',
      // common
      'editBuffer.name',
      // crop
      'editBuffer.isLeguminous',
      'editBuffer.tags',
      'editBuffer.nFix',
      'editBuffer.averageWeight',
      // usage
      'editBuffer.abbreviation',
      'editBuffer.yieldLevel',
      'editBuffer.nitrogenRequirement',
      'editBuffer.drySubstrateAmount',
      'editBuffer.subsidyCode',
      // quality
      'editBuffer.crudeProteinContent'
    ]),
    setNutrientRemoval (state, nutrientRemoval) {
      const invalidKeys = Object.keys(nutrientRemoval)
        .filter(key => !nutrientRemovalProperties.includes(key))

      if (invalidKeys.length) {
        throw new Error(`invalid productShare object keys: ${Object.keys(invalidKeys)}`)
      }

      Object.assign(state.editBuffer, nutrientRemoval)
    },
    setProductShares (state, productShares) {
      const invalidKeys = Object.keys(productShares)
        .filter(key => !productShareProperties.includes(key))

      if (invalidKeys.length) {
        throw new Error(`invalid productShare object keys: ${Object.keys(invalidKeys)}`)
      }

      Object.assign(state.editBuffer, productShares)
    },
    create (state, {level, parentId}) {
      state.editBuffer = makeTemplate(level, parentId)

      const lastTranslations = {
        name: newTranslatableString(),
        abbreviation: newTranslatableString()
      }
      Vue.set(state, 'lastTranslations', lastTranslations)
    },
    select (state, {id, fullObject}) {
      state.selection = id
      state.editBuffer = cloneDeep(fullObject)
      state.ui.editBufferHash = JSON.stringify(state.editBuffer)
    },
    clearSelection (state) {
      state.selection = null
      state.editBuffer = null
    }
  },
  actions
}
