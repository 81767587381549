import newGuid from '@helpers/new-guid'

import {sortBy} from 'lodash'

const mutations = {

  setAvailableDmsReferenceTypes (state, dmsReferenceTypes) {
    state.masterData.dmsReferenceTypes = dmsReferenceTypes
  },

  setAvailableCountries (state, countries) {
    state.masterData.countries = countries
  },

  setAvailableUsageTypes (state, usageTypes) {
    state.masterData.usageTypes = usageTypes
  },

  setAvailableCurrencies (state, currencies) {
    state.masterData.currencies = currencies
  },

  setAvailableAreas (state, areas) {
    state.areas.available = areas
  },

  setCurrentArea (state, currentArea) {
    state.areas.current = currentArea
  },

  setAvailableOrgUnits (state, orgUnits) {
    state.orgUnits.available = orgUnits
  },

  setCurrentOrgUnit (state, orgUnit) {
    state.orgUnits.current = orgUnit
  },

  setTotalItemsForEntity (state, countItems) {
    state.tableData.totalItemsForEntity = countItems
  },

  toggleLeftSidebarPinned (state) {
    state.ui.leftSidebarPinned = !state.ui.leftSidebarPinned
  },

  toggleRightSidebarPinned (state) {
    state.ui.rightSidebarPinned = !state.ui.rightSidebarPinned
  },

  selectRows (state, rows) {
    state.tableData.selection = rows
  },

  setLocalDistrict (state, localDistrict) {
    state.data.localDistrict = localDistrict
  },

  setCadastralDistricts (state, cadastralDistricts) {
    const sorted = sortBy(cadastralDistricts, 'number')
    state.data.cadastralDistricts = sorted
  },

  setSelectedCadastralDistrict (state, district) {
    state.data.selectedCadastralDistrict = district
  },

  setLandParcels (state, parcels) {
    state.data.landParcels = parcels
  },

  setLandParcel (state, parcel) {
    state.data.landParcel = parcel
  },

  setSegments (state, segments) {
    state.data.segments = segments
  },

  resetSelection (state) {
    state.data.segments = []
    state.data.landParcel = null
    state.data.selectedCadastralDistrict = null
    state.data.localDistrict = null
  },

  setInitialRows (state, data) {
    state.tableData.rows = data.splice(0, state.tableData.initialNumberOfRowsToLoad - state.tableData.numberNextRows)
    state.tableData.bufferRows = data
  },

  appendBufferRows (state) {
    state.tableData.rows = [...new Set([...state.tableData.rows, ...state.tableData.bufferRows])]
    state.tableData.indexNextRows += state.tableData.numberNextRows
  },

  setNextBufferRows (state, data) {
    if (data.length > 0) {
      state.tableData.bufferRows = data
    } else {
      state.tableData.endOfDataReached = true
    }
  },

  resetScrollData (state) {
    state.tableData.initialNumberOfRowsToLoad = 150
    state.tableData.indexNextRows = 150
    state.tableData.numberNextRows = 50
    state.tableData.bufferRows = []
    state.tableData.endOfDataReached = false
  },

  emptyTable (state) {
    state.tableData.selection = []
    state.tableData.rows = []
  },

  setDataForTableSelection (state, data) {
    if (state.areas.current.id === 'purchases' || state.areas.current.id === 'sales') {
      data.commonData.notary = {value: data.commonData.notary.uid, text: data.commonData.notary.name, label: data.commonData.notary.city}
      data.commonData.businessObject = {value: data.commonData.businessObject.uid, text: data.commonData.businessObject.name}
      data.commonData.partnerA = {value: data.commonData.partnerA.uid, text: data.commonData.partnerA.name, label: data.commonData.partnerA.city}
      data.commonData.partnerB.forEach((partner, index) => {
        data.commonData.partnerB[index] = {
          value: partner.uid,
          text: partner.name,
          label: partner.city
        }
      })
      if (data.commonData.partnerB.length === 0) data.commonData.partnerB = [{}]
      state.contract.current = data
    } else if (state.areas.current.id === 'lease') {
      state.lease.current = data.contract
    } else if (state[state.areas.current.id]) {
      state[state.areas.current.id].current = data
    } else {
      state.tableData.selectionData = data
    }
  },

  setLandParcelPrice (state, {parcelId, price, currency}) {
    const lp = state.tableData
                .selectionData
                .landParcelData
                .find(parcel => parcel.uid === parcelId)
    lp.price = price
    lp.currency = currency
  },

  setLeaseContractSize (state, {id, newValue}) {
    const amendments = state.tableData.selectionData.contract.amendments
    const area = amendments.map(x => x.areas.find(area => area.uid === id))[0]
    area.sizeContractual = parseFloat(newValue)
  },
  deleteLeaseContractArea (state, id) {
    const amendments = state.tableData.selectionData.contract.amendments

    for (const amendment of amendments) {
      const index = amendment.areas.findIndex(area => area.uid === id)
      if (index !== undefined) {
        amendment.areas.splice(index, 1)
      }
    }
  },
  addAreaToAmendments (state, {newArea, selectedAmendment}) {
    const amendments = state.tableData.selectionData.contract.amendments
    amendments[selectedAmendment].areas.push(newArea)
    state.tableData.selectionData.contract.amendments = amendments
  },
  addPayments (state, payments) {
    state.tableData.selectionData.contract.payments = state.tableData.selectionData.contract.payments.concat(payments)
  },
  deletePaymentRecipients (state) {
    const selectedRecipientUids = state.tableData.selection.map(x => x.uid)

    for (let i = 0; i < selectedRecipientUids.length; i++) {
      state.tableData.selectionData.contract.paymentRecipients.splice(state.tableData.selectionData.contract.paymentRecipients.indexOf(selectedRecipientUids[i]), 1)
    }
  },
  setBankData (state, data) {
    state.masterData.bankData = data
  },
  setLoanNumbers (state, data) {
    state.masterData.loanNumbers = data
  },
  removeMortgage (state) {
    const selectedMortgage = state.landParcel.selectedMortgage
    state.tableData.selectionData.mortgages.splice(state.tableData.selectionData.mortgages.indexOf(selectedMortgage), 1)
    state.landParcel.selectedMortgage = null
  },
  saveMortgage (state) {
    const editMortgage = state.landParcel.editBuffer

    const bank = state.masterData.bankData.find(x => x.uid === editMortgage.bankId)
    const loanNumber = state.masterData.loanNumbers.find(x => x.uid === editMortgage.loanNumberId)

    const fullMortgageObject = {
      bank,
      loanNumber,
      amount: editMortgage.amount,
      remark: editMortgage.remark,
      removalAllowed: editMortgage.removalAllowed,
      currency: editMortgage.currencyId
    }

    if (state.landParcel.editBuffer.uid) {
      fullMortgageObject.uid = state.landParcel.editBuffer.uid

      let index = state.tableData.selectionData.mortgages.indexOf(this.editMortgage)
      state.tableData.selectionData.mortgages.splice(index, 1, fullMortgageObject)
    } else {
      fullMortgageObject.uid = newGuid()
      state.tableData.selectionData.mortgages.push(fullMortgageObject)
    }
  }
}

export default mutations
