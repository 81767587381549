export default async function loadDataTablesTranslation (language) {
  const translations = {}

  switch (language) {
  case 'en': {
    Object.assign(translations, await import('@wwwroot/misc/translations/datatables/English.json'))
    break
  }
  case 'de': {
    Object.assign(translations, await import('@wwwroot/misc/translations/datatables/German.json'))
    Object.assign(translations, await import('@wwwroot/misc/translations/datatables/dt-additional-german.json'))
    break
  }
  case 'ru': {
    Object.assign(translations, await import('@wwwroot/misc/translations/datatables/Russian.json'))
    break
  }
  }

  return translations
}
