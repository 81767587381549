import {makeSetters} from '@helpers/vuex/mutations'
import moment from 'moment'

function makeEmptyProperty () {
  return {
    uid: null,
    assignedEntityId: null,
    number: null,
    name: null,
    propertyType: null,
    remarks: '',
    segments: [],
    address: {
      postalcode: null,
      city: null,
      street: null
    },
    taxNotice: {
      uid: null,
      referenceNumber: null,
      notificationDate: null,
      taxAuthority: null,
      cashReferenceNumber: null,
      annualPropertyTax: null,
      propertyTaxQ1: null,
      propertyTaxQ2: null,
      propertyTaxQ3: null,
      propertyTaxQ4: null,
      landValueDM: 0,
      basicAmount: 0,
      dateUnitValueFromTaxNotice: moment(),
      unitValueDM: 0,
      unitValueEUR: 0,
      community: null,
      propertyValue: 0,
      outdoorFacilityValue: null,
      facilities: [],
      functionalZoningCategory: null,
      buildingLicenseRestrictions: null
    }
  }
}

export default {
  namespaced: true,

  state: {
    current: {
      uid: null,
      assignedEntityId: null,
      number: null,
      name: null,
      propertyType: null,
      remarks: '',
      segments: [],
      address: {
        postalcode: null,
        city: null,
        street: null
      },
      taxNotice: {
        uid: null,
        referenceNumber: null,
        notificationDate: null,
        taxAuthority: null,
        cashReferenceNumber: null,
        annualPropertyTax: null,
        propertyTaxQ1: null,
        propertyTaxQ2: null,
        propertyTaxQ3: null,
        propertyTaxQ4: null,
        landValueDM: 0,
        basicAmount: 0,
        dateUnitValueFromTaxNotice: moment(),
        unitValueDM: 0,
        unitValueEUR: 0,
        community: null,
        propertyValue: 0,
        outdoorFacilityValue: null,
        facilities: [],
        functionalZoningCategory: null,
        buildingLicenseRestrictions: null
      }
    }
  },

  actions: {
  },

  mutations: {
    ...makeSetters([
      'current.number',
      'current.name',
      'current.propertyType',
      'current.address.street',
      'current.address.postalCode',
      'current.address.city',
      'current.remark',
      'current.functionalZoningCategory',
      'current.buildingLicenseRestrictions',
      'current.taxNotice.annualPropertyTax',
      'current.taxNotice.assessedRate',
      'current.taxNotice.basicAmmount',
      'current.taxNotice.cashReferenceNumber',
      'current.taxNotice.community',
      'current.taxNotice.dateUnitValueFromTaxNotice',
      'current.taxNotice.landValueDM',
      'current.taxNotice.notificationDate',
      'current.taxNotice.outdoorFacilitiesValue',
      'current.taxNotice.propertyTaxQ1',
      'current.taxNotice.propertyTaxQ2',
      'current.taxNotice.propertyTaxQ3',
      'current.taxNotice.propertyTaxQ4',
      'current.taxNotice.propertyValue',
      'current.taxNotice.referenceNumber',
      'current.taxNotice.taxAuthority',
      'current.taxNotice.unitValueDM',
      'current.taxNotice.unitValueEUR'
    ]),

    appendSegment (state, segment) {
      state.current.segments = state.current.segments.concat(segment)
    },

    removeSegment (state, segment) {
      state.current.segments = state.current.segments.filter(seg => seg.uid !== segment.uid)
    },

    newProperty (state) {
      state.current = makeEmptyProperty()
    }

  }
}
