import queryData from './api/queryData'

export const initRequest = ({commit}) => {
  queryData.getTreeNodes((treeNodes) => {
    commit('navigation/recieveOrgUnits', treeNodes)
  })

  queryData.getCountries((countries) => {
    commit('recieveCountries', countries)
  })

  queryData.getCountriesWithRegions((regions) => {
    commit('recieveCountriesWithRegions', regions)
  })

  queryData.getContactTypes((contactTypes) => {
    commit('recieveContactTypes', contactTypes)
  })
}

export const updateCurrentSelectedCountryUID = ({commit}, currentSelectedCountryUID) => {
  commit('updateCurrentSelectedCountryUID', currentSelectedCountryUID)
}
