import axios from 'axios'
import moment from 'moment'
import pages from './pages'

import poultryHouseManagement from './poultryhouse-management'

const makeDefaultState = () => {
  return {
    ui: {
      currentPage: pages.blank,
      errorMessage: ''
    },
    data: {
      currentFarm: null,
      currentOrgUnit: null,
      overview: {
        favoriteFeeds: [],
        totalFeed: 1,
        currentFlocks: [],
        poultryHouses: []
      },
      currentFlock: {
        placementDate: []
      },
      feeds: [],
      currentFeed: null,
      currentPoultryHouse: null
    }
  }
}

const state = makeDefaultState()

const mutations = {
  resetState (state) {
    Object.assign(state, makeDefaultState())
  },

  setCurrentFarm (state, farm) {
    state.data.currentFarm = farm
    if (farm === null) {
      state.ui.currentPage = pages.blank
    }
  },

  setCurrentOrgUnit (state, orgUnit) {
    state.data.currentOrgUnit = orgUnit
  },

  setCurrentFlock (state, flock) {
    state.data.currentFlock = flock
  },

  updateOverviewData (state, newOverviewData) {
    state.data.overview = newOverviewData
  },

  goToPage (state, pageName) {
    state.ui.currentPage = pageName
  },

  setErrorMessage (state, errorMessage) {
    state.ui.errorMessage = errorMessage
  },

  setCurrentPoultryHouse (state, poultryHouse) {
    state.ui.currentPoultryHouse = poultryHouse
  }
}

const actions = {
  goToFarm ({commit}, farm) {
    const date = moment().format('YYYY-MM-DD')
    commit('resetState')
    commit('setCurrentFarm', farm)

    axios.all([
      axios.get('/api/poultrymanagement/production/feeds/count', {params: {farm: farm.id}}),
      axios.get('/api/poultrymanagement/production/feeds/favorites', {params: {farm: farm.id}}),
      axios.get('/api/poultrymanagement/production/flocks', {params: {farm: farm.id, date: date}}),
      axios.get('/api/poultrymanagement/production/poultryHouses', {params: {farm: farm.id, date: date}}),
      axios.get('/api/poultrymanagement/production/feeds', {params: {farm: getters.currentFarm.id}})

    ])
    .then(axios.spread((count, favorits, flocks, houses, feeds) => {
      commit('updateOverviewData', {
        favoriteFeeds: favorits.data,
        totalFeed: count.data,
        currentFlocks: flocks.data,
        poultryHouses: houses.data,
        allFeeds: feeds.data
      })
      commit('goToPage', pages.overview)
    }))
    .catch((error) => {
      commit('setErrorMessage', error)
      commit('goToPage', pages.error)
    })
  },

  goToFlock ({commit}, flockNumber) {
    axios.get('/api/poultrymanagement/production/flocks/details', {params: {flockNumber: flockNumber}})
    .then(result => {
      commit('setCurrentFlock', result.data)
      commit('goToPage', pages.flockManagement)
    })
    .catch((error) => {
      commit('setErrorMessage', error)
      commit('goToPage', pages.error)
    })
  },

  async instableFlock ({dispatch, state, commit}, flock) {
    await axios.post('/api/poultrymanagement/production/flocks/place', flock)
    await dispatch('goToFarm', state.data.currentFarm)
  },

  instableNewFlock ({commit}) {
    commit('goToPage', pages.instableFlock)
  },

  depleteAnimals ({dispatch}, data) {
    axios.post('/api/poultrymanagement/production/flocks/depleteanimals', data)
    .then(() => dispatch('goToFarm', state.data.currentFarm))
  },

  depleteWholeFlock ({dispatch}, data) {
    axios.post('/api/poultrymanagement/production/flocks/depletewholeflock', data)
    .then(() => dispatch('goToFarm', state.data.currentFarm))
  },

  updateFlockDetails ({commit}, flockDetails) {
    axios.post('/api/poultrymanagement/production/flocks/updatedetails', flockDetails)
    .then(() => commit('goToPage', pages.overview))
    // TOOD: if view doesnt change, details in state have to be updated
  },

  deleteFlock ({dispatch, state}, flockNumber) {
    axios.post('/api/poultrymanagement/production/flocks/delete', {flockNumber: flockNumber})
    .then(() => dispatch('goToFarm', state.data.currentFarm))
  },

  goToFeeds ({commit}) {
    commit('goToPage', pages.feeds)
  },

  goToPoultryHouse ({commit, state}, poultryHouseNumber) {
    axios.get('/api/poultrymanagement/production/poultryHouse', {params: {poultryHouseNumber, farmId: state.data.currentFarm.id}})
    .then(result => {
      commit('poultryManagement/productionManager/poultryHouseManagement/setData', result.data, {root: true})
      commit('goToPage', pages.poultryHouseManagement)
    })
    .catch((error) => {
      commit('setErrorMessage', error)
      commit('goToPage', pages.error)
    })
  },

  openFarmAsFarmer ({state}) {
    const targetUrl = new URL(window.location.href)
    targetUrl.searchParams.set('view', 'farmer')
    targetUrl.searchParams.set('entity', state.data.currentFarm.id || state.data.currentOrgUnit.id)
    window.open(targetUrl, '_blank')
  }
}

const getters = {
  currentFarm (state) {
    return state.data.currentFarm
  },
  currentOrgUnit (state) {
    return state.data.currentOrgUnit
  },
  currentPage (state) {
    return state.ui.currentPage
  },
  favoriteFeeds (state) {
    return state.data.overview.favoriteFeeds
  },
  totalFavorites (state) {
    return state.data.overview.favoriteFeeds.filter(item => item.isFavorite).length
  },
  totalFeed (state) {
    return state.data.overview.totalFeed
  },
  currentFlocks (state) {
    return state.data.overview.currentFlocks
  },
  totalFlocks (state) {
    return state.data.overview.currentFlocks.length
  },
  poultryHouses (state) {
    return state.data.overview.poultryHouses
  },
  totalPoultryHouses (state) {
    return state.data.overview.poultryHouses.length
  },
  errorMessage (state) {
    return state.ui.errorMessage
  },
  allFeeds (state) {
    return state.data.overview.allFeeds
  },
  currentFlock (state) {
    return state.data.currentFlock
  },
  currentFlockPlacementDates () {
    return state.data.currentFlock.placementDate || []
  },
  currentFlockDepletingDate () {
    return state.data.currentFlock.depletingDate ? state.data.currentFlock.depletingDate : null
  },
  currentFlockNumber (state) {
    return state.data.currentFlock.flockNumber
  },
  currentFlockHatchingDate (state) {
    return state.data.currentFlock.hatchingDate ? state.data.currentFlock.hatchingDate : null
  },
  currentFlockDaysOfLife (state) {
    return state.data.currentFlock.daysOfLife
  },
  currentFlockBreed (state) {
    return state.data.currentFlock.breedName
  },
  currentFlockFeedRecommendation (state) {
    return state.data.currentFlock.feedRecommendation
  },
  currentFlockType (state) {
    return state.data.currentFlock.type
  },
  currentFlockPoultryHouseOccupancy (state) {
    return state.data.currentFlock.poultryHouseOccunpancy
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  modules: {
    poultryHouseManagement
  }
}
