import './config'

let waitscreen = {
  showWaitscreen () {
    throw new Error("'showWaitscreen' method of infrastructure module 'waitscreen' is not implemented.")
  },
  hideWaitscreen () {
    throw new Error("'hideWaitscreen' method of infrastructure module 'waitscreen' is not implemented.")
  },
  waitFor () {
    throw new Error("'waitFor' method of infrastructure module 'waitscreen' is not implemented.")
  },
  setWaitscreenError () {
    throw new Error("'setWaitscreenError' method of infrastructure module 'waitscreen' is not implemented.")
  },
  setWaitscreenProgress () {
    throw new Error("'setWaitscreenProgress' method of infrastructure module 'waitscreen' is not implemented.")
  }
}

let notifications = {
  info () {
    throw new Error("'info' method of infrastructure module 'notifications' is not implemented.")
  },
  success () {
    throw new Error("'success' method of infrastructure module 'notifications' is not implemented.")
  },
  persistentSuccess () {
    throw new Error("'persistentSuccess' method of infrastructure module 'notifications' is not implemented.")
  },
  warning () {
    throw new Error("'warning' method of infrastructure module 'notifications' is not implemented.")
  },
  error () {
    throw new Error("'error' method of infrastructure module 'notifications' is not implemented.")
  },
  criticalError () {
    throw new Error("'criticalError' method of infrastructure module 'notifications' is not implemented.")
  }
}

let backend = {
  getToken () {
    return undefined
  },
  getServer () {
    return undefined
  }
}

let config

const registerWaitscreen = (implementation) => Object.assign(waitscreen, implementation)
const registerNotifications = (implementation) => Object.assign(notifications, implementation)
const registerBackend = (implementation) => Object.assign(backend, implementation)
const setConfig = (actualConfig) => {
  config = actualConfig
}

const dependencyConfiguration = {
  registerWaitscreen,
  registerNotifications,
  registerBackend,
  setConfig
}

const maybeAddBackend = url => {
  const server = backend.getServer()

  if (server && !url.startsWith('http')) {
    if (!url.startsWith('/')) {
      url = '/' + url
    }
    url = server + url
  }
  return url
}

export {
  waitscreen,
  notifications,
  backend,
  maybeAddBackend,
  config,
  dependencyConfiguration
}
