import * as actions from './actions'
import * as mutations from './mutations'
// import * as getters from './getters'

import supportedLocales from './supported-locales.json'

const defaultLocale = supportedLocales[0]

const resources = {}
for (const locale of supportedLocales) {
  resources[locale] = {}
}

const state = {
  locale: defaultLocale,
  supportedLocales,
  resources,
  translations: resources[defaultLocale],
  missingTranslations: {},
  editMode: false,
  editing: {
    key: null,
    context: null,
    history: null,
    newValues: null
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
  // getters
}
