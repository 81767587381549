import osb from './osb'
import varioDoc from './vario-doc'
import nevonex from './nevonex'
import {makeSetters} from '@helpers/vuex/mutations'

const state = {
  selectedApplicationMapId: null
}

export default {
  namespaced: true,
  state,
  mutations: {
    ...makeSetters([
      'selectedApplicationMapId'
    ])
  },
  modules: {
    osb,
    varioDoc,
    nevonex
  }
}
