export default async function setJqueryValidateLanguage (language) {
  let load = () => {}

  switch (language) {
  case 'de': {
    load = (await import('@wwwroot/misc/translations/jquery-validate/messages_de')).default
    break
  }
  case 'ru': {
    load = (await import('@wwwroot/misc/translations/jquery-validate/messages_ru')).default
    break
  }
  }

  load()
}
