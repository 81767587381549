import navigation from './navigation'
import appointments from './appointments'
import * as actions from './actions'

export default {
  namespaced: true,
  actions,
  modules: {
    navigation,
    appointments
  }
}
