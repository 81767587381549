moment = require 'moment'

require 'eonasdan-bootstrap-datetimepicker'

{getCurrentLocale} = require 'src/js/i18n'

setup =  ({$element, options, $ctx = $(document)}) ->
    dataOptions =
        viewMode: $element.data('view-mode')
        format: $element.data('format') or 'L'
        locale: getCurrentLocale()

    options = $.extend {}, dataOptions

    if $element.data('min-from')
        options.useCurrent = false # see issue #1075

    dateTime = $element.find('input').val()

    momentDate = moment(dateTime)

    dateTimePicker = $element.datetimepicker(options).data('DateTimePicker')
    dateTimePicker.date(momentDate)

    if $element.data('min-from')
        $minProvider = $ctx.find '#' + $element.data('min-from')
        $minProvider.on 'dp.change', (event) ->
            $element.data('DateTimePicker').minDate event.date

    if $element.data('max-from')
        $maxProvider = $ctx.find '#' + $element.data('max-from')
        $maxProvider.on 'dp.change', (event) ->
            $element.data('DateTimePicker').maxDate event.date

    if $element.data('current')
        $element.data('DateTimePicker').date moment()

    return $.Deferred().resolve().promise()

module.exports = {
    setup
}

module.exports.__esModule = true
module.exports.default = setup
