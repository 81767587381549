import mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'

const state = {
  contacts: [],
  currentContact: null,
  newContact: null,
  isCreateContact: false,
  isEditContact: false,
  isEditChanged: false

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
