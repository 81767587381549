import axios from 'axios'
import {cloneDeep, pickBy} from 'lodash'

import supportedLocales from '@store/modules/i18n/supported-locales.json'

import {determineParentId} from './helpers'

const updateSingle = ({rootState, commit}, {level, dto}) => {
  const {id} = dto

  switch (level) {
  case 'crop': {
    const updatedCrops = {
      ...rootState.masterData.crops,
      [id]: Object.assign(dto, {id})
    }
    commit('masterData/setCrops', updatedCrops, {root: true})
    break
  }
  case 'usage': {
    const updatedUsages = {
      ...rootState.masterData.cropUsages,
      [id]: Object.assign(dto, {id})
    }
    commit('masterData/setCropUsages', updatedUsages, {root: true})
    break
  }
  case 'quality': {
    const updatedQualities = {
      ...rootState.masterData.cropHarvestQualities,
      [id]: Object.assign(dto, {id})
    }
    commit('masterData/setCropHarvestQualities', updatedQualities, {root: true})
    break
  }
  }
}

export function reloadRequiredData ({dispatch}) {
  return Promise.all([
    dispatch('masterData/loadUserPermissions', null, {root: true}),
    dispatch('loadTranslations'),
    dispatch('masterData/reloadCropTags', true, {root: true}),
    dispatch('masterData/reloadCrops', true, {root: true}),
    dispatch('masterData/reloadCropUsages', true, {root: true}),
    dispatch('masterData/reloadCropHarvestQualities', true, {root: true})
  ])
}

export function loadTranslations ({dispatch}) {
  return dispatch('i18n/preloadTranslations', [
    'Common.SR_Common',
    'Messages.Info.SR_InfoMessages',
    'masterData.crops',
    'frs.units'
  ], {root: true})
}

export function create ({getters, commit}) {
  if (!getters.creationContext) {
    throw new Error('cannot create at this level')
  }

  commit('create', getters.creationContext)
}

export function select ({rootState, commit}, id) {
  const level = rootState.masterData.crops[id]
    ? 'crop'
    : rootState.masterData.cropUsages[id]
      ? 'usage'
      : rootState.masterData.cropHarvestQualities[id]
        ? 'quality'
        : null

  if (!level) throw new Error('invalid id')

  const lookupMapping = {
    crop: 'crops',
    usage: 'cropUsages',
    quality: 'cropHarvestQualities'
  }

  const lookup = rootState.masterData[lookupMapping[level]]

  return Promise.all([
    axios.get(`/api/Translation/keys?key=masterData.crops.${level}.${id}_name`).then(response => response.data),
    axios.get(`/api/Translation/keys?key=masterData.crops.${level}.${id}_abbreviation`).then(response => response.data)
  ])
  .then(([nameHistory, abbreviationHistory]) => {
    const defaultTranslations = () => supportedLocales
      .reduce((history, locale) => Object.assign(history, {[locale]: null}), {})

    const currentTranslations = history => Object.keys(history)
      .reduce((obj, locale) => Object.assign(obj, {[locale]: history[locale][0].value}), defaultTranslations())

    const translations = {
      name: currentTranslations(nameHistory)
    }

    if (level === 'usage') {
      translations.abbreviation = currentTranslations(abbreviationHistory)
    }

    commit('setLastTranslations', translations)

    const fullObject = Object.assign({}, lookup[id], translations)

    commit('select', {id, fullObject})
  })
}

export function save ({state, getters, commit, dispatch, rootState}) {
  const {bufferLevel: level} = getters.location
  const {name, abbreviation, ...dto} = cloneDeep(state.editBuffer)

  dto.name = name['de-DE']
  if (abbreviation) {
    dto.abbreviation = abbreviation['de-DE']
  }

  const urls = {
    crop: '/api/v2/master-data/crops',
    usage: '/api/v2/master-data/crop-usages',
    quality: '/api/v2/master-data/crop-harvest-qualities'
  }

  const translations = {
    name: pickBy(name, (value, key) => state.lastTranslations.name[key] !== value),
    abbreviation: pickBy(abbreviation, (value, key) => state.lastTranslations.abbreviation[key] !== value)
  }

  dto.isFullyTranslated = Object.values(name).every(x => x) && (!abbreviation || Object.values(abbreviation).every(x => x))

  return axios.post(urls[level], dto)
    .then(response => response.data)
    .then(({id}) => Promise.all([
      dispatch('i18n/updateTranslation', {key: `masterData.crops.${level}.${id}_name`, valuesByLang: translations.name}, {root: true}),
      abbreviation
        ? dispatch('i18n/updateTranslation', {key: `masterData.crops.${level}.${id}_abbreviation`, valuesByLang: translations.abbreviation}, {root: true})
        : Promise.resolve()
    ]).then(() => ({id})))
    .then(({id}) => {
      Object.assign(dto, {id})
      updateSingle({commit, rootState}, {level, dto})
      return dispatch('select', id)
    })
}

export function cancelCreate ({state, commit, dispatch}) {
  if (!state.editBuffer || state.editBuffer.id) throw new Error('currently not in create mode')

  const parentId = determineParentId(state.editBuffer)

  if (parentId) {
    return dispatch('select', parentId)
  } else {
    commit('clearSelection')
  }
}

export function archive ({state, getters, commit, dispatch, rootState}) {
  const {bufferLevel: level} = getters.location
  const {id} = state.editBuffer

  const urls = {
    crop: `/api/v2/master-data/crops/${id}`,
    usage: `/api/v2/master-data/crop-usages/${id}`,
    quality: `/api/v2/master-data/crop-harvest-qualities/${id}`
  }

  return axios.delete(urls[level])
    .then(() => {
      const dto = Object.assign(cloneDeep(state.editBuffer), {isArchived: true})
      updateSingle({commit, rootState}, {level, dto})
      return dispatch('select', dto.id)
    })
}

export function restore ({state, getters, commit, dispatch, rootState}) {
  const {bufferLevel: level} = getters.location
  const {id} = state.editBuffer

  const urls = {
    crop: `/api/v2/master-data/crops/${id}/restore`,
    usage: `/api/v2/master-data/crop-usages/${id}/restore`,
    quality: `/api/v2/master-data/crop-harvest-qualities/${id}/restore`
  }

  return axios.post(urls[level])
    .then(() => {
      const dto = Object.assign(cloneDeep(state.editBuffer), {isArchived: false})
      updateSingle({commit, rootState}, {level, dto})
      return dispatch('select', dto.id)
    })
}
