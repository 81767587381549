import Vue from 'vue'

import {smarterGet} from '@helpers/vuex/data-loading'
import {makeSetters} from '@helpers/vuex/mutations'

import management from './management'
import cropManagement from './crop-management'
import productSalesManagement from './product-sales-management'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    units: {},
    globalProducts: {},
    customProducts: {},
    productCharacteristics: [],
    crops: {},
    cropTags: [],
    productTags: [],
    cropUsages: {},
    cropHarvestQualities: {},
    productAlgorithms: {},
    portalSettings: {
      recommended: {
        products: []
      }
    }
  },
  getters: {
    products (state, getters, rootState) {
      const customProducts = rootState.fieldRecordSystem
        ? state.customProducts[rootState.fieldRecordSystem.navigation.location.orgUnitId] // TODO at some point use global navigation module, when orgUnit navigation is extracted from frs
        : {}

      return Object.assign({}, state.globalProducts, customProducts)
    },
    unitArray (state) {
      return Object.values(state.units)
    },
    productArray (state, getters) {
      return Object.values(getters.products)
    },
    activeProductArray (state, getters) {
      return Object.values(getters.productArray.filter(x => !x.isArchived))
    },
    cropArray (state) {
      return Object.values(state.crops)
    },
    cropUsageArray (state) {
      return Object.values(state.cropUsages)
    },
    cropHarvestQualityArray (state) {
      return Object.values(state.cropHarvestQualities)
    },
    productAlgorithmArray (state) {
      return Object.values(state.productAlgorithms)
    }
  },
  mutations: {
    setUnits (state, unitArray) {
      state.units = {}
      for (const unit of unitArray) {
        state.units[unit.shortcut] = unit
      }
    },
    setCustomProducts (state, {orgUnitId, products}) {
      Vue.set(state.customProducts, orgUnitId, products)
    },
    setProductAlgorithms (state, algorithms) {
      state.productAlgorithms = algorithms
    },
    setRecommendedState (state, id) {
      if (!state.portalSettings.recommended.products.includes(id)) {
        state.portalSettings.recommended.products.push(id)
      } else {
        state.portalSettings.recommended.products = state.portalSettings.recommended.products.filter(x => x !== id)
      }
    },
    setRecommendedProductIds (state, ids) {
      state.portalSettings.recommended.products = ids
    },
    ...makeSetters([
      'globalProducts',
      'crops',
      'cropTags',
      'productTags',
      'cropUsages',
      'cropHarvestQualities',
      'productCharacteristics'
    ])
  },
  actions: {
    loadUserPermissions ({commit}) {
      return axios.get('/api/v2/master-data/permissions')
        .then(response => {
          commit('setUserPermissions', response.data, {root: true})
        })
    },
    reloadUnits ({commit}) {
      return smarterGet('/api/v2/masterdata/units', {
        id: 'masterData.units',
        expiry: 3600,
        onResult (units) {
          commit('setUnits', units)
        }
      })
    },
    reloadGlobalProducts ({commit}, ignoreCache) {
      return smarterGet('/api/v2/master-data/products/global', {
        id: 'masterData.products.global',
        expiry: ignoreCache ? 0 : 3600,
        onResult (products) {
          for (const productId in products) {
            addProductColor(products[productId])
          }
          commit('setGlobalProducts', products)
        }
      })
    },
    reloadCustomProducts ({commit}, {ignoreCache, orgUnitId}) {
      return smarterGet('/api/v2/master-data/products/custom/{orgUnitId}', {
        id: 'masterData.products.custom',
        expiry: ignoreCache ? 0 : 3600,
        inputs: {
          orgUnitId: () => orgUnitId // TODO at some point use global navigation module, when orgUnit navigation is extracted from frs
        },
        onResult (products) {
          for (const productId in products) {
            addProductColor(products[productId])
          }
          commit('setCustomProducts', {orgUnitId, products})
        }
      })
    },
    reloadCrops ({commit}, ignoreCache) {
      return smarterGet('/api/v2/master-data/crops', {
        id: 'masterData.crops',
        expiry: ignoreCache ? 0 : 3600,
        onResult (crops) {
          commit('setCrops', crops)
        }
      })
    },
    reloadProductCharacteristics ({commit}) {
      return axios.get('/api/v2/master-data/products/product-yield-characteristics')
      .then(res => res.data)
      .then(characteristics => {
        commit('setProductCharacteristics', characteristics)
      })
    },
    reloadCropTags ({commit}, ignoreCache) {
      return smarterGet('/api/v2/master-data/crop-tags', {
        id: 'masterData.crops.tags',
        expiry: ignoreCache ? 0 : 3600,
        onResult (tags) {
          commit('setCropTags', tags)
        }
      })
    },
    reloadProductTags ({commit}, ignoreCache) {
      return smarterGet('/api/v2/master-data/products/product-tags', {
        id: 'masterData.product.tags',
        expiry: ignoreCache ? 0 : 3600,
        onResult (tags) {
          commit('setProductTags', tags)
        }
      })
    },
    reloadCropUsages ({commit}, ignoreCache) {
      return smarterGet('/api/v2/master-data/crop-usages', {
        id: 'masterData.crops.usages',
        expiry: ignoreCache ? 0 : 3600,
        onResult (usages) {
          commit('setCropUsages', usages)
        }
      })
    },
    reloadCropHarvestQualities ({commit}, ignoreCache) {
      return smarterGet('/api/v2/master-data/crop-harvest-qualities', {
        id: 'masterData.crops.qualities',
        expiry: ignoreCache ? 0 : 3600,
        onResult (qualities) {
          commit('setCropHarvestQualities', qualities)
        }
      })
    },
    reloadProductAlgorithms ({commit}) {
      return smarterGet('/api/v2/master-data/products/algorithms', {
        id: 'masterData.products.algorithms',
        expiry: 3600,
        onResult (algorithms) {
          commit('setProductAlgorithms', algorithms)
        }
      })
    },
    loadPortalSettings ({commit}) {
      return axios.get('/api/v2/master-data/products/recommended')
      .then(response => response.data)
      .then(productIds => {
        commit('setRecommendedProductIds', productIds)
      })
    }
  },
  modules: {
    management,
    cropManagement,
    productSalesManagement
  }
}

const addProductColor = (product) => {
  return Object.assign(product, {color: productColorLookup[product.type]})
}

const productColorLookup = {
  seed: '#00a81b',
  fertilizer: '#a85600',
  protection: '#0099ff'
}
