<template lang="html">
  <div v-if="todo" class="wip-todo">
    <strong class="wip-todo-title">TODO</strong>
    <slot>Draw the rest of the fucking owl</slot>
  </div>
  <div v-else class="wip-wrapper clearfix" :class="{component}">
    <div v-if="note" class="wip-header">
      <i class="fa fa-info-circle" />{{ note }}
    </div>
    <div class="wip-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    todo: Boolean,
    note: String,
    component: Boolean
  }
}
</script>

<style lang="scss" scoped>
$yellow: #eeae1f;
$orange: #ee701f;
$gray: #444444;

.wip-todo {
  padding: 10px;
  background: repeating-linear-gradient(
    -45deg,
    darken($yellow, 25%),
    darken($yellow, 25%) 9px,
    darken($gray, 25%) 9px,
    darken($gray, 25%) 18px
  );
  margin: 10px;
  color: white;

  .wip-todo-title {
    font-size: 1.2em;
    margin-right: 0.2em;
  }

  ::v-deep button {
    border: 2px solid $yellow;
    border-radius: 6px;
    background-color: $gray;
    transition: background-color 0.2s;

    &:hover {
      background-color: lighten($gray, 10%);
    }
  }
}

.wip-wrapper {
  margin: 5px;
  border: 8px solid;
  border-image: repeating-linear-gradient(
    -45deg,
    $yellow,
    $yellow 9px,
    $gray 9px,
    $gray 18px
  ) 8;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-shrink: 0;
}

.wip-header {
  border-bottom: 2px solid;
  border-image: repeating-linear-gradient(
    -45deg,
    $yellow,
    $yellow 9px,
    #000 9px,
    #000 18px
  ) 2;

  padding: 0 3px;

  .fa {
    margin-right: 3px;
  }
}

.component {
  border-image: repeating-linear-gradient(
    -45deg,
    $orange,
    $orange 9px,
    $gray 9px,
    $gray 18px
  ) 8;

  .wip-header {
    border-image: repeating-linear-gradient(
      -45deg,
      $orange,
      $orange 9px,
      #000 9px,
      #000 18px
    ) 2;
  }
}

.wip-content {
  padding: 5px;
  flex: 1;

  display: flex;
  flex-direction: column;
}
</style>
