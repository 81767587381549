require 'src/coffee/controls/tree'

{loadJSON} = require 'src/coffee/helpers'

{addSearchControls, addExpansionControls, addStateHandling} = require 'src/coffee/controls/tree/addons'

setup = ({$element, options, $ctx = $(document), useStateHandling = true}) ->
    $loadingIndicator = $ '<div class="loading-indicator text-center"><i class="fa fa-cog fa-spin fa-5x"></i></div>'

    $element.wrap '<div class="tree-wrapper">'
    $loadingIndicator.insertBefore $element

    options.data or= loadJSON $element.find 'script.nodes'

    tree = $element.tree options

    addSearchControls tree, $element
    addExpansionControls tree, $element

    deferred = $.Deferred()

    if useStateHandling
        promise = addStateHandling tree, $element
    else
        promise = $.Deferred().resolve().promise()

    promise
    .always ->
        $loadingIndicator.hide()
        $element.parent().children().addClass 'in'
        deferred.resolve(tree)
    .fail -> console.error('could not restore tree state')

    return deferred.promise()

module.exports = {
    setup
}

module.exports.__esModule = true
module.exports.default = setup
