import Vue from 'vue'
import newGuid from 'src/js/helpers/new-guid'
import {cloneDeep} from 'lodash'

const cloneValuesAndAreas = (target, source) => {
  const tempValidOnDate = target.validOn
  Object.assign(target, source)
  target.uid = newGuid()
  target.isStart = false
  target.validOn = tempValidOnDate
  target.areas = cloneDeep(source.areas)
  target.leaser = cloneDeep(source.leaser)
  target.lessor = cloneDeep(source.lessor)

  for (let area of target.areas) {
    area.uid = newGuid()
  }
}

const mutations = {
  selectAmendment (state, amendment) {
    state.currentAmendment = amendment
  },
  openCreateOrEdit (state, amendment) {
    if (!amendment) {
      throw Error('No currement amendment')
    }

    state.editAmendment = amendment
    state.isEditAmendmentModalOpen = true
  },
  closeCreateOrEdit (state) {
    state.isEditAmendmentModalOpen = false
    state.editAmendment = null
  },
  openDeleteAmendment (state) {
    if (!state.currentAmendment) {
      throw new Error('No amendment selected')
    }

    state.isDeleteAmendmentModalOpen = true
  },
  closeDeleteAmendment (state) {
    state.isDeleteAmendmentModalOpen = false
  },
  resetAmendments (state) {
    state.selectedAmendment = null
    state.currentAmendment = null
  },

  addAreasToAmendment (state, {segments, localDistrict, cadastralDistrict}) {
    if (!state.currentAmendment) {
      throw new Error('No amendment selected')
    }

    if (segments && segments.length > 0) {
      segments.forEach(segment => {
        const newArea = {
          landParcelIdentifier: `${localDistrict.text}, ${cadastralDistrict.number}, ${segment.landParcelNumber}`,
          fkLandParcel: segment.landParcelId,
          sizeActual: segment.segmentSize,
          sizeContractual: segment.segmentSize,
          usage: {
            value: segment.usageId,
            label: segment.usageShortcut,
            text: segment.usageName
          },
          groundpoints: segment.groundpoints,
          link: null
        }
        state.currentAmendment.areas.push(newArea)
      })
    }

    Vue.set(state.currentAmendment, 'hasAreaChanges', true)
  },

  updateContractualArea (state, area) {
    if (!state.currentAmendment) {
      throw new Error('No amendment selected')
    }

    const index = state.currentAmendment.areas.findIndex(x => x.uid === area.uid)

    if (index < 0) {
      state.currentAmendment.areas.push(area)
    } else {
      Vue.set(state.currentAmendment.areas, index, area)
    }

    Vue.set(state.currentAmendment, 'hasAreaChanges', true)
  },

  addNewlyCreatedSegmentsToAmendment (state, lp) {
    if (!state.currentAmendment) {
      throw new Error('No amendment selected')
    }

    if (!(lp.segments)) {
      return
    }

    for (const segment of lp.segments) {
      const newArea = {
        landParcelIdentifier: `${lp.localDistrict.text}, ${lp.cadastralDistrict}, ${lp.number}`,
        fkLandParcel: lp.uid,
        sizeActual: segment.area,
        sizeContractual: segment.area,
        usage: segment.usage,
        groundpoints: segment.groundpoints,
        link: null
      }

      state.currentAmendment.areas.push(newArea)
    }

    Vue.set(state.currentAmendment, 'hasAreaChanges', true)
  },

  deleteAreaFromAmendment (state, area) {
    if (!state.currentAmendment) {
      throw new Error('No amendment selected')
    }

    area.forEach((area) => {
      const index = state.currentAmendment.areas.findIndex(x => x.uid === area.uid)
      state.currentAmendment.areas.splice(index, 1)
    })

    Vue.set(state.currentAmendment, 'hasAreaChanges', true)
  },

  completeAmendments (state, currentContract) {
    if (currentContract.amendments && currentContract.amendments.length > 0) {
      currentContract.amendments.sort((a, b) => {
        // return new Date(a.validOn) - new Date(b.validOn) // TODO find out if this proposed fix during CORE-910 is correct
      })
      let previousAmendment = null

      currentContract.amendments.forEach(amendment => {
        if (amendment.isNew) {
          cloneValuesAndAreas(amendment, previousAmendment)
          amendment.isNew = false
        }
        previousAmendment = amendment
      })
    }
  },
  addLessor (state) {
    state.currentAmendment.lessor.push({})
    state.isEditChanged = true
  },
  addLeaser (state) {
    state.currentAmendment.leaser.push({})
    state.isEditChanged = true
  },
  removeLessor (state, idx) {
    state.currentAmendment.lessor.splice(idx, 1)
    state.isEditChanged = true
  },
  removeLeaser (state, idx) {
    state.currentAmendment.leaser.splice(idx, 1)
    state.isEditChanged = true
  }
}

export default mutations
