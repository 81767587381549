
import {postJson} from 'src/coffee/infrastructure/ajax'
import contractValidation from './contractValidation'

export default {
  save (contract) {
    const validationErrors = contractValidation.validateContract(contract)
    if (validationErrors && validationErrors.length > 0) {
      return Promise.reject(validationErrors)
    } else {
      return new Promise((resolve, reject) => {
        postJson('/RealEstate/Lease/SaveContract', contract)
        .done((result) => {
          resolve(result)
        })
      .fail((jqXhrm) => {
        let response = null

        if (jqXhrm.responseText) {
          response = JSON.parse(jqXhrm.responseText)
        }

        if (response && response.errors && response.errors.length > 0) {
          reject(response.errors)
        } else {
          reject(jqXhrm.statusText)
        }
      })
      })
    }
  },

  createBankDetailsForContact (data) {
    postJson('/RealEstate/Contacts/CreateBankDetailsForContact', data)
  }
}
