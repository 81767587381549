export const paymentModes = (state) => {
  return state.paymentModes ? state.paymentModes : []
}

export const hasCurrentPaymentRecipient = (state) => {
  return !!state.currentPaymentRecipient
}

export const isEditModalOpen = (state) => {
  return state.isEditModalOpen
}

export const currentPaymentRecipient = (state) => {
  return state.currentPaymentRecipient ? state.currentPaymentRecipient : {}
}

export const editPaymentRecipient = (state) => {
  return state.editPaymentRecipient ? state.editPaymentRecipient : {
    recipient: {}
  }
}
export const isDeleteModalOpen = (state) => {
  return state.isDeleteModalOpen
}

export const selectedPayments = (state) => {
  return state.selectedPayments
}

export const hasCurrentPayment = (state) => {
  return state.selectedPayments.length > 0
}

export const editPayment = (state) => {
  return state.editPayment ? state.editPayment : {currency: null}
}
export const isEditPaymentModalOpen = (state) => {
  return state.isEditPaymentModalOpen
}
export const isDeletePaymentModalOpen = (state) => {
  return state.isDeletePaymentModalOpen
}
