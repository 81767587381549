const mutations = {
  openNavigationGuard (state) {
    state.isSaveChangesModalOpen = true
  },

  closeNavigationGuard (state) {
    state.isSaveChangesModalOpen = false
  }

}

export default mutations
