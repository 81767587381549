# CoffeeScript

{get, postJson} = require 'src/coffee/infrastructure/ajax'
{applyRecursive} = require './helpers'

saveState = (treeId, tree, element) ->
    state =
        nodes: {}

    applyRecursive tree.getAllNodes(), (node) -> state.nodes[node.data.uid] = node.isExpanded if node.data.uid and node.isExpanded

    {scrollTop, scrollLeft} = element

    state.scrollInfo = {scrollTop, scrollLeft}

    postJson '/TreeState',
        TreeId: treeId
        State: state

restoreState = (treeId, tree, element) ->
    get '/TreeState', {treeId}
    .then (state) ->
        f = (node) -> node.isExpanded = state.nodes[node.data.uid] if node.data.uid of state.nodes

        if state.nodes
            applyRecursive tree.getAllNodes(), f
            tree.rebuildTree()

        if state.scrollInfo
            Object.assign(element, state.scrollInfo)

module.exports = {
    saveState,
    restoreState
}
