import axios from 'axios'
import api from './api'
import {geoJsonFormat} from '@helpers/openlayers/features'

function calculateBaseTypeCapacities (allMaterials) {
  // remove all packaging materials
  allMaterials
    .filter(m => !m.materialId)
    .forEach(m => calculateBaseTypeCapacity(allMaterials, m))
}

function calculateBaseTypeCapacity (allMaterials, baseMaterial) {
  allMaterials.forEach(currentMaterial => {
    if (currentMaterial.materialId === baseMaterial.id) {
      currentMaterial.baseTypeCapacity = baseMaterial.baseTypeCapacity > 0
        ? baseMaterial.baseTypeCapacity * currentMaterial.capacity
        : currentMaterial.capacity

      if (currentMaterial.materialId) {
        calculateBaseTypeCapacity(allMaterials, currentMaterial)
      }
    }
  })
}

export default {
  loadPermissions ({commit}) {
    axios.get('/api/PoultryManagementPrototype/permissions')
      .then(response => response.data)
      .then(permissions => commit('setPermissions', permissions))
  },

  navigateToFarmSelection ({commit, dispatch}, clearSelection) {
    commit('setIsInAdminComponent', false)
    commit('setRightPanelContent', 'FarmSelection')
    commit('setLeftPanelContent', 'Map')
    commit('setMapMode', 'displayFarms')

    if (clearSelection) {
      dispatch('setSelectedFarmId', null)
    }
  },

  navigateToPoultryHouseSelection ({commit, dispatch}, clearSelection) {
    commit('setRightPanelContent', 'PoultryHouseSelection')
    commit('setLeftPanelContent', 'Map')
    commit('setMapMode', 'displayPoultryHouses')

    if (clearSelection) {
      dispatch('setSelectedPoultryHouseId', null)
    }
  },

  loadFarms ({commit, state}) {
    api.get('/api/PoultryManagementPrototype/getFarms', {date: state.ui.selection.date})
      .then(result => result.data.map(farm => {
        return {...farm, feature: farm.feature && geoJsonFormat.readFeature(farm.feature, {dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857'})}
      }))
      .then(farms => commit('setFarms', farms))
  },

  loadPoultryHouses ({commit, state}) {
    api.get('/api/PoultryManagementPrototype/getPoultryHouses', {date: state.ui.selection.date})
      .then(result => result.data.map(house => {
        return {...house, feature: house.feature && geoJsonFormat.readFeature(house.feature, {dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857'})}
      }))
      .then(poultryHouses => commit('setPoultryHouses', poultryHouses))
  },

  loadFlocks ({commit, state}) {
    api.get('/api/PoultryManagementPrototype/getFlocks', {date: state.ui.selection.date})
      .then(result => commit('setFlocks', result.data))
  },

  loadLivestock ({commit, state}) {
    api.get('/api/PoultryManagementPrototype/getLivestockToDate', {date: state.ui.selection.date})
      .then(result => commit('setLivestock', result.data))
  },

  loadPoultryHouseVisits ({commit, state}) {
    api.get('/api/PoultryManagementPrototype/getPoultryHouseVisits', {from: state.ui.selection.date})
      .then(result => commit('setPoultryHouseVisits', result.data))
  },

  loadMaterials ({commit}) {
    api.get('/api/PoultryManagementPrototype/getMaterials')
      .then(materials => {
        commit('setMaterials', materials.data)
        calculateBaseTypeCapacities(materials.data)
      })
  },

  loadFeeds ({commit}) {
    api.get('/api/PoultryManagementPrototype/getFeeds')
      .then(feeds => commit('setFeeds', feeds.data))
  },

  setSelectedFarmId ({commit, dispatch}, id) {
    commit('setSelectedFarmId', id)
    dispatch('setSelectedPoultryHouseId', null)
  },
  setSelectedPoultryHouseId ({commit, dispatch}, id) {
    commit('setSelectedPoultryHouseId', id)
    dispatch('setSelectedPoultryHouseVisit', null)
  },
  setSelectedPoultryHouseVisit ({commit}, number) {
    commit('setSelectedPoultryHouseVisit', number)
  },

  setContent ({commit}, leftPanelContent, rightPanelContent) {
    commit('setLeftPanelContent', leftPanelContent)
    commit('setRightPanelContent', rightPanelContent)
  },

  updateFoodConsumptionState ({state}, resetFlag) {
    // very quick and very dirty solution to update food consumption state
    let visitNumber = state.ui.selection.poultryHouse.visit - 1
    let visit = state.data.poultryHouseVisits[visitNumber]
    if (visit && visit.foodConsumptionRecord && !resetFlag) {
      state.foodConsumption.male = visit.foodConsumptionRecord.male
      state.foodConsumption.female = visit.foodConsumptionRecord.female
    } else {
      state.foodConsumption.female = {
        actualFeedAmountPerAnimal: null,
        actualFeedAmountPerDay: null,
        targetFeedAmountPerAnimalToday: null,
        targetFeedAmountPerDayToday: null,
        targetFeedAmountPerDayTomorrow: null,
        targetFeedAmountPerAnimalTomorrow: null,
        usedFeedId: null,
        siloNumber: null,
        siloLevelPercent: null,
        waterPerDay: null,
        waterPerAnimal: null
      }
      state.foodConsumption.male = {
        actualFeedAmountPerAnimal: null,
        actualFeedAmountPerDay: null,
        targetFeedAmountPerAnimalToday: null,
        targetFeedAmountPerDayToday: null,
        targetFeedAmountPerDayTomorrow: null,
        targetFeedAmountPerAnimalTomorrow: null,
        usedFeedId: null,
        siloNumber: null,
        siloLevelPercent: null,
        waterPerDay: null,
        waterPerAnimal: null
      }
    }
  },

  setSelectedDate ({commit, dispatch, state}, date) {
    if (date !== state.ui.selection.date) {
      commit('setSelectedDate', date)
      Promise.all([
        dispatch('loadFarms'),
        dispatch('loadPoultryHouses'),
        dispatch('loadFlocks'),
        dispatch('loadLivestock'),
        dispatch('loadPoultryHouseVisits')
      ])
    }
  },

  savePoultryHouseVisitLivestockRecord ({dispatch}, record) {
    api.post('/api/PoultryManagementPrototype/savePoultryHouseVisitLivestockRecord', record)
      .then(() => Promise.all([dispatch('loadLivestock'), dispatch('loadPoultryHouseVisits')]))
  },

  savePoultryHouseVisitEggRecord ({dispatch}, record) {
    api.post('/api/PoultryManagementPrototype/savePoultryHouseVisitEggRecord', record)
      .then(() => dispatch('loadPoultryHouseVisits'))
  },

  savePoultryHouseVisitFoodConsumptionRecord ({state, dispatch}) {
    const record = {
      date: state.ui.selection.date,
      poultryHouseId: state.ui.selection.poultryHouse.id,
      visit: state.ui.selection.poultryHouse.visit,
      foodConsumptionRecord: {
        male: state.foodConsumption.male,
        female: state.foodConsumption.female,
        note: state.foodConsumption.note
      }
    }
    api.post('/api/PoultryManagementPrototype/savePoultryHouseVisitFoodConsumptionRecord', record)
      .then(() => dispatch('loadPoultryHouseVisits'))
  },

  savePoultryHouseVisitAnimalWeightRecord ({dispatch}, record) {
    api.post('/api/PoultryManagementPrototype/savePoultryHouseAnimalWeightRecord', record)
      .then(() => dispatch('loadPoultryHouseVisits'))
  }
}
