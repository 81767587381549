import foodConsumptionData from './food-consumption-data'
import {makeSetters} from '@helpers/vuex/mutations'

export default {
  namespaced: true,
  state: {
    note: ''
  },
  mutations: {
    ...makeSetters([
      'note'
    ])
  },
  modules: {
    male: foodConsumptionData,
    female: foodConsumptionData
  }
}
