import newGuid from 'src/js/helpers/new-guid'
import commandQueue from '../api/commandQueue'

export const selectPaymentRecipient = ({commit}, paymentRecipient) => {
  commit('selectPaymentRecipient', paymentRecipient)
}

export const updatePaymentRecipient = ({commit}, paymentRecipient) => {
  commit('updatePaymentRecipient', paymentRecipient)
}
export const selectPayments = ({commit}, payments) => {
  commit('selectPayments', payments)
}

export const createNewPaymentRecipientSuccess = ({commit}) => {
  commit('addPaymentRecipientToContract')
  commit('realEstateContractLease/contracts/setFinancesChanged', null, {root: true})
}

export const createRequest = ({commit, rootState}) => {
  const currentContract = rootState.realEstateContractLease.contracts.currentContract
    ? rootState.realEstateContractLease.contracts.currentContract
    : rootState.realEstateContractLease.contracts.newContract

  if (!currentContract) {
    throw new Error('No contract selected')
  }

  let newPaymentRecipient = {
    uid: newGuid(),
    recipient: null,
    paymentMode: null,
    currency: {
      uid: '346c8459-bfb3-4054-ad9c-e08cd17792fe',
      symbol: '€'
    },
    iban: '',
    bic: '',
    share: 0,
    flatSum: 0,
    transactionComment: '',
    typeOfPayment: ''
  }

  commit('openCreateOrEdit', newPaymentRecipient)
}

export const editRequest = ({commit, state}) => {
  if (!state.currentPaymentRecipient) {
    throw new Error('No payment recipient selected')
  }

  commit('openCreateOrEdit', state.currentPaymentRecipient)
}

export const createOrEditPaymentRecipientSuccess = ({commit, state}, entityId) => {
  if (!state.editPaymentRecipient) {
    throw new Error('No payment recipient selected')
  }

  if (state.editPaymentRecipient.saveNewBankDetails) {
    let paymentRecipient = state.editPaymentRecipient
    let data = {
      iban: paymentRecipient.iban,
      swiftBic: paymentRecipient.bic ? paymentRecipient.bic.trim() : '',
      name: paymentRecipient.bankName ? paymentRecipient.bankName.trim() : '',
      contactId: paymentRecipient.recipient.value,
      entityId: entityId
    }
    commandQueue.createBankDetailsForContact(data)
  }

  commit('realEstateContractLease/contracts/addOrUpdatePaymentRecipientToContract', state.editPaymentRecipient, {root: true})
  commit('selectPaymentRecipient', state.editPaymentRecipient)
  commit('closePaymentRecipientForm')
  commit('realEstateContractLease/contracts/setFinancesChanged', null, {root: true})
}

export const createOrEditFailure = ({commit}) => {
  commit('closePaymentRecipientForm')
}

export const deleteRequest = ({commit, rootState}) => {
  const currentContract = rootState.realEstateContractLease.contracts.currentContract
    ? rootState.realEstateContractLease.contracts.currentContract
    : rootState.realEstateContractLease.contracts.newContract

  if (!currentContract) {
    throw new Error('No contract selected')
  }

  commit('openDelete')
}

export const deleteSuccess = ({commit, state}) => {
  if (!state.currentPaymentRecipient) {
    throw new Error('No payment recipient selected')
  }

  commit('realEstateContractLease/contracts/removePaymentRecipientFromContract', state.currentPaymentRecipient, {root: true})
  commit('closeDelete')
  commit('realEstateContractLease/contracts/setFinancesChanged', null, {root: true})
}

export const deleteFailure = ({commit}) => {
  commit('closeDelete')
}

export const createNewPaymentSuccess = ({commit}) => {
  commit('addPaymentRecipientToContract')
}

export const createPaymentRequest = ({commit, rootState}) => {
  const currentContract = rootState.realEstateContractLease.contracts.currentContract
    ? rootState.realEstateContractLease.contracts.currentContract
    : rootState.realEstateContractLease.contracts.newContract

  if (!currentContract) {
    throw new Error('No contract selected')
  }

  let newPayment = {
    uid: newGuid(),
    recipient: null,
    transactionComment: '',
    amount: 0,
    currency: {
      uid: '346c8459-bfb3-4054-ad9c-e08cd17792fe',
      symbol: '€'
    },
    typeOfPayment: '',
    lessorIBAN: '',
    lessorBIC: '',
    leaserIBAN: '',
    leaserBIC: '',
    dateDue: null,
    datePaid: null,
    vetoed: false

  }

  commit('openCreateOrEditPayment', newPayment)
}

export const editPaymentRequest = ({commit, state}) => {
  if (state.selectedPayments.length === 0) {
    throw new Error('No payment selected')
  }

  if (state.selectedPayments.length !== 1) {
    throw new Error('More the one payment selected')
  }

  commit('openCreateOrEditPayment', state.selectedPayments[0])
}

export const createOrEditPaymentSuccess = ({commit, state}) => {
  if (!state.editPayment) {
    throw new Error('No payment selected')
  }

  commit('realEstateContractLease/contracts/addOrUpdatePaymentToContract', state.editPayment, {root: true})
  commit('closeCreateOrEditPayment')
  commit('realEstateContractLease/contracts/setFinancesChanged', null, {root: true})
}

export const addPayments = ({commit}, payments) => {
  for (let payment of payments) {
    commit('realEstateContractLease/contracts/addOrUpdatePaymentToContract', payment, {root: true})
  }
  commit('realEstateContractLease/contracts/setFinancesChanged', null, {root: true})
}

export const createOrEditPaymentFailure = ({commit}) => {
  commit('closeCreateOrEditPayment')
}

export const deletePaymentRequest = ({commit, rootState}) => {
  const currentContract = rootState.realEstateContractLease.contracts.currentContract
    ? rootState.realEstateContractLease.contracts.currentContract
    : rootState.realEstateContractLease.contracts.newContract

  if (!currentContract) {
    throw new Error('No contract selected')
  }

  commit('openPaymentDelete')
}

export const deletePaymentSuccess = ({commit, state}) => {
  if (state.selectedPayments.length === 0) {
    throw new Error('No payment selected')
  }

  commit('realEstateContractLease/contracts/removePaymentFromContract', state.selectedPayments, {root: true})
  commit('closePaymentDelete')
  commit('realEstateContractLease/contracts/setFinancesChanged', null, {root: true})
  commit('selectPayments', [])
}

export const deletePaymentFailure = ({commit}) => {
  commit('closePaymentDelete')
}
