import {isMaize} from '@helpers/products'

const getters = {
  displayNewProduct (state) {
    return state.editBuffer && !state.editBuffer.id
  },
  displayDetails (state, getters) {
    return (state.editBuffer && state.ui.selectedProductIds.length === 1) || getters.displayNewProduct
  },
  displaySaveButton (state, getters) {
    return (state.editBuffer && state.ui.selectedProductIds.length === 1) || getters.displayNewProduct
  },
  displayArchiveButton (state) {
    return state.ui.selectedProductIds !== null && state.ui.selectedProductIds.length > 0
  },
  displayDiscardButton (state, getters) {
    return getters.displayNewProduct
  },
  displayMultipleSelectionSummary (state) {
    return state.ui.selectedProductIds.length > 1
  },
  isMaize (state) {
    return isMaize(state.editBuffer)
  }
}

export default getters
