import mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'

const state = {
  paymentModes: [],
  currentPaymentRecipient: null,
  editPaymentRecipient: null,
  isEditModalOpen: false,
  isDeleteModalOpen: false,
  selectedPayments: [],
  editPayment: null,
  isEditPaymentModalOpen: false,
  isDeletePaymentModalOpen: false
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
