
const loadLocaleFromCookie = () => {
  const cookieSplitterRegex = /(?:(\w+)=([^;]+))/g
  const localeRegex = /c.+(\w{2}-\w{2}).+uic.+/
  const cookies = {}

  let finished = false
  while (!finished) {
    const match = cookieSplitterRegex.exec(document.cookie)
    if (match) {
      cookies[match[1]] = match[2]
    }
    if (cookieSplitterRegex.lastIndex === 0) {
      finished = true
    }
  }

  const cultureCookie = localeRegex.exec(cookies.ASPNET_CULTURE)

  const locale = cultureCookie ? cultureCookie[1] : 'en-US'
  const language = locale.split('-')[0]

  return {
    language,
    locale
  }
}

export default loadLocaleFromCookie
