import mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'

const state = {
  currentArea: null,
  isEditAreaModalOpen: false,
  isDeleteAreaModalOpen: false,
  currentLocalDistrict: null,
  localDistricts: [],
  currentCadastralDistrict: null,
  cadastralDistricts: [],
  currentLandParcelNumber: null,
  landParcelNumbers: [],
  segments: [],
  selectedSegments: []
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
