export const dmsReferenceTypes = (state) => {
  return state.dmsReferenceTypes ? state.dmsReferenceTypes : []
}

export const hasCurrentDmsReference = (state) => {
  return !!state.currentDmsReference
}

export const isEditDmsReferenceModalOpen = (state) => {
  return state.isEditDmsReferenceModalOpen
}

export const currentDmsReference = (state) => {
  return state.currentDmsReference ? state.currentDmsReference : {}
}

export const editDmsReference = (state) => {
  return state.editDmsReference ? state.editDmsReference : {type: null}
}

export const isDeleteDmsReferenceModalOpen = (state) => {
  return state.isDeleteDmsReferenceModalOpen
}
