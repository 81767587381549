{Validation} = require './validation-helper'


class SelectionGridValidationExtension
    constructor: (@grid, $eventSink) ->
        @validationStates = {}

        $eventSink.on 'change:validation', @handleValidationEvent
        @grid.editor.on 'preRemove', @handleRowRemove


    reset: =>
        @validationStates = {}
        @triggerValidationEvent()


    handleValidationEvent: (event, {isValid, control}) =>
        event.stopPropagation()

        selectedRowId = @grid.table.rows({selected: true}).data()[0].DT_RowId
        state = @getRowState(selectedRowId)
        state[control[0].id] = isValid

        @grid.selectionExtension?.markRowValidationState(selectedRowId, Validation.isStateValid(state))
        @triggerValidationEvent()


    triggerValidationEvent: =>
        args = {
            isValid: Validation.isStateValid(@validationStates)
            control: @grid.$container
        }
        @grid.$container.trigger('change:validation', [args])


    handleRowRemove: (event, json) =>
        rowIds = @grid.table.rows({selected: true}).data().map((row) -> row.DT_RowId)
        for id in rowIds
            delete @validationStates[id]
        @triggerValidationEvent()


    getRowState: (rowId) ->
        if(@validationStates[rowId] is undefined)
            @validationStates[rowId] = {}

        return @validationStates[rowId]


attachSelectionExtension = (grid, $eventSink) ->
    #assign to grid property to prevent garbage collection
    grid.selectionGridValidationExtension = new SelectionGridValidationExtension(grid, $eventSink)


module.exports = {
    SelectionGridValidationExtension,
    attachSelectionExtension
}

module.exports.__esModule = true
module.exports.default = attachSelectionExtension
