# CoffeeScript

{applyRecursive} = require './helpers'
{saveState, restoreState} = require './state'

addStateHandling = (tree, $element) ->
    treeId = $element.data 'tree-id'

    if not treeId
        return $.Deferred().resolve().promise()

    handler = ->
        saveState treeId, tree, $element.get(0)
        return undefined
    window.addEventListener 'beforeunload', handler
    $element.data 'save-state-handler', handler
    $element.one 'unload', ->
        window.removeEventListener 'beforeunload', handler
        handler()
    restoreState treeId, tree, $element.get(0)

removeStateHandling = ($element) ->
    handler = $element.data 'save-state-handler'
    $element.off 'unload'
    window.removeEventListener 'beforeunload', handler
    $element.data 'save-state-handler', null

addExpansionControls = (tree, $element) ->
    $data = $ '.easytree-addon-data'

    $control = $ '<div>'
    .addClass 'easytree-expansion-control fade'
    .insertBefore $element

    $ '<button>'
    .addClass 'btn btn-primary btn-xs easytree-collapse-all'
    .text $data.data('collapseText') or 'Collapse'
    .on 'click', ->
        nodes = tree.getAllNodes()
        applyRecursive nodes, (node) -> node.isExpanded = false
        tree.rebuildTree()
    .appendTo $control

    $ '<button>'
    .addClass 'btn btn-primary btn-xs easytree-expand-all'
    .text $data.data('expandText') or 'Expand'
    .on 'click', ->
        nodes = tree.getAllNodes()
        applyRecursive nodes, (node) -> node.isExpanded = true
        tree.rebuildTree()
    .appendTo $control

addSearchControls = (tree, $element) ->
    $data = $ '.easytree-addon-data'

    $control = $ '<div>'
    .addClass 'easytree-search-control fade'
    .insertBefore $element

    unfilteredNodes = null

    matchingNodes = []
    activeMatch = 0

    deepCopy = (node) -> $.extend(true, {}, node)

    save = ->
        unfilteredNodes = tree.getAllNodes()

    revert = ->
        tree.rebuildTree unfilteredNodes
        unfilteredNodes = null

    recursiveCollapse = (node) ->
        node.isExpanded = false
        for child in node.children
            recursiveCollapse child

    recursiveSearch = (node, regex) ->
        directMatch = regex.test node.text?.toLowerCase()

        if directMatch
            matchingNodes.push node
            node.searchMatched = true
            node.liClass = 'search-match'

        childResults = node
            .children
            .map (node) -> recursiveSearch node, regex
            .filter (x) -> x

        if childResults.length
            node.searchMatchedChildren = true
            node.isExpanded = true
        else
            node.isExpanded = false

        return node.searchMatched or node.searchMatchedChildren

    recursivePrune = (node) ->
        unless node.searchMatched
            node.children = pruned node.children
        unless node.searchMatched or node.searchMatchedChildren
            return null
        return node

    pruned = (nodes) ->
        return nodes
            .map recursivePrune
            .filter (x) -> x

    filter = (text) ->
        searchResult = unfilteredNodes.map deepCopy

        matchingNodes = []
        activeMatch = 0

        pattern = text.toLowerCase().replace(/\s/g, '').split('').reduce (a, b) -> "#{a}[^#{b}]*#{b}"
        regex = new RegExp pattern

        for node in searchResult
            recursiveSearch node, regex

        matchingNodes[0]?.liClass += ' active-match'

        searchResult = pruned searchResult

        tree.rebuildTree searchResult

    $ '<input>'
    .addClass 'form-control'
    .on 'input', (event) ->
        $input = $ event.target
        searchText = $input.val()

        save() if not unfilteredNodes and searchText

        if searchText
            filter(searchText)
        else if unfilteredNodes
            revert()
    .on 'keydown', (event) ->
        if matchingNodes.length
            if event.keyCode is 13
                $node = $element
                .find("li > span##{matchingNodes[activeMatch].id}")

                $a = $node.find 'a'
                if $a.length
                    $a.click()
                else
                    $node.find('.easytree-title span').trigger 'click'
                return false
            if event.keyCode is 9
                $element.find('.active-match').removeClass 'active-match'
                if event.shiftKey
                    activeMatch = (activeMatch - 1 + matchingNodes.length) % matchingNodes.length
                else
                    activeMatch = (activeMatch + 1) % matchingNodes.length
                $node = $element
                .find("li > span##{matchingNodes[activeMatch].id}").addClass 'active-match'
                return false

    .appendTo $control

module.exports = {
    addStateHandling,
    removeStateHandling,
    addExpansionControls,
    addSearchControls
}
