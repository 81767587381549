
export const hasCurrentTerm = (state) => {
  return !!state.currentTerm
}

export const isEditTermModalOpen = (state) => {
  return state.isEditTermModalOpen
}

export const currentTerm = (state) => {
  return state.currentTerm ? state.currentTerm : {}
}

export const editTerm = (state) => {
  return state.editTerm ? state.editTerm : {currency: null}
}

export const isDeleteTermModalOpen = (state) => {
  return state.isDeleteTermModalOpen
}

export const currencies = (state) => {
  return state.currencies
}
