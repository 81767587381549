import {maxBy, minBy} from 'lodash'

const emptyContract = () => {
  let contract = {
    isRentOut: false,
    certificateNumber: '',
    contractDate: '',
    leaseObject: {},
    comments: '',
    paymentMode: '',
    payDueDay: '',
    paymentRecipients: [],
    payments: [],
    dmsReferences: [],
    amendments: [
      {
        isStart: false,
        validOn: null,
        lessor: [{}],
        leaser: [{}],
        lessorList: '',
        leaserList: '',
        isExchangeAllowed: false,
        isWaterPaidByLeaser: false,
        isTaxPaidByLeaser: false,
        areas: []
      }
    ],
    terms: [
      {
        uid: null,
        start: null,
        end: null,
        leasePrice: null,
        currency: {symbol: ''},
        remark: null
      }
    ],
    autoRenew: false,
    autoRenewalPeriod: 0,
    cancellationPeriod: 0
  }

  return contract
}

export const currentPermissions = (state) => {
  return state.permissions ? state.permissions : {leaseContractCanEdit: true, leaseContractView: true}
}

export const currentContract = (state) => {
  let contract = state.currentContract

  if (!contract) {
    contract = emptyContract()
  }

  return contract
}

export const hasCurrentContract = (state) => {
  return !!state.currentContract
}

export const isCreateLeaseContract = (state) => {
  return state.isCreateLeaseContract
}

export const isEditLeaseContract = (state) => {
  return state.isEditLeaseContract
}

export const newContract = (state) => {
  return state.newContract ? state.newContract : {}
}

export const isEditChanged = (state) => {
  return state.isEditChanged
}

export const status = (state) => {
  return state.status
}

export const termFrom = (state) => {
  const currentContract = state.currentContract ? state.currentContract : state.newContract

  if (currentContract && currentContract.terms && currentContract.terms.length > 0) {
    const firstTerm = minBy(currentContract.terms, (term) => term.start)
    if (firstTerm) {
      return firstTerm.start
    }
  }
}

export const termTo = (state) => {
  const currentContract = state.currentContract ? state.currentContract : state.newContract

  if (currentContract && currentContract.terms && currentContract.terms.length > 0) {
    const lastTerm = maxBy(currentContract.terms, (term) => term.end)
    if (lastTerm) {
      return lastTerm.end
    }
  }
}
