import 'core-js'

import Vue from 'vue'
import VueRouter from 'vue-router'
import PortalVue from 'portal-vue'

import './composition-root' // set up infrastructure dependencies

import 'bootstrap'

// TODO modularize all helpers currently still on the window object
import 'src/coffee/helpers'
import 'src/coffee/helpers/validation'
import 'src/coffee/helpers/download'
import 'src/coffee/controls/modals/binary-question'

import App from 'src/coffee/infrastructure/page'
import DebugOverlay from 'src/coffee/infrastructure/debug-overlay'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import VueVirtualScroller from 'vue-virtual-scroller'
import VTooltip from 'v-tooltip'
import WorkInProgress from '@components/WorkInProgress'
import I18n from 'src/vue/plugins/I18n'

import store from '@store'

import {setTheme} from './theme'

Vue.use(VueVirtualScroller)

Vue.use(VTooltip, {
  defaultTemplate: '<div class="v-tooltip" role="tooltip"><div class="v-tooltip-arrow"></div><div class="v-tooltip-inner"></div></div>',
  defaultArrowSelector: '.v-tooltip-arrow',
  defaultInnerSelector: '.v-tooltip-inner',
  popover: {
    defaultBaseClass: 'v-tooltip v-popover',
    defaultInnerClass: 'v-tooltip-inner v-popover-inner',
    defaultArrowClass: 'v-tooltip-arrow v-popover-arrow'
  }
})

Vue.component('wip', WorkInProgress)

Vue.use(VueRouter)
Vue.use(PortalVue)

Vue.use(I18n, {store})

$(() => {
  setTheme()

  const requiredFeatures = [
    window.Proxy,
    Object.values
  ]
  if (requiredFeatures.some(x => !x)) {
    location.href = '/BrowserNotSupported'
    return
  }

  // NOTE legacy reasons
  // eslint-disable-next-line no-new
  new App(document.body)

  if (window.DEBUG && !$('.debug-overlay').length) {
    // NOTE legacy reasons
    // eslint-disable-next-line no-new
    new DebugOverlay()
  }
})
