# CoffeeScript
{register} = require 'src/js/infrastructure/setup-registry'
{post, postJson} = require 'src/coffee/infrastructure/ajax'
{makeSuccessHandler, makeErrorHandler} = require 'src/coffee/helpers/async/notifications'
{makeReplaceHandler} = require 'src/coffee/helpers/async/fragment-loading'
{notifications, waitscreen} = require 'src/js/infrastructure'

converterSelectionSetup = ($ctx) ->
    $modal = $ctx.find('.modal')
    $converter = $modal.find('select#converter')
    $downloadButton = $modal.find('button[type=submit]')

    converterOptionsUrl = $modal.data 'converter-options-url'

    loadConverterOptions = ->
        converter = $converter.val()

        return unless converter

        converterData =
            converter: converter
            geoDataSelection: $modal.data('legacy-geodata')
            borderType: 'AgriculturalParcel'
            featureType: $modal.data('feature-type')

        promise = post converterOptionsUrl, converterData
        .then makeReplaceHandler $modal.find('.converter-options')
        .then -> $downloadButton.enable()

        waitscreen.waitFor promise

    $converter.on 'change', loadConverterOptions

    loadConverterOptions()

    conversionUrl = $modal.data 'conversion-url'
    baseDownloadUrl = $modal.data 'download-base-url'

    $download = $modal.find('a#download-anchor')

    $downloadButton.on 'click', (event) ->
        converter = $converter.val()

        if not converter
            notifications.warning $modal.data 'no-converter-selected-message'
            return

        # TODO see if it is possible to make converter options a bit less confusing
        metaData = {}

        for item in $modal.find('input[data-metadata="metadata"]')
            metaData[item.id] = item.value

        # for item in $modal.find('select[data-metadata="metadata"]')
        #     option = item.selectedOptions[0]
        #     metaData[option.id] = option.value

        # TODO refactor productInfo handling completely, probably not sensible until converters get refactored
        productInfos = for item in $modal.find('div[data-metadata="selecteditem"]')
            applicationMapId: $modal.find(item).data('map-id')
            productId: $modal.find(item).data('product-id')

        geodata = $modal.data 'geodata'
        data = {converter, metaData, productInfos, geodata}

        promise = postJson conversionUrl, data
        .then (filename) ->
            downloadUrl = baseDownloadUrl + '/' + filename
            $download.attr 'href', downloadUrl
        .then ->
            $modal.modal('hide')
            $download[0].click()
        .then makeSuccessHandler $downloadButton
        .fail makeErrorHandler $downloadButton
        waitscreen.waitFor promise

module.exports.__esModule = true
module.exports.default = converterSelectionSetup

register('Download.ConverterSelectionSetup', converterSelectionSetup)
