import axios from 'axios'

import farmer from './farmer'
import productionManager from './production-manager'
import navigation from './navigation'
import {uniq, flatten} from 'lodash'

const state = {
  permissions: [], // list of permissions/roles
  permissionsNg: {}, // permissions ng mapped on entity
  initComplete: false,
  tree: [],
  ui: {
    currentView: 'productionManager' // productionManager, farmer
  },
  commonData: {
    breeds: []
  }
}

const mutations = {
  setInitComplete (state, value) {
    state.initComplete = value
  },

  setPermissions (state, permissions) {
    state.permissions = permissions
  },

  setPermissionsNg (state, permissions) {
    state.permissionsNg = permissions
  },

  goToView (state, view) {
    state.ui.currentView = view
  },

  setBreeds (state, breeds) {
    state.commonData.breeds = breeds
  }
}

const actions = {
  async loadPermissions ({commit, getters}) {
    return axios.get('/api/PoultryManagement/permissions')
      .then(response => response.data)
      .then(permissions => commit('setPermissions', permissions))
  },
  async loadPermissionsNg ({commit}) {
    return axios.get('/api/PoultryManagement/permissions-ng')
      .then(response => response.data)
      .then(permissions => commit('setPermissionsNg', permissions))
  },
  async checkRoleAndGotoFarm ({state, getters, dispatch}, orgUnit) {
    if (state.ui.currentView === 'farmer') {
      return dispatch('gotoFarmerFarm', orgUnit)
    }

    return getters.hasProductionManagerPermission ? dispatch('gotoProductionManagerFarm', orgUnit) : dispatch('gotoFarmerFarm', orgUnit)
  },
  async gotoFarmerFarm ({commit, dispatch}, orgUnit) {
    commit('goToView', 'farmer')
    commit('farmer/resetState')
    return dispatch('farmer/goToFarm', orgUnit)
  },
  async gotoProductionManagerFarm ({commit, dispatch}, orgUnit) {
    commit('goToView', 'productionManager')
    return dispatch('productionManager/goToFarm', orgUnit)
  },
  async loadBreeds ({commit}) {
    return axios.get('/api/poultrymanagement/production/flocks/breeds')
    .then((result) => commit('setBreeds', result.data))
  }
}

const getters = {
  permissions (state) {
    return state.permissions
  },
  currentView (state) {
    return state.ui.currentView
  },
  breeds (state) {
    return state.commonData.breeds
  },
  hasProductionManagerPermission (state) {
    return uniq(flatten(Object.values(state.permissionsNg))).includes('ProductionManagerView')
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
  modules: {
    farmer,
    productionManager,
    navigation
  }
}
