import {cloneDeep} from 'lodash'
import moment from 'moment'

export const selectAmendment = ({commit}, amendment) => {
  commit('selectAmendment', amendment)
}

const getCurrentContract = rootState => {
  const currentContract = rootState.realEstateContractLease.contracts.currentContract
    ? rootState.realEstateContractLease.contracts.currentContract
    : rootState.realEstateContractLease.contracts.newContract
  if (!currentContract) {
    throw new Error('No contract selected')
  }

  return currentContract
}

export const createAmendmentRequest = ({commit}) => {
  let newAmendment = {
    isNew: true, // marker for completeAmendments mutation
    isStart: true,
    validOn: moment().toISOString(),
    lessor: [],
    leaser: [],
    isExchangeAllowed: false,
    isWaterPaidByLeaser: true,
    isTaxPaidByLeaser: true,
    areas: []
  }

  commit('openCreateOrEdit', newAmendment)
}

export const editAmendmentRequest = ({commit, state}) => {
  if (!state.currentAmendment) {
    throw new Error('No amendment selected')
  }

  let amendment = cloneDeep(state.currentAmendment)

  commit('openCreateOrEdit', amendment)
}

export const createOrEditAmendmentSuccess = ({commit, state, rootState}) => {
  if (!state.editAmendment) {
    throw new Error('No amendment selected')
  }

  commit('realEstateContractLease/contracts/addOrUpdateAmendmentToContract', state.editAmendment, {root: true})

  const currentContract = getCurrentContract(rootState)
  commit('completeAmendments', currentContract)

  commit('selectAmendment', state.editAmendment)
  commit('closeCreateOrEdit')
  commit('realEstateContractLease/contracts/setAmendmentsChanged', null, {root: true})
}

export const createOrEditAmendmentFailure = ({commit}) => {
  commit('closeCreateOrEdit')
}

export const addLeaser = ({commit}) => {
  commit('addLeaser')
}

export const addLessor = ({commit}) => {
  commit('addLessor')
}

export const removeLeaser = ({commit}, idx) => {
  commit('removeLeaser', idx)
}

export const removeLessor = ({commit}, idx) => {
  commit('removeLessor', idx)
}

export const deleteAmendmentFailure = ({commit}) => {
  commit('closeDeleteAmendment')
}

export const deleteAmendmentRequest = ({commit, rootState}) => {
  getCurrentContract(rootState)
  commit('openDeleteAmendment')
}

export const deleteAmendmentSuccess = ({commit, state}) => {
  if (!state.currentAmendment) {
    throw new Error('No amendment selected')
  }

  commit('realEstateContractLease/contracts/removeAmendmentFromContract', state.currentAmendment, {root: true})
  commit('closeDeleteAmendment')
  commit('realEstateContractLease/contracts/setAmendmentsChanged', null, {root: true})
}

export const deletAmendmentFailure = ({commit}) => {
  commit('closeDeleteAmendment')
}
