require 'legacy-scripts/vendor/jquery/jquery.form'

{notifications, waitscreen} = require 'src/js/infrastructure'

setup = ({$element, options, $ctx = $(document)}) ->
    unless $element.prop 'novalidate'
        validator = $element.validate()

        $element.data 'validator', validator

        $element.on 'submit', (event) -> validator.valid()
        $element.find('select').on 'change', (event) -> $(event.currentTarget).valid()

    if $element.data 'prevent-submit'
        $element.on 'submit', -> false

    # enable ajax forms
    if $element.data 'ajax-form'
        saveSuccess = $element.data 'success-message'
        saveError = $element.data 'error-message'
        saveInfo = $element.data 'info-message'

        successCallback = ->
            waitscreen.hide()
            if saveSuccess
                notifications.success saveSuccess
            if saveInfo
                notifications.info saveInfo
        formOptions =
            beforeSubmit: (arr, $form, options) -> $form.validate().valid()
            beforeSend: -> waitscreen.show()
            beforeSerialize: ($form, options) -> # de-localize numeric values etc.
            success: successCallback
            target: $element.data 'target'
            error: ->
                waitscreen.hide()
                if saveError
                    notifications.criticalError saveError

        $element.ajaxForm formOptions

    return $.Deferred().resolve().promise()

module.exports = {
    setup
}

module.exports.__esModule = true
module.exports.default = setup
