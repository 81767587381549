import {get} from 'src/coffee/infrastructure/ajax'
import axios from 'axios'

const convertToNode = (orgUnit) => {
  return {
    uid: orgUnit.content.uid,
    name: orgUnit.content.name,
    template: orgUnit.content.template,
    archived: orgUnit.content.archived,
    children: (orgUnit.children !== undefined) ? orgUnit.children.map(convertToNode).sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0) : []
  }
}

export default {
  getLeaseContractById (uid, cb, errorCb) {
    return Promise.resolve(get('/RealEstate/Lease/GetContract', {id: uid})
    .done((result) => {
      for (let amendment of result.contract.amendments) {
        amendment.lessorList = amendment.lessor.map(lessor => lessor.text).join(', ')
        amendment.leaserList = amendment.leaser.map(leaser => leaser.text).join(', ')
      }
      cb(result)
    })
    .fail(
      errorCb
    ))
  },
  getTreeNodes (cb) {
    return Promise.resolve(get('/RealEstate/Contract/Tree')
    .done((result) => {
      cb(result.map(convertToNode).sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
    })
    .fail(() => {
      cb(new Array(0))
    }))
  },
  getPaymentModes (entityId, cb) {
    if (entityId === undefined) {
      cb(new Array(0))
      return
    }

    return Promise.resolve(get('/RealEstate/PaymentMode/GetPaymentModes', {entityId})
    .done((result) => cb(result))
    .fail(() => {
      cb(new Array(0))
    }))
  },
  getCadastralDistrictsByLocalDistrict (localDistrictId, entityId, cb) {
    return Promise.resolve(get('/RealEstate/CadastralDistrict/CadastralDistrictsForLocalDistrict', {parentId: localDistrictId, entityId: entityId})
    .done((result) => {
      cb(result)
    })
    .fail(() => {
      cb(new Array(0))
    }))
  },
  getLandParcelNumbersByCadastralDistrict (cadastralDistrictId, entityId, cb) {
    return Promise.resolve(get('/RealEstate/LandParcel/GetLandParcelsInCadastralDistrict', {parentId: cadastralDistrictId, entityId: entityId})
    .done((result) => {
      cb(result)
    })
    .fail(() => {
      cb(new Array(0))
    }))
  },
  getSegmentsByLandParcelNumberId (cadastralDistrictId, landParcelNumberId, cb) {
    return Promise.resolve(get(`/RealEstate/Segments/SegmentsForCadastralDistrict?cadastralDistrictId=${cadastralDistrictId}&landParcelId=${landParcelNumberId}`)
    .done((result) => {
      cb(result)
    })
    .fail(() => {
      cb(new Array(0))
    }))
  },
  getDmsReferenceTypes (cb) {
    return Promise.resolve(get('/RealEstate/Lease/GetDmsReferenceTypes')
    .done((result) => cb(result))
    .fail(() => {
      cb(new Array(0))
    }))
  },
  getCurrencies (cb) {
    return Promise.resolve(get('/RealEstate/MasterDataCurrency/GetCurrencies')
    .done((result) => {
      result.forEach(x => {
        x.symbol = x.short
        delete x.short
      })
      cb(result)
    })
    .fail(() => {
      cb(new Array(0))
    }))
  },
  getCurrentTenant (orgUnitId, cb) {
    return Promise.resolve(get(`/RealEstate/Tenants/GetCurrentTenant?orgUnitId=${orgUnitId}`)
    .done((result) => cb(result))
    .fail(() => {
      cb(null)
    }))
  },
  checkCertificateNumberDublicate (certificateNumber) {
    return axios.get(`/RealEstate/Lease/CheckCertificateNumberDublicate?certificateNumber=${certificateNumber}`)
    .then((response) => response.data)
  }
}
