import eggForms from './egg-forms'
import status from './status'
import InvalidEnumValueException from './InvalidEnumValueException'
import axios from 'axios'
import {makeSetters} from '@helpers/vuex/mutations'

const state = {
  ui: {
    currentForm: eggForms.none
  },
  masterData: {
    packageTypes: []
  },
  data: {
    eggPackages: [{id: 0, packageType: 1, amount: 0}],
    remainingPackagedEggs: 0,
    useDetailedData: false,
    noneBreedableEggs: 0,
    smallEggs: 0,
    doubleYolk: 0,
    dirtyEggs: 0,
    thrownAway: 0,
    breedableEggsEntered: false,
    noneBreedableEggsEntered: false
  }
}

const getters = {
  statusNoneBreedableEggs (state) {
    if (state.data.noneBreedableEggsEntered) {
      return status.complete
    } else {
      return status.incomplete
    }
  },

  noneBreedableEggs (state) {
    return state.data.useDetailedData
      ? (state.data.smallEggs + state.data.doubleYolk + state.data.dirtyEggs + state.data.thrownAway)
      : state.data.noneBreedableEggs
  },

  statusBreedableEggs (state) {
    if (state.data.breedableEggsEntered) {
      return status.complete
    } else {
      return status.incomplete
    }
  },

  eggPackages (state) {
    return state.data.eggPackages
  },

  remainingPackagedEggs (state) {
    return state.data.remainingPackagedEggs
  },

  currentForm (state) {
    return state.ui.currentForm
  },

  totalBreedableEggs (state) {
    const findPackageType = (typeid) => state.masterData.packageTypes.find(packageType => packageType.id === typeid)
    const reducer = (acc, eggPackage) => {
      const packageType = findPackageType(eggPackage.packageType)
      const capacity = packageType !== undefined ? packageType.capacity : 0
      return acc + (eggPackage.amount * capacity)
    }
    const packedEggs = state.data.eggPackages.reduce(reducer, 0)
    return (Number.isNaN(packedEggs) ? 0 : packedEggs) + state.data.remainingPackagedEggs
  },

  totalNoneBreedableEggs (state, getters) {
    return getters.noneBreedableEggs
  },

  packageTypes (state) {
    return state.masterData.packageTypes
  }
}

const mutations = {
  ...makeSetters([
    'data.useDetailedData',
    'data.smallEggs',
    'data.doubleYolk',
    'data.dirtyEggs',
    'data.thrownAway'
  ]),

  setBreedableEggsEntered (state) {
    state.data.breedableEggsEntered = true
  },

  setNoneBreedableEggsEntered (state) {
    state.data.noneBreedableEggsEntered = true
  },

  showForm (state, form) {
    if (!eggForms.isValidValue(form)) {
      throw new InvalidEnumValueException(`${form} is not a valid value of eggForms!`)
    }

    state.ui.currentForm = form
  },

  addEggPackage (state) {
    const nextId = state.data.eggPackages.length === 0
      ? 0
      : Math.max(...state.data.eggPackages.map(p => p.id)) + 1

    state.data.eggPackages.push({id: nextId, packageType: 1, amount: 0})
  },

  updateEggPackageAmount (state, {eggPackageId, amount}) {
    const newAmount = Number.isNaN(amount) ? 0 : amount
    state.data.eggPackages[eggPackageId].amount = newAmount
  },

  updateEggPackageType (state, {eggPackageId, type}) {
    state.data.eggPackages[eggPackageId].packageType = type
  },

  setAvailablePackageTypes (state, packageTypes) {
    state.masterData.packageTypes = packageTypes
  },

  setRemainingPackagedEggs (state, amount) {
    state.data.remainingPackagedEggs = amount
  },

  setNoneBreedableEggs (state, value) {
    state.data.noneBreedableEggs = value
  },

  setState (state, newState) {
    state.data = newState
  },

  resetState (state) {
    state.data = {
      eggPackages: [{id: 0, packageType: 1, amount: 0}],
      remainingPackagedEggs: 0,
      useDetailedData: false,
      noneBreedableEggs: 0,
      smallEggs: 0,
      doubleYolk: 0,
      dirtyEggs: 0,
      thrownAway: 0,
      breedableEggsEntered: false,
      noneBreedableEggsEntered: false
    }
  }
}

const actions = {
  loadPackageTypes ({commit}) {
    axios.get('/api/poultrymanagement/farmer/packagetypes')
         .then(result => commit('setAvailablePackageTypes', result.data))
         .catch(() => {
           console.error("couldn't load package types")
         })
  },

  loadEggProduction ({dispatch, commit, rootGetters}) {
    const farm = rootGetters['poultryManagement/farmer/currentFarm']
    const poultryHouse = rootGetters['poultryManagement/farmer/currentPoultryHouse']
    const date = rootGetters['poultryManagement/farmer/selectedDate']
    dispatch('loadPackageTypes')
    axios.get('/api/poultrymanagement/farmer/eggProduction', {
      params: {
        farmId: farm.id,
        poultryHouseNumber: poultryHouse.poultryHouseNumber,
        flockNumber: poultryHouse.flocks[0],
        date: date
      }
    })
    .then((result) => {
      if (result.data) {
        commit('setState', result.data)
      } else {
        commit('resetState')
      }
    })
  },

  saveEggProduction ({dispatch, state, rootGetters}) {
    const farm = rootGetters['poultryManagement/farmer/currentFarm']
    const poultryHouse = rootGetters['poultryManagement/farmer/currentPoultryHouse']
    const date = rootGetters['poultryManagement/farmer/selectedDate']
    axios.post('/api/poultrymanagement/farmer/saveEggProduction', {
      ...state.data,
      farmId: farm.id,
      poultryHouseNumber: poultryHouse.poultryHouseNumber,
      flockNumber: poultryHouse.flocks[0],
      date: date
    })
    .then(() => {
      dispatch('poultryManagement/farmer/goToFarm', farm, {root: true})
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
