import './IxRes.scss'

import replaceTokens from '@helpers/replace-tokens'

const pathJoin = (a, b) => a && b ? `${a}.${b}` : a || b || ''

export default {
  props: {
    resource: {}, // NOTE deprecated
    namespace: String,
    placeholder: Boolean, // NOTE obsolete
    process: Function,
    context: Object
  },
  name: 'localized-string',
  functional: true,
  render (h, context) {
    const state = context.parent.$store.state.i18n

    const resourceProxy = context.props.resource
    const slotContent = context.slots().default[0].text.trim()

    const parentNamespace = context.parent.$options.translationNamespace
    const explicitNamespace = context.props.namespace
    const resourceNamespace = resourceProxy ? resourceProxy._resource : null
    const inherentNamespace = slotContent.search(/\./) >= 0
      ? slotContent.replace(/\.[^.]+$/, '')
      : ''

    const namespace = pathJoin(explicitNamespace || resourceNamespace || parentNamespace, inherentNamespace)

    const key = slotContent.replace(/.+\./, '')

    const fullKey = pathJoin(namespace, key)

    const classes = ['localized-text']
    const attrs = {}
    const on = {}

    if (context.parent.$store.state.debug.localization) {
      classes.push('highlight')
    }
    if (context.parent.$store.state.i18n.editMode && namespace) {
      classes.push('editable')
      on.click = event => {
        event.preventDefault()
        event.stopPropagation()

        context.parent.$store.dispatch('i18n/startEditing', {key: fullKey, context: context.props.context})
      }
    }

    if (!namespace || /\s/.test(key)) { // placeholder
      classes.push('placeholder')
      attrs.title = `PLACEHOLDER: ${key}`

      return h('span', {
        class: classes,
        attrs
      }, key)
    }

    let content
    if (!state.translations[namespace] || !state.translations[namespace][key]) {
      context.parent.$store.dispatch('i18n/signalMissingTranslation', {namespace, key})
      content = key
    } else {
      content = state.translations[namespace][key]
    }

    // const content = resourceProxy ? resourceProxy[key] : key
    const processingFunction = context.props.process

    switch (typeof content) {
    case 'string': {
      if (context.parent.$store.state.debug.localization) {
        attrs.title = fullKey
      }

      const text = processingFunction ? processingFunction(content) : content

      if (!context.props.context) return h('span', {class: classes, attrs, on}, text)

      const vnodes = replaceTokens(text, context.props.context, {
        email: value => h('a', {attrs: {href: `mailto:${value}`}}, value),
        link: value => h('a', {attrs: {href: `${value}`, 'data-ajax': false, target: '_blank'}}, value)
      })

      return h('span', {class: classes, attrs, on}, vnodes)
    }
    case 'object':
      classes.push(content.missing ? 'missing' : 'failed')
      attrs.title = `${content.toString()}: ${fullKey}`

      return h('span', {
        class: classes,
        attrs,
        on
      }, key)
    default:
      classes.push('loading')
      attrs.title = fullKey

      return h('span', {
        class: classes,
        attrs,
        on
      }, 'LOADING...')
    }
  }
}
