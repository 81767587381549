# CoffeeScript

class Plugin
    mapcontrol: null
    map: null
    init: ->

module.exports = {
    Plugin
}

module.exports.__esModule = true
module.exports.default = Plugin
