const mutations = {
  recievePaymentModes (state, paymentModes) {
    state.paymentModes = paymentModes
  },
  selectPaymentRecipient (state, paymentRecipient) {
    state.currentPaymentRecipient = paymentRecipient
  },

  updatePaymentRecipient (state, paymentRecipient) {
    state.editPaymentRecipient = paymentRecipient
  },

  selectPayments (state, payments) {
    state.selectedPayments = payments
  },

  openCreateOrEdit (state, paymentRecipient) {
    if (!paymentRecipient) {
      throw Error('No currement payment recipient')
    }
    state.editPaymentRecipient = paymentRecipient
    state.isEditModalOpen = true
  },

  closePaymentRecipientForm (state) {
    state.isEditModalOpen = false
    state.editPaymentRecipient = null
  },

  openDelete (state) {
    if (!state.currentPaymentRecipient) {
      throw new Error('No payment recipient selected')
    }

    state.isDeleteModalOpen = true
  },
  closeDelete (state) {
    state.isDeleteModalOpen = false
  },

  openCreateOrEditPayment (state, payment) {
    if (!payment) {
      throw Error('No currement payment')
    }

    state.editPayment = payment
    state.isEditPaymentModalOpen = true
  },
  closeCreateOrEditPayment (state) {
    state.isEditPaymentModalOpen = false
    state.editPayment = null
  },
  openPaymentDelete (state) {
    if (state.selectedPayments.length === 0) {
      throw new Error('No payment selected')
    }

    state.isDeletePaymentModalOpen = true
  },
  closePaymentDelete (state) {
    state.isDeletePaymentModalOpen = false
  }
}

export default mutations
