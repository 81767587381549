import mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'

const state = {
  banks: [],
  currentBankingAccount: null,
  editBankingAccount: null,
  isEditModalOpen: false,
  isDeleteModalOpen: false
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
