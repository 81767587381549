# CoffeeScript

require 'jquery-validation'

$.validator.addMethod 'shapefile', (value, input) ->
    return true if not input.required and input.files.length is 0
    return false if input.files.length and input.files.length < 3

    extensions = [0..input.files.length-1]
        .map (i) -> input.files.item(i)
        .map (file) -> file.name[file.name.lastIndexOf(".")..-1].toLowerCase()

    counts = {}
    for extension in extensions
        counts[extension] or= 0
        counts[extension]++

    for extension, count of counts
        return false if count > 1

    return counts['.shp'] is 1 and counts['.shx'] is 1 and counts['.dbf'] is 1

# TODO refactor
# ensures that at least this element or the elements given in otherElementIds has a value
$.validator.addMethod 'require-this-or-one-of', (value, element, otherElementIds) ->
    if value
        return true

    anotherElementHasValue = false
    for otherElementId in otherElementIds.split(',')
        otherValue = $('#' + otherElementId).val()
        unless otherValue is null or otherValue is ''
            anotherElementHasValue = true
    return anotherElementHasValue

$.validator.addMethod 'number', (value, element) ->
    return true if @optional element

    parser = Globalize.numberParser()

    return not isNaN parser value

$.validator.addMethod 'strict-number', (value, element) ->
    return true if @optional element

    parser = Globalize.numberParser()

    {decimal, group} = Globalize.locale().main('numbers')['symbols-numberSystem-latn']

    isNumber = not isNaN parser value
    onlyNumericsAndSeparators = new RegExp("^[0-9#{decimal}#{group}]*$").test(value)

    return isNumber and onlyNumericsAndSeparators

$.validator.addMethod 'range', (value, element, params) ->
    return true if @optional element

    parser = Globalize.numberParser()
    value = parser value
    return value >= params[0] and value <= params[1]

$.validator.addMethod 'min', (value, element, param) ->
    return true if @optional element

    parser = Globalize.numberParser()
    value = parser value
    return value >= param

$.validator.addMethod 'greater-than', (value, element, param) ->
    return true if @optional element

    parser = Globalize.numberParser()
    value = parser value
    return value > param

$.validator.addMethod 'max', (value, element, param) ->
    return true if @optional element

    parser = Globalize.numberParser()
    value = parser value
    return value <= param

$.validator.addMethod 'regex', (value, element, param) ->
    return true if @optional element
    regex = new RegExp(param).test(value)
    return regex

getFeedbackIcon = ($element) ->
    $feedback = $element.closest('.form-group, .DTE_Field').find('.form-control-feedback')
    if not $feedback.length
        $feedback = $ '<span class="glyphicon form-control-feedback"></span>'
        if $element.hasClass 'selectized'
            $feedback.insertAfter $element.next('.selectize-control').find('.selectize-input > input')
        else
            $feedback.insertAfter $element
    return $feedback


$.validator.setDefaults
    ignore: '[readonly], :hidden:not([class~=selectized]), :hidden > .selectized, .selectize-control .selectize-input input, table.dataTable input, .ignore-validation, button'
    highlight: (element) ->
        $element = $ element
        $element.closest('.form-group')
        .addClass 'has-error'
        .removeClass 'has-success'
        .attr 'aria-invalid', true
        unless $element.closest('.input-group').length or $element.is('select:not(.selectized)') or $element.hasClass('no-validation-icon')
            $element.closest('.form-group')
            .addClass 'has-feedback'
            getFeedbackIcon $element
            .addClass 'glyphicon-remove'
            .removeClass 'glyphicon-ok'
    unhighlight: (element) ->
        $element = $ element
        $element.closest('.form-group')
        .addClass 'has-success'
        .removeClass 'has-error'
        .removeAttr 'aria-invalid'
        unless $element.closest('.input-group').length or $element.is('select:not(.selectized)') or $element.hasClass('no-validation-icon')
            $element.closest('.form-group')
            .addClass 'has-feedback'
            getFeedbackIcon $element
            .addClass 'glyphicon-ok'
            .removeClass 'glyphicon-remove'
    errorElement: 'span'
    errorClass: 'help-block'
    errorPlacement: ($error, $element) ->
        if $element.closest('.input-group').length
            $error.insertAfter $element.closest('.input-group').parent().children().last()
        else if $element.prop('type') is 'checkbox'
            $error.appendTo $element.closest '.checkbox'
        else if $element.prop('type') is 'radio'
            $error.insertAfter $element.closest '.radio'
        else
            $error.appendTo $element.parent()


$.validator.addMethod 'vin', (value, element) ->
    return true if @optional element

    isVin = new RegExp("^[A-HJ-NPR-Z0-9]{17}$").test(value)
    return value.length == 17 and isVin

# $.validator.addMethod 'required', (value, element) ->
#     if element.checked isnt undefined
#         return element.checked
#     else if value.length isnt undefined
#         return value.length > 0
#     else
#         return value
