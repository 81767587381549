
import {notifications} from 'src/js/infrastructure'
import {postJson} from 'src/coffee/infrastructure/ajax'

export default {
  saveContact (contact) {
    return new Promise((resolve, reject) => {
      postJson('/RealEstate/Contacts/SaveContact', contact)
      .done((result) => {
        resolve(result)
      })
     .fail((jqXhrm) => {
       let response = null

       if (jqXhrm.responseText) {
         response = JSON.parse(jqXhrm.responseText)
       }

       if (response && response.errors && response.errors.length > 0) {
         notifications.error(response.errors)
         reject(response.errors)
       } else {
         notifications.error(jqXhrm.statusText)
         reject(jqXhrm.statusText)
       }
     })
    })
  }
}
