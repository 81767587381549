export const orgUnits = (state) => {
  return state.orgUnits
}

export const selectedOrgUnit = (state) => {
  return state.selectedOrgUnit
}

export const hasCurrentOrgUnit = (state) => {
  return !!state.selectedOrgUnit
}
