const mutations = {
  saveChangesRequest (state) {
    state.isSaveChangesModalOpen = true
  },
  saveChangesCancel (state) {
    state.isSaveChangesModalOpen = false
  },
  saveChangesFailure (state) {
    state.isSaveChangesModalOpen = false
  },
  saveChangesSuccess (state) {
    state.isSaveChangesModalOpen = false
  }

}

export default mutations
