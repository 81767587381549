
{waitscreen} = require 'src/js/infrastructure'
{get} = require 'src/coffee/infrastructure/ajax'
page = null

getModal = (url, data, method = get, displayOptions = {}) ->
    deferred = $.Deferred()
    promise = deferred.promise()

    $modals = null

    # by default set focus on first input element
    displayOptions.setFocusOnFirstInput ?= true

    method url, data
    .then (response) ->
        $fragment = $(response)
        $modals = $fragment.filter('.modal').add $fragment.find('.modal')
        $modals.addClass 'dynamic'
        clearDynamicModals()
        page.setupFragment $fragment, {promise}
    .then ($fragment) ->
        $modals.first().modal {keyboard: true}
        $modals.first().modal 'show'
        $modals.first().on 'shown.bs.modal', ->
            deferred.resolve $modals.first(), $modals
            visibleInputs = $modals.first().find('input').not(':hidden') #find all visible input elements
            if visibleInputs.length > 0
                if displayOptions.setFocusOnFirstInput
                    visibleInputs.first().focus()
    .fail (reason) -> deferred.reject reason

    waitscreen.waitFor promise

    return promise

# TODO remove in favor of getModal
getDialog = (shouldClearDynamicModals = true) ->
    waitscreen.show()
    return (response) ->
        deferred = $.Deferred()
        $fragment = $(response)
        $modal = $fragment.filter('.modal').add $fragment.find('.modal')
        $modal.addClass 'dynamic'
        clearDynamicModals() if shouldClearDynamicModals

        page.setupFragment $fragment, deferred.promise()
        .then waitscreen.hide
        .then ->
            $modal.modal 'show'
        .then deferred.resolve

clearDynamicModals = ->
    $('#modals .modal.dynamic').remove()
    $('.modal-backdrop.fade.in').remove()

clearModals = ->
    $('#modals').empty()
    $('.modal-backdrop.fade.in').remove()

setPageInstance = (instance) -> page = instance

module.exports = {
    getModal,
    getDialog,
    clearModals,
    clearDynamicModals,
    setPageInstance
}
