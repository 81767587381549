export const banks = (state) => {
  return state.banks ? state.banks : []
}

export const hasCurrentBankingAccount = (state) => {
  return !!state.currentBankingAccount
}

export const isEditModalOpen = (state) => {
  return state.isEditModalOpen
}

export const currentBankingAccount = (state) => {
  return state.currentBankingAccount ? state.currentBankingAccount : {}
}

export const editBankingAccount = (state) => {
  return state.editBankingAccount ? state.editBankingAccount : {bank: null}
}
export const isDeleteModalOpen = (state) => {
  return state.isDeleteModalOpen
}
