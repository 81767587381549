export const splitPropertyPath = propertyPath => {
  const parts = splitPropertyPathSegments(propertyPath)
  return [parts.slice(0, -1), parts[parts.length - 1]]
}

export const splitModulePathSegments = modulePath => modulePath.split('/')
export const splitPropertyPathSegments = propertyPath => propertyPath.split('.')

export const followPathReducer = (object, pathSegment) => object[pathSegment]
export const followPath = (object, pathSegments) => pathSegments.reduce(followPathReducer, object)

export const defaultSetterName = propertyName => `set${propertyName.substring(0, 1).toUpperCase()}${propertyName.substring(1)}`
