import mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'

const state = {
  currentTerm: null,
  editTerm: null,
  isEditTermModalOpen: false,
  isDeleteTermModalOpen: false
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
