import commandQueue from '../api/commandQueue'

export const saveChangesRequest = ({commit}) => {
  commit('saveChangesRequest')
}

export const saveChangesCancel = ({commit}) => {
  commit('saveChangesCancel')
}

export const saveChangesFailure = ({commit}) => {
  commit('saveChangesFailure')
}

export const saveChangesSuccess = ({commit, rootState}) => {
  const currentContact = rootState.realEstateMasterDataContacts.contacts.currentContact
    ? rootState.realEstateMasterDataContacts.contacts.currentContact
    : rootState.realEstateMasterDataContacts.contacts.newContact

  if (!currentContact) {
    throw new Error('No contact for saving selected')
  }

  return commandQueue.saveContact(currentContact)
    .then(() => commit('saveChangesSuccess'))
}
