import likelySubtags from '@wwwroot/cldr/supplemental/likelySubtags.json'
import numberingSystems from '@wwwroot/cldr/supplemental/numberingSystems.json'

Globalize.load(likelySubtags)
Globalize.load(numberingSystems)

export default async function loadCldrData (language) {
  const module = await import(`@wwwroot/cldr/main/${language}/numbers.json`)
  Globalize.load(module.default)
}
