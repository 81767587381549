import mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'

const state = {
  currentContract: null,
  newContract: null,
  isCreateLeaseContract: false,
  isEditLeaseContract: false,
  isEditChanged: false,
  permissions: null,
  status: {
    common: 'none',
    areas: 'none',
    finances: 'none',
    terms: 'none',
    amendments: 'none'
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
