
const mutations = {
  recieveContactTypes (state, contactTypes) {
    state.contactTypes = contactTypes
  },
  recieveCountries (state, countries) {
    state.countries = countries
  },
  recieveCountriesWithRegions (state, countriesWithRegions) {
    state.countriesWithRegions = countriesWithRegions
  },
  updateCurrentSelectedCountryUID (state, currentSelectedCountryUID) {
    state.currentSelectedCountryUID = currentSelectedCountryUID
  }
}

export default mutations
