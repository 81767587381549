import {flatten} from 'lodash'

export function permissions (state) {
  return state.permissions
}

export function selectedFarm (state) {
  const farmId = state.ui.selection.farmId

  return farmId ? state.data.farms.find(farm => farm.id === farmId) : null
}

export function isInAdminComponent (state) {
  return state.ui.navigation.isInAdminComponent || null
}

export function selectedPoultryHouse (state) {
  const poultryHouseId = state.ui.selection.poultryHouse.id

  return poultryHouseId ? state.data.poultryHouses.find(house => house.id === poultryHouseId) : null
}

export function selectedPoultryHouseVisit (state) {
  const poultryHouse = state.ui.selection.poultryHouse

  return state.data.poultryHouseVisits.find(v => v.poultryHouseId === poultryHouse.id && v.visit === poultryHouse.visit)
}

export function getFlock (state) {
  return (poultryHouseId) => {
    if (!poultryHouseId) {
      poultryHouseId = state.ui.selection.poultryHouse.id
    }

    const poultryHouse = state.data.poultryHouses.find(house => house.id === poultryHouseId)

    if (!state.data.flocks) {
      return null
    }

    return state.data.flocks.find(flock => flock.id === poultryHouse.flockId) || {name: ''}
  }
}

export function getFarmLivestock (state) {
  return (farmId) => {
    farmId = !farmId ? state.ui.selection.poultryHouse.id : farmId

    let male = 0
    let female = 0

    if (!state.data.livestock.farms) {
      return {male: 0, female: 0}
    }

    state.data.livestock.farms.find(farm => farm.id === farmId).poultryHouses.forEach(house => {
      male += house.livestock.male
      female += house.livestock.female
    })

    return {male, female}
  }
}

export function getPoultryHouseLivestock (state) {
  return (poultryHouseId) => {
    poultryHouseId = !poultryHouseId ? state.ui.selection.poultryHouse.id : poultryHouseId

    if (!state.data.livestock.farms) {
      return {male: 0, female: 0}
    }

    return flatten(state.data.livestock.farms.map(farm => farm.poultryHouses))
      .find(house => house.id === poultryHouseId)
      .livestock
  }
}

export function getFarmVisitStatus (state) {
  return (farmId) => {
    farmId = !farmId ? state.ui.selection.farmId : farmId

    const all = state.data.poultryHouses
      .filter(house => house.farmId === farmId)
      .map((house) => getPoultryHouseVisitStatusInternal(state, house.id))

    if (all.every(status => status === 0)) {
      return convertStatus(0)
    }

    if (all.every(status => status === 2)) {
      return convertStatus(2)
    }

    return convertStatus(1)
  }
}

export function getPoultryHouseVisitStatus (state) {
  return (poultryHouseId) => {
    poultryHouseId = !poultryHouseId ? state.ui.selection.poultryHouse.id : poultryHouseId

    return convertStatus(getPoultryHouseVisitStatusInternal(state, poultryHouseId))
  }
}

function convertStatus (status) {
  switch (status) {
  case 0:
  default:
    return 'not-started'
  case 1:
    return 'in-progress'
  case 2:
    return 'finished'
  }
}

function getPoultryHouseVisitStatusInternal (state, poultryHouseId) {
  const status1 = getSinglePoultryHouseVisitStatusInternal(state, poultryHouseId, 1)
  const status2 = getSinglePoultryHouseVisitStatusInternal(state, poultryHouseId, 2)

  const minStatus = Math.min(status1, status2)
  const maxStatus = Math.max(status1, status2)

  if (maxStatus === 0) {
    return 0
  }
  if (minStatus === 2) {
    return 2
  }

  return 1
}

function getSinglePoultryHouseVisitStatusInternal (state, poultryHouseId, visitNumber) {
  // todo: rename visit to visitNumber or number...
  const visit = state.data.poultryHouseVisits.find(visit => visit.poultryHouseId === poultryHouseId && visit.visit === visitNumber)

  if (!visit) {
    return 0
  }

  const statusProps = [
    'livestockRecord',
    'eggRecord',
    'foodConsumptionRecord',
    'animalWeightRecord'
  ]

  const foundProps = statusProps.filter(prop => !!visit[prop])

  if (foundProps.length <= 0) {
    return 0
  } else if (foundProps.length !== statusProps.length) {
    return 1
  }

  return 2
}

export function getEggMaterial (state) {
  return (eggType) => {
    if (!eggType) {
      eggType = 'BroodEgg' // alternatively ConsumerEgg
    }

    return eggMaterials(state).find(m => m.type === eggType)
  }
}

export function eggMaterials (state) {
  return state.data.logistics.materials.filter(m => m.isSystemType && (m.type === 'BroodEgg' || m.type === 'ConsumerEgg'))
}

export function materials (state) {
  return state.data.logistics.materials
}

export function feeds (state) {
  return state.data.feeds
}

export function packagingMaterials (state) {
  return state.data.logistics.materials.filter(mat => mat.materialId)
}

export function getMatchingPackagingMaterials (state) {
  return (eggType) => {
    const allPackagingMaterials = packagingMaterials(state)

    let baseMaterial = getEggMaterial(state)(eggType)

    const matchingPackagingMaterials = []

    findMatchingPackagingMaterials(allPackagingMaterials, baseMaterial, matchingPackagingMaterials)

    return matchingPackagingMaterials
  }
}

function findMatchingPackagingMaterials (allPackagingMaterials, baseMaterial, matchingPackagingMaterials) {
  allPackagingMaterials.forEach(currentMaterial => {
    if (currentMaterial.materialId === baseMaterial.id) {
      if (!matchingPackagingMaterials.find(m => m.id === currentMaterial.id)) {
        matchingPackagingMaterials.push(currentMaterial)
      }

      findMatchingPackagingMaterials(allPackagingMaterials, currentMaterial, matchingPackagingMaterials)
    }
  })
}
