import queryData from './api/queryData'

export const initRequest = ({commit, dispatch}, target) => {
  queryData.getTreeNodes((treeNodes) => {
    commit('navigation/recieveOrgUnits', treeNodes)
  })
  .then(() => {
    if (target && target.orgUnitId && target.contractId) {
      dispatch('realEstateContractLease/navigation/selectOrgUnit', {uid: target.orgUnitId}, {root: true})
      .then(() => {
        dispatch('realEstateContractLease/contracts/selectContract', target.contractId, {root: true})
      })
    }
  })

  queryData.getCurrencies((currencies) => {
    commit('recieveCurrencies', currencies)
  })

  queryData.getDmsReferenceTypes((dmsReferenceTypes) => {
    commit('dmsReferences/recieveDmsReferenceTypes', dmsReferenceTypes)
  })
}
