
{waitscreen} = require 'src/js/infrastructure'

page = null

makeAppendHandler = ($target = $('#default-ajax-target')) ->
    waitscreen.show()
    return (response) ->
        deferred = $.Deferred()
        page.setupFragment $(response), deferred.promise()
        .then ($fragment) -> $target.append($fragment)
        .then waitscreen.hide
        .then deferred.resolve

makeReplaceHandler = ($target = $('#default-ajax-target')) ->
    waitscreen.show()
    return (response) ->
        deferred = $.Deferred()
        page.setupFragment $(response), {promise: deferred.promise()}
        .then ($fragment) -> $target.empty().append($fragment)
        .then waitscreen.hide
        .then deferred.resolve

makeRefreshHandler = -> makeReplaceHandler()

setPageInstance = (instance) -> page = instance

module.exports = {
    makeAppendHandler,
    makeReplaceHandler,
    makeRefreshHandler,
    setPageInstance
}
