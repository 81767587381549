# CoffeeScript

toastr = require 'toastr'

defaultOptions =
    showDuration: 500
    hideDuration: 600
    timeOut: 3000
    positionClass: 'toast-bottom-right'
    progressBar: true

persistentOptions = Object.assign {}, defaultOptions, {timeOut: 30000}

info = (text, title) ->
    toastr.info text, title, defaultOptions

success = (text, title, callBackFunc) ->
    options = Object.assign {}, defaultOptions
    if callBackFunc
      options.closeButton = true
      options.progressBar = false
      options.timeOut = 0
      options.onclick = callBackFunc

    toastr.success text, title, options

persistentSuccess = (text, title) ->
    options = Object.assign {}, persistentOptions
    toastr.success text, title, options

warning = (text, title) ->
    toastr.warning text, title, defaultOptions

error = (text, title) ->
    toastr.error text, title, defaultOptions

criticalError = (text, title) ->
    options = Object.assign {}, defaultOptions
    options.timeOut = 0
    options.extendedTimeOut = 0
    options.progressBar = false
    toastr.error text, title, options


module.exports = {
    info,
    success,
    persistentSuccess,
    warning,
    error,
    criticalError,
    defaultOptions
}
