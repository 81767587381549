{config} = require 'src/js/infrastructure'

mapservers =
    local:
        url: 'http://127.0.0.1:9090/cgi-bin/mapserv.exe?map=C:\\ms4w\\apps\\'
        appName: 'geodata\\service\\geodata.map'
        firstOptionSeparator: '&'
    remote:
        url: 'https://geoserver.ixmap.eu/mapserver/cgi-bin/'
        appName: config.remoteMapserver
        firstOptionSeparator: '?'
    geoserver:
        baseUrl: config.remoteGeoserver
        url: config.remoteGeoserver + '/geoserver/ixmap/wms'
    alternate:
        url: 'https://geoserver.ixmap.eu/mapserver/cgi-bin/'
        appName: 'geodata'
        firstOptionSeparator: '?'

module.exports = mapservers

module.exports.__esModule = true
module.exports.default = mapservers
