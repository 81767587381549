import {cloneDeep} from 'lodash'
import newGuid from 'src/js/helpers/new-guid'

export const selectDmsReference = ({commit}, dmsReference) => {
  commit('selectDmsReference', dmsReference)
}

export const createDmsReferenceRequest = ({commit}) => {
  let newNewDmsReference = {
    uid: newGuid(),
    type: null,
    name: '',
    reference: ''
  }

  commit('openCreateOrEdit', newNewDmsReference)
}

export const editDmsReferenceRequest = ({commit, state}) => {
  if (!state.currentDmsReference) {
    throw new Error('No dms reference selected')
  }

  let dmsReference = cloneDeep(state.currentDmsReference)

  commit('openCreateOrEdit', dmsReference)
}

export const createOrEditDmsReferenceSuccess = ({commit, state}) => {
  if (!state.editDmsReference) {
    throw new Error('No dms reference selected')
  }

  commit('realEstateContractLease/contracts/addOrUpdateDmsReferenceToContract', state.editDmsReference, {root: true})
  commit('selectDmsReference', state.editDmsReference)
  commit('closeCreateOrEdit')
  commit('realEstateContractLease/contracts/setCommonChanged', null, {root: true})
}

export const createOrEditDmsReferenceFailure = ({commit}) => {
  commit('closeCreateOrEdit')
}

export const deleteDmsReferenceRequest = ({commit, rootState}) => {
  const currentContract = rootState.realEstateContractLease.contracts.currentContract
    ? rootState.realEstateContractLease.contracts.currentContract
    : rootState.realEstateContractLease.contracts.newContract

  if (!currentContract) {
    throw new Error('No contract selected')
  }

  commit('openDeleteDmsReference')
}

export const deleteDmsReferenceSuccess = ({commit, state}) => {
  if (!state.currentDmsReference) {
    throw new Error('No dms reference selected')
  }

  commit('realEstateContractLease/contracts/removeDmsReferenceFromContract', state.currentDmsReference, {root: true})
  commit('closeDeleteDmsReference')
  commit('realEstateContractLease/contracts/setCommonChanged', null, {root: true})
}

export const deleteDmsReferenceFailure = ({commit}) => {
  commit('closeDeleteDmsReference')
}
