# CoffeeScript

convertChangesToGridStateSaver = (changes) ->
    ###
    use only for legacy compatibility
    ###
    return [
        {state: 'Added', rows: changes.added}
        {state: 'Updated', rows: changes.modified}
        {state: 'Deleted', rows: changes.deleted}
    ]

fillUrlPlaceholders = (url, rowData) ->
    for name, value of rowData
        url = url.replace "{#{name}}", value
        url = url.replace encodeURIComponent("{#{name}}"), value
    return url

compareRows = (data1, data2, compareData = false) ->
    if compareData
        if Object.keys(data1).length isnt Object.keys(data2).length
            return false

        for key, value of data1
            continue if key is 'DT_RowId'

            if data2[key] isnt value
                return false

        return true

    return data1['DT_RowId'] is data2['DT_RowId']

rowIntersection = (rows1, rows2, compareData = false) ->
    intersection = []
    for data1 in rows1
        alreadyExists = false
        for data2 in rows2
            if compareRows data1, data2, compareData
                alreadyExists = true
                break
        intersection.push data1 unless alreadyExists
    return intersection

copySelectionToOtherGrid = (grid1, grid2, compareData = false) ->
    if typeof grid1 is 'string'
        grid1 = $(grid1).staticgrid()[0]

    if typeof grid2 is 'string'
        grid2 = $(grid2).staticgrid()[0]

    rowsToCopy = grid1.table.rows('.selected').data().toArray()
    existingRows = grid2.table.rows().data().toArray()

    newRows = rowIntersection rowsToCopy, existingRows, compareData

    # grid2.table.rows.add(newRows).draw()
    for row in newRows
        grid2.state.addRow row

    grid2.table.draw()

module.exports = {
    convertChangesToGridStateSaver,
    fillUrlPlaceholders,
    compareRows,
    rowIntersection,
    copySelectionToOtherGrid
}
