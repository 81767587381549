import {newTranslatableString} from './helpers'

const defaultCropBuffer = () => ({
  id: null,
  name: newTranslatableString(),
  tags: [],
  isLeguminous: false,
  nFix: null,
  averageWeight: null
})

const defaultUsageBuffer = parentId => ({
  id: null,
  cropId: parentId,
  name: newTranslatableString(),
  abbreviation: newTranslatableString(),
  yieldLevel: null,
  nitrogenRequirement: null,
  drySubstrateAmount: null,
  subsidyCode: null
})

const defaultQualityBuffer = parentId => ({
  id: null,
  cropUsageId: parentId,
  name: newTranslatableString(),
  crudeProteinContent: null,
  mainproductShare: null,
  byproductShare: null,
  mainproductNitrogenRemoval: null,
  mainproductPhosphorusRemoval: null,
  mainproductPotassiumRemoval: null,
  mainproductMagnesiumRemoval: null,
  byproductNitrogenRemoval: null,
  byproductPhosphorusRemoval: null,
  byproductPotassiumRemoval: null,
  byproductMagnesiumRemoval: null
})

export default (level, parentId) => {
  switch (level) {
  case 'crop': return defaultCropBuffer()
  case 'usage': return defaultUsageBuffer(parentId)
  case 'quality': return defaultQualityBuffer(parentId)
  default: throw new Error(`invalid level: ${level}`)
  }
}
