import supportedLocales from '@store/modules/i18n/supported-locales.json'

const translationReducer = (obj, locale) => Object.assign(obj, {[locale]: ''})
export const newTranslatableString = () => supportedLocales.reduce(translationReducer, {})

export const levels = [
  null,
  'crop',
  'usage',
  'quality'
]

export const nutrientRemovalProperties = [
  'mainproductNitrogenRemoval',
  'mainproductPhosphorusRemoval',
  'mainproductPotassiumRemoval',
  'mainproductMagnesiumRemoval',
  'byproductNitrogenRemoval',
  'byproductPhosphorusRemoval',
  'byproductPotassiumRemoval',
  'byproductMagnesiumRemoval'
]

export const productShareProperties = [
  'mainproductShare',
  'byproductShare'
]

export const determineLevel = dto => !dto
  ? null
  : dto.cropUsageId
    ? 'quality'
    : dto.cropId
      ? 'usage'
      : 'crop'

export const determineParentId = dto => !dto ? null : dto.cropUsageId || dto.cropId || null
