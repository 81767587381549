import queryData from './api/queryData'

export const initRequest = ({commit}) => {
  queryData.getTreeNodes((treeNodes) => {
    commit('navigation/recieveOrgUnits', treeNodes)
  })
}

export const getPayments = ({commit}, {orgUnit, start, end, onlyUnpaid}) => {
  queryData.getPayments({orgUnitId: orgUnit.id, start, end, onlyUnpaid}, (payments) => {
    commit('realEstateContractLeasePayments/recievePayments', payments, {root: true})
  })
}
