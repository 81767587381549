# CoffeeScript

{HelloWorldPlugin} = require 'src/coffee/controls/map/webgis/plugins/hello-world'
mapservers = require 'src/coffee/controls/map/servers'

class DebugOverlay
    cloakingEnabled: true
    expanded: false

    constructor: (@page) ->
        @$element = $ '<div>'
        .addClass 'debug-overlay'

        @expandDiv = $ '<div>'
        .addClass 'expand'
        .appendTo @$element

        @expandButton = $ '<button>'
        .on 'click', =>
            if @expanded
                @$content.detach()
            else
                @$element.append @$content
            @expanded = not @expanded
        .appendTo @expandDiv

        $ '<i>'
        .addClass 'fa fa-wrench'
        .appendTo @expandButton

        @$content = $ '<div>'
        .addClass 'content'

        @sections = {}
        @_addSection ''

        @errors = []
        @errorButton = @addButton 'No Errors', @_errorButtonClicked
        @_updateErrorButton()

        @addButton 'Wait', ->
            @page.get '/Home/Wait'

        @addButton 'Exception', ->
            @page.get '/Home/Error'

        @enableCloaking() if @cloakingEnabled

        remoteMapserver = mapservers.remote

        @addButtonToSection 'Map', 'server: ' + remoteMapserver.appName, ->
            if remoteMapserver.appName is 'geodata'
                $(this).text 'server: ' + (remoteMapserver.appName = 'geodata_test')
            else
                $(this).text 'server: ' + (remoteMapserver.appName = 'geodata')

        @addButtonToSection 'Map', 'Add Location Picker', ->
            for map in $('.mapcontrol').mapcontrol()
                map.webgis?.registerPlugin new HelloWorldPlugin 'debugOverlay'

        @addButtonToSection 'Layout', 'Toggle Breadcrumbs', ->
            if $('.navbar-breadcrumbs').hasClass('collapsed') then @page.showBreadcrumbs() @page.hideBreadcrumbs()

        @addButtonToSection 'Layout', 'Toggle Waitscreen', ->
            $('.waitscreen').toggleClass('inactive')
            $('.waitscreen').toggleClass('waitscreen-fade')

        $(document).ajaxError (event, jqXHR, settings, thrownError) =>
            @addError(jqXHR.responseText, settings.url)
            return true

        @$element.appendTo $ 'body'

    _errorButtonClicked: =>
        console.log @errors
        for error in @errors
            w = window.open()
            w.document.write error.message
            w.history.pushState {}, null, error.url
        @errors = []
        @_updateErrorButton()

    _updateErrorButton: ->
        x = @errors.length
        if x
            @errorButton
            .text "#{@errors.length} Errors"
            .addClass 'error'
            @errorButton.prop('disabled', false)
            @expandButton.addClass 'error'
        else
            @errorButton
            .text 'No Errors'
            .removeClass 'error'
            @errorButton.prop('disabled', true)
            @expandButton.removeClass 'error'

    _addSection: (name) ->
        div = $ '<div>'
        .addClass 'section-container'
        .appendTo @$content

        $ '<span>'
        .addClass 'title'
        .text name
        .appendTo div

        @sections[name] = $ '<div>'
        .addClass 'content-section'
        .appendTo div

    addError: (message, url) ->
        @errors.push {message: message, url: url}
        @_updateErrorButton()

    addButton: (text, callback) -> @addButtonToSection '', text, callback

    addButtonToSection: (section, text, callback) ->
        unless section of @sections
            @_addSection section

        button = $ '<button>'
        .on 'click', callback
        .appendTo @sections[section]

        if text.startsWith 'fa '
            $ '<i>'
            .addClass text
            .appendTo button
        else
            button.text text
        return button

    enableCloaking: ->
        @$element.addClass 'hidden'

        $(document).on 'keyup', (event) =>
            if event.keyCode is 120
                @$element.toggleClass 'hidden'
                return false
            return true

module.exports = {
    DebugOverlay
}

module.exports.__esModule = true
module.exports.default = DebugOverlay
