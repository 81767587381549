# CoffeeScript

{Plugin, Action, ModalAction} = require './base'

WKT = require('ol/format/WKT').default

class HelloWorldPlugin extends Plugin
    constructor: (@from) ->
        @actions = [
            new Action 'Hello World!',
                icon: 'fa fa-globe'
                context: 'global'
                onExecute: => alert "Hello World ! ~ from #{@from}"
            new ModalAction 'Hello World from Map!',
                icon: 'fa fa-map-marker'
                onMapClick: (coordinate) ->
                    lonLat = ol.proj.transform coordinate, 'EPSG:900913', 'EPSG:4326'
                    alert "Hello World ! ~ from x=#{coordinate[0]}, y=#{coordinate[1]}\nlon=#{lonLat[0]}, lat=#{lonLat[1]}"
                    return true
                onFeatureClick: (feature, layer) ->
                    format = new WKT
                    opt =
                        dataProjection: 'EPSG:4326'
                        featureProjection: 'EPSG:900913'
                    wkt = format.writeFeature feature, opt
                    alert "Hello World ! ~ from feature '#{feature.get 'name'}' on layer '#{layer.get 'name'}' (#{wkt})"
                    return true
        ]

module.exports = {
    HelloWorldPlugin
}

module.exports.__esModule = true
module.exports.default = HelloWorldPlugin
