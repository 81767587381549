import axios from 'axios'

const requestResources = requestedTranslations => Promise.all(Object.keys(requestedTranslations).map(lang => {
  const url = `/api/Translation/${lang}`
  const prefixes = Object.keys(requestedTranslations[lang])
  return axios.post(url, prefixes)
    .then(response => response.data)
}))
.then(responses => responses.reduce((combined, response) => Object.assign(combined, response), {}))

export {
  requestResources
}
