import Vue from 'vue'
import newGuid from 'src/js/helpers/new-guid'
import moment from 'moment'

const mutations = {
  setCurrentPermissions (state, permissions) {
    state.permissions = permissions
  },
  selectContract (state, contract) {
    state.currentContract = contract
    state.isEditChanged = false
  },
  createNewLeaseContractRequest (state, orgUnitId) {
    state.currentContract = null

    state.newContract = {
      orgUnitId: orgUnitId,
      isRentOut: false,
      certificateNumber: '',
      contractDate: moment().toISOString(),
      leaseObject: null,
      comments: '',
      paymentRecipients: [],
      payments: [],
      dmsReferences: [],
      amendments: [
        {
          uid: newGuid(),
          isStart: true,
          validOn: moment().toISOString(),
          lessor: [null],
          lessorList: '',
          leaser: [null],
          leaserList: '',
          isExchangeAllowed: false,
          isWaterPaidByLeaser: false,
          isTaxPaidByLeaser: false,
          areas: []
        }
      ],
      terms: [
        {
          uid: newGuid(),
          start: moment().toISOString(),
          end: moment().add(1, 'year').toISOString(),
          leasePrice: 0,
          currency: {
            uid: '346c8459-bfb3-4054-ad9c-e08cd17792fe',
            symbol: '€'
          },
          remark: ''
        }
      ],
      autoRenew: false,
      autoRenewalPeriod: 0,
      cancellationPeriod: 0
    }

    state.isCreateLeaseContract = true
    state.isEditChanged = false
  },

  createNewLeaseContractSuccess (state, contractId) {
    if (!state.newContract) {
      throw new Error('No new contract for processing')
    }

    state.newContract.uid = contractId

    // state.currentContract = state.newContract
    Vue.set(state, 'currentContract', state.newContract)
    state.isCreateLeaseContract = false

    state.newContract = null
    state.isEditChanged = false
  },
  createNewLeaseContractFailure (state) {
    if (!state.newContract) {
      throw new Error('No new contract for processing')
    }

    state.isCreateLeaseContract = false

    state.newContract = null
    state.isEditChanged = false
  },

  editLeaseContractRequest (state) {
    if (!state.currentContract) {
      throw new Error('No contract selected')
    }

    // Vue.set(state, 'editContract', cloneDeep(state.currentContract))
    state.isEditLeaseContract = true
    state.isEditChanged = false
  },

  editLeaseContractSuccess (state) {
    if (!state.currentContract) {
      throw new Error('No contract selected')
    }

    state.isEditLeaseContract = false
    state.isEditChanged = false
  },
  editLeaseContractFailure (state) {
    state.isEditLeaseContract = false

    state.currentContract = null
    state.isEditChanged = false
  },
  removeCurrentContract (state) {
    Vue.delete(state.currentContract, 'contract')
  },
  addOrUpdateDmsReferenceToContract (state, dmsReference) {
    const currentContract = state.currentContract ? state.currentContract : state.newContract

    if (!currentContract) {
      throw new Error('No contract selected')
    }

    const index = currentContract.dmsReferences.findIndex(x => x.uid === dmsReference.uid)

    if (index < 0) {
      currentContract.dmsReferences.push(dmsReference)
    } else {
      Vue.set(currentContract.dmsReferences, index, dmsReference)
    }

    state.isEditChanged = true
  },
  removeDmsReferenceFromContract (state, dmsReference) {
    const currentContract = state.currentContract ? state.currentContract : state.newContract

    if (!currentContract) {
      throw new Error('No contract selected')
    }

    const index = currentContract.dmsReferences.findIndex(x => x.uid === dmsReference.uid)
    currentContract.dmsReferences.splice(index, 1)

    state.isEditChanged = true
  },
  addOrUpdateAmendmentToContract (state, amendment) {
    const currentContract = state.currentContract ? state.currentContract : state.newContract

    if (!currentContract) {
      throw new Error('No contract selected')
    }

    const index = currentContract.amendments.findIndex(x => x.uid === amendment.uid)

    if (index < 0) {
      currentContract.amendments.push(amendment)
    } else {
      Vue.set(currentContract.amendments, index, amendment)
    }

    state.isEditChanged = true
  },
  removeAmendmentFromContract (state, amendment) {
    const currentContract = state.currentContract ? state.currentContract : state.newContract

    if (!currentContract) {
      throw new Error('No contract selected')
    }

    const index = currentContract.amendments.findIndex(x => x.uid === amendment.uid)
    currentContract.amendments.splice(index, 1)

    state.isEditChanged = true
  },
  addOrUpdateTermToContract (state, term) {
    const currentContract = state.currentContract ? state.currentContract : state.newContract

    if (!currentContract) {
      throw new Error('No contract selected')
    }

    const index = currentContract.terms.findIndex(x => x.uid === term.uid)

    if (index < 0) {
      currentContract.terms.push(term)
    } else {
      Vue.set(currentContract.terms, index, term)
    }

    state.isEditChanged = true
  },
  removeTermFromContract (state, term) {
    const currentContract = state.currentContract ? state.currentContract : state.newContract

    if (!currentContract) {
      throw new Error('No contract selected')
    }

    const index = currentContract.terms.findIndex(x => x.uid === term.uid)
    currentContract.terms.splice(index, 1)

    state.isEditChanged = true
  },
  addOrUpdatePaymentRecipientToContract (state, recipient) {
    const currentContract = state.currentContract ? state.currentContract : state.newContract
    if (!currentContract) {
      throw new Error('No contract selected')
    }

    const index = currentContract.paymentRecipients.findIndex(x => x.uid === recipient.uid)

    if (index < 0) {
      currentContract.paymentRecipients.push(recipient)
    } else {
      Vue.set(currentContract.paymentRecipients, index, recipient)
    }

    state.isEditChanged = true
  },
  removePaymentRecipientFromContract (state, paymentRecipient) {
    const currentContract = state.currentContract ? state.currentContract : state.newContract

    if (!currentContract) {
      throw new Error('No contract selected')
    }

    const index = currentContract.paymentRecipients.findIndex(x => x.uid === paymentRecipient.uid)
    currentContract.paymentRecipients.splice(index, 1)

    state.isEditChanged = true
  },

  addOrUpdatePaymentToContract (state, payment) {
    const currentContract = state.currentContract ? state.currentContract : state.newContract
    if (!currentContract) {
      throw new Error('No contract selected')
    }

    const index = currentContract.payments.findIndex(x => x.uid === payment.uid)

    if (index < 0) {
      currentContract.payments.push(payment)
    } else {
      Vue.set(currentContract.payments, index, payment)
    }

    state.isEditChanged = true
  },

  removePaymentFromContract (state, payments) {
    const currentContract = state.currentContract ? state.currentContract : state.newContract

    if (!currentContract) {
      throw new Error('No contract selected')
    }

    currentContract.payments = currentContract.payments.filter(payment => !payments.map(p => p.uid).includes(payment.uid))

    state.isEditChanged = true
  },
  setContractAsChanged (state) {
    state.isEditChanged = true
  },
  setCommonChanged (state) {
    state.status.common = 'changed'
  },
  setAreasChanged (state) {
    state.status.areas = 'changed'
  },
  setFinancesChanged (state) {
    state.status.finances = 'changed'
  },
  setTermsChanged (state) {
    state.status.terms = 'changed'
  },
  setAmendmentsChanged (state) {
    state.status.amendments = 'changed'
  },
  setCommonError (state) {
    state.status.common = 'error'
  },
  setAreasError (state) {
    state.status.areas = 'error'
  },
  setFinancesError (state) {
    state.status.finances = 'error'
  },
  setTermsError (state) {
    state.status.terms = 'error'
  },
  setAmendmentsError (state) {
    state.status.amendments = 'error'
  },
  setStatusAllChanged (state) {
    state.status.common = 'changed'
    state.status.areas = 'changed'
    state.status.finances = 'changed'
    state.status.terms = 'changed'
    state.status.amendments = 'changed'
  },
  setStatusWorking (state) {
    state.status.common = 'working'
    state.status.areas = 'working'
    state.status.finances = 'working'
    state.status.terms = 'working'
    state.status.amendments = 'working'
  },
  setStatusError (state) {
    state.status.common = 'error'
    state.status.areas = 'error'
    state.status.finances = 'error'
    state.status.terms = 'error'
    state.status.amendments = 'error'
  },
  setStatusSuccess (state) {
    state.status.common = 'success'
    state.status.areas = 'success'
    state.status.finances = 'success'
    state.status.terms = 'success'
    state.status.amendments = 'success'
  },
  resetStatus (state) {
    state.status.common = 'none'
    state.status.areas = 'none'
    state.status.finances = 'none'
    state.status.terms = 'none'
    state.status.amendments = 'none'
  }
}

export default mutations
