const mutations = {
  recieveLocalDistricts (state, localDistricts) {
    state.localDistricts = localDistricts
  },
  recieveCadastralDistricts (state, cadastralDistricts) {
    state.cadastralDistricts = cadastralDistricts
  },
  resetAreas (state) {
    state.currentLocalDistrict = null
    state.cadastralDistricts.length = 0
    state.currentCadastralDistrict = null
    state.landParcelNumbers = []
    state.currentLandParcelNumber = null
    state.segments = []
    state.selectedSegments = null
  },
  selectedLocalDistrict (state, {localDistrict, cadastralDistricts}) {
    mutations.resetAreas(state)
    state.currentLocalDistrict = localDistrict

    if (localDistrict) {
      cadastralDistricts.forEach(cd => state.cadastralDistricts.push(cd))
    }
  },
  selectedCadastralDistrict (state, {cadastralDistrict, landParcelNumbers, segments}) {
    state.currentCadastralDistrict = cadastralDistrict
    state.landParcelNumbers = landParcelNumbers
    state.currentLandParcelNumber = null
    state.segments = segments
    state.selectedSegments = null
  },
  selectLandParcelNumber (state, {landParcelNumber, segments}) {
    state.currentLandParcelNumber = landParcelNumber
    state.segments = segments
    state.selectedSegments = null
  },
  selectSegments (state, segments) {
    state.selectedSegments = segments
  },

  selectArea (state, area) {
    state.currentArea = area
  },
  openEditArea (state, area) {
    state.currentArea = area
    state.isEditAreaModalOpen = true
  },
  closeEditArea (state) {
    state.currentArea = null
    state.isEditAreaModalOpen = false
  },
  openDeleteArea (state) {
    state.isDeleteAreaModalOpen = true
  },
  closeDeleteArea (state) {
    state.isDeleteAreaModalOpen = false
  },
  updateContractualArea (state, newContractualSize) {
    state.currentArea.sizeContractual = newContractualSize
  }
}

export default mutations
