{setupRangePicker} = require 'src/coffee/controls/range-picker'

setup = ({$element, options, $ctx = $(document)}) ->
    dataOptions =
        min: parseFloat $element.attr 'min'
        max: parseFloat $element.attr 'max'
        label: $element.attr 'label'

    options = $.extend {}, dataOptions, options

    setupRangePicker $element, options

    return $.Deferred().resolve().promise()

module.exports = {
    setup
}

module.exports.__esModule = true
module.exports.default = setup
