clamp = (x, {min, max}) ->
    min or= 0
    max or= 1
    return Math.min(max, Math.max(min, x))

normalize = (x, {min, max}) ->
    min or= 0
    max or= 1
    return (x - min) / (max - min)

denormalize = (normalized, {min, max}) ->
    min or= 0
    max or= 1
    return min + normalized * (max - min)

quantize = (value, bins) ->
    for v, i in bins
        continue if i is 0
        if v >= value
            return i - 1
    throw new Error('value outside of given range')

module.exports = {
    clamp,
    normalize,
    denormalize,
    quantize
}
