import {makeSetters} from '@helpers/vuex/mutations'
import newGuid from '@helpers/new-guid'

export default {
  namespaced: true,

  state: {
    current: {
      contract: {
        amendments: [],
        autoRenew: false,
        autoRenewalPeriod: 0,
        cancellationPeriod: 0,
        certificateNumber: null,
        comments: null,
        contractDate: null,
        dmsReferences: [],
        isRentOut: false,
        leaseObject: null,
        orgUnitId: null,
        paymentRecipients: [],
        payments: [],
        terms: [
          {
            start: null,
            end: null
          }
        ],
        uid: null
      },
      permissions: {}
    },
    currentAmendment: {
      hasAreaChanges: false,
      isExchangeAllowed: false,
      isStart: false,
      isTaxPaidByLeaser: false,
      isWaterPaidByLeaser: false,
      leaser: [],
      lessor: [],
      uid: null,
      validon: null
    },

    sumContractAreas: 0,
    leasedIn: 0,
    leasedOut: 0,
    segmentSizeByUsage: [],
    priceByLocalDistrictAndUsage: []

  },

  actions: {
  },

  mutations: {
    ...makeSetters([
      'current.amendments',
      'current.autoRenew',
      'current.autoRenewalPeriod',
      'current.cancellationPeriod',
      'current.certificateNumber',
      'current.comments',
      'current.contractDate',
      'current.dmsReferences',
      'current.isRentOut',
      'current.leaseObject',
      'current.orgUnitId',
      'current.paymentRecipients',
      'current.payments',
      'current.terms',
      'current.uid',

      'currentAmendment.areas',
      'currentAmendment.hasAreaChanges',
      'currentAmendment.isExchangeAllowed',
      'currentAmendment.isStart',
      'currentAmendment.isTaxPaidByLeaser',
      'currentAmendment.isWaterPaidByLeaser',
      'currentAmendment.leaser',
      'currentAmendment.lessor',
      'currentAmendment.uid',
      'currentAmendment.validOn'
    ]),

    setCurrentAmendment (state, amendment) {
      state.currentAmendment = amendment
    },

    appendAmendment (state, amendment) {
      const newAmendment = JSON.parse(JSON.stringify(state.current.amendments[state.current.amendments.length - 1]))
      newAmendment.uid = newGuid()
      newAmendment.isStart = false
      newAmendment.validOn = amendment.validOn

      for (let area of newAmendment.areas) {
        area.uid = newGuid()
      }

      state.current.amendments = state.current.amendments.concat(newAmendment)
      state.currentAmendment = newAmendment
    },

    removeAmendment (state, amendment) {
      state.current.amendments = state.current.amendments.filter(dr => dr.uid !== amendment.uid)
    },

    updateAmendment (state, amendment) {
      const without = state.current.amendments.filter(dr => dr.uid !== amendment.uid)
      state.current.amendments = without.concat(amendment)
    },

    appendArea (state, area) {
      if (!state.currentAmendment) return
      area.uid = newGuid()
      state.currentAmendment.areas = state.currentAmendment.areas.concat(area)
    },

    removeArea (state, area) {
      state.currentAmendment.areas = state.currentAmendment.areas.filter(a => a.uid !== area.uid)
    },

    setLeasedSize (state, {areaId, newSize}) {
      if (newSize === undefined || newSize === null) return
      const seg = state.currentAmendment.areas.find(seg => seg.uid === areaId)
      if (seg) {
        seg.sizeContractual = newSize
      }
    },

    appendLeaser (state, leaser) {
      if (!state.currentAmendment) return
      leaser.uid = newGuid()
      state.currentAmendment.leaser = state.currentAmendment.leaser.concat(leaser)
    },

    removeLeaser (state, leaser) {
      state.currentAmendment.leaser = state.currentAmendment.leaser.filter(l => l.value !== leaser.value)
    },

    appendLessor (state, lessor) {
      if (!state.currentAmendment) return
      lessor.uid = newGuid()
      state.currentAmendment.lessor = state.currentAmendment.lessor.concat(lessor)
    },

    removeLessor (state, lessor) {
      state.currentAmendment.lessor = state.currentAmendment.lessor.filter(l => l.value !== lessor.value)
    },

    appendDmsReference (state, dmsReference) {
      dmsReference.uid = newGuid()
      state.current.dmsReferences = state.current.dmsReferences.concat(dmsReference)
    },

    removeDmsReference (state, dmsReference) {
      state.current.dmsReferences = state.current.dmsReferences.filter(dr => dr.uid !== dmsReference.uid)
    },

    updateDmsReference (state, dmsReference) {
      const without = state.current.dmsReferences.filter(dr => dr.uid !== dmsReference.uid)
      state.current.dmsReferences = without.concat(dmsReference)
    },

    appendPaymentRecipient (state, paymentRecipient) {
      paymentRecipient.uid = newGuid()
      state.current.paymentRecipients = state.current.paymentRecipients.concat(paymentRecipient)
    },

    removePaymentRecipient (state, paymentRecipient) {
      state.current.paymentRecipients = state.current.paymentRecipients.filter(pr => pr.uid !== paymentRecipient.uid)
    },

    updatePaymentRecipient (state, paymentRecipient) {
      const without = state.current.paymentRecipients.filter(pr => pr.uid !== paymentRecipient.uid)
      state.current.paymentRecipients = without.concat(paymentRecipient)
    },

    appendPayment (state, payment) {
      payment.uid = newGuid()
      state.current.payments = state.current.payments.concat(payment)
    },

    removePayment (state, payment) {
      state.current.payments = state.current.payments.filter(p => p.uid !== payment.uid)
    },

    updatePayment (state, payment) {
      const without = state.current.payments.filter(p => p.uid !== payment.uid)
      state.current.payments = without.concat(payment)
    },

    appendTerm (state, term) {
      term.uid = newGuid()
      state.current.terms = state.current.terms.concat(term)
    },

    removeTerm (state, term) {
      state.current.terms = state.current.terms.filter(t => t.uid !== term.uid)
    },

    updateTerm (state, term) {
      const without = state.current.terms.filter(t => t.uid !== term.uid)
      state.current.terms = without.concat(term)
    },

    setAggregationData (state, data) {
      state.sumContractAreas = data.commonData.sumContractAreas
      state.leasedIn = data.commonData.leasedIn
      state.leasedOut = data.commonData.leasedOut
      state.segmentSizeByUsage = data.segmentSizeByUsage
      state.priceByLocalDistrictAndUsage = data.priceByLocalDistrictAndUsage
    }
  }

}
