{normalize, denormalize, quantize, clamp} = require 'src/coffee/helpers/math-tools'

defaultColorFunction = (i) -> 'transparent'

normalizeMousePosition = (element, {x, y}) ->
    elementX = x - element.getClientRects()[0].left
    elementY = y - element.getClientRects()[0].top

    normalized =
        x: elementX / element.clientWidth
        y: elementY / element.clientHeight

    return normalized

segmentLimits = (picker, index) ->
    stops = picker.state.stops
    return {
        min: if index > 0 then stops[index - 1].normalizedValue else 0
        max: if stops.length > index then stops[index].normalizedValue else 1
    }

stopLimits = (picker, index) ->
    stops = picker.state.stops
    return {
        min: if index > 0 then stops[index - 1].normalizedValue else 0
        max: if stops.length > (index + 1) then stops[index + 1].normalizedValue else 1
    }

sortStops = (picker) ->
    stops = picker.state.stops
    stops.sort((a, b) -> a.value - b.value)
    stops.map((x, i) -> x.index = i)

updateSegments = (picker) ->
    stops = picker.state.stops
    segments = picker.state.segments
    while segments.length > stops.length + 1
        segments.pop().remove()
    while segments.length < stops.length + 1
        segment = createSegment()
        # picker.element.querySelector('.segment-container').appendChild segment
        picker.element.appendChild segment
        segments.push(segment)

redrawSegments = (picker) ->
    positions = picker.getNormalizedValues()

    for segment, i in picker.state.segments
        segment.style.backgroundColor = picker.options.colorFunction(i)

        left = positions[i]
        right = 1 - positions[i + 1]

        segment.style.left = "#{100 * left}%"
        segment.style.right = "#{100 * right}%"

redrawPicker = (picker, segmentWidthsOnly) ->
    unless segmentWidthsOnly
        sortStops(picker)
        updateSegments(picker)
    redrawSegments(picker)

redrawStop = (stop) ->
    x = stop.normalizedValue * stop.picker.element.clientWidth - 4
    stop.element.style.left = "#{x}px"
    format = Globalize.numberFormatter()
    displayValue = format(stop.value)
    stop.element.setAttribute 'value', stop.value
    stop.tooltip.textContent = "#{stop.picker.options.label}: #{displayValue}"
    stop.label.textContent = displayValue

    stop.input.value = displayValue

updateStop = (stop, value) ->
    normalizedValue = stop.picker.normalize(value)
    updateStopNormalized(stop, normalizedValue)

updateStopNormalized = (stop, normalizedValue) ->
    {min, max} = stopLimits(stop.picker, stop.index)

    margin = 10 / stop.picker.element.clientWidth

    range =
        min: min + margin
        max: max - margin

    normalizedValue = clamp(normalizedValue, range)

    stop.normalizedValue = normalizedValue
    stop.value = stop.picker.denormalize(normalizedValue)

    redrawStop(stop)

createChildWithClasses = (parent, tag, classes) ->
    element = document.createElement(tag)
    element.classList.add(classes...)
    parent.appendChild(element)
    return element

createSegment = ->
    element = document.createElement('div')
    element.classList.add 'range-picker-segment'
    element.rangePickerSegment = {}

    return element

createStop = ->
    element = document.createElement('div')
    element.classList.add 'range-picker-stop'

    tooltip = createChildWithClasses(element, 'div', ['range-picker-stop-tooltip'])
    label = createChildWithClasses(element, 'div', ['range-picker-stop-label'])

    inputContainer = createChildWithClasses(element, 'div', ['range-picker-stop-input'])
    input = createChildWithClasses(inputContainer, 'input', ['form-control'])

    removeButton = createChildWithClasses(element, 'button', ['range-picker-stop-remove-button', 'btn', 'btn-danger'])
    icon = createChildWithClasses(removeButton, 'i', ['fa', 'fa-close'])

    removeButton.addEventListener 'click', (event) ->
        stop.picker.removeStop(stop)

    input.addEventListener 'input', (event) ->
        parse = Globalize.numberParser()
        stop.picker.set stop, parse input.value

    # element.addEventListener 'focusout', -> stop.picker.deselect() # issue with remove button

    element.rangePickerStop = stop = {
        element,
        input,
        tooltip,
        label,
        normalized: 0,
        value: 0
    }

    return stop

createRangePicker = (element, options) ->
    format = Globalize.numberFormatter()
    options.colorFunction or= defaultColorFunction

    state = {
        stops: [],
        segments: [],
        selectedStop: null,
        clickedStop: null,
        draggedStop: null
    }

    element.classList.add 'range-picker-container'

    segmentContainer = createChildWithClasses(element, 'div', ['segment-container'])
    stopContainer = createChildWithClasses(element, 'div', ['stop-container'])
    labelContainer = createChildWithClasses(element, 'div', ['label-container'])

    minLabel = createChildWithClasses(labelContainer, 'span', ['range-picker-axis-label', 'min-label'])
    maxLabel = createChildWithClasses(labelContainer, 'span', ['range-picker-axis-label', 'max-label'])
    minLabel.textContent = format options.min
    maxLabel.textContent = format options.max

    addStop = (value) ->
        stops = picker.state.stops
        # TODO check available space

        desiredIndex = quantize(value, picker.getValues())

        {min, max} = segmentLimits(picker, desiredIndex)

        if (max - min) < (20 / picker.element.clientWidth)
            picker.element.classList.add('error')
            setTimeout((-> picker.element.classList.remove('error')), 1000)
            return

        stop = createStop()
        stop.picker = picker
        stop.index = desiredIndex # required for proper clamping of stop value

        stops.splice desiredIndex, 0, stop
        stopContainer.appendChild stop.element

        updateStop(stop, value)

        picker.changed()
        picker.redraw()

    removeStop = (stopOrIndex) ->
        stops = picker.state.stops
        stop = if Number.isInteger(stopOrIndex) then stops[stopOrIndex] else stopOrIndex

        stops.splice(stops.indexOf(stop), 1)
        stop.element.remove()

        picker.changed()
        picker.redraw()

    set = (stopOrIndex, value) ->
        stop = if Number.isInteger(stopOrIndex) then picker.state.stops[stopOrIndex] else stopOrIndex

        updateStop(stop, value)

        picker.changed(true)
        picker.redraw(true)

    get = (index) -> picker.state.stops[index].value

    getNormalizedValues = ->
        values = picker.state.stops.map((x) -> x.normalizedValue)
        values.unshift(0)
        values.push(1)
        return values

    getValues = ->
        values = picker.state.stops.map (x) -> x.value
        values.unshift(picker.options.min)
        values.push(picker.options.max)
        return values

    select = (stopOrIndex) ->
        stop = if Number.isInteger(stopOrIndex) then picker.state.stops[stopOrIndex] else stopOrIndex

        if picker.state.selectedStop
            picker.deselect()

        picker.state.selectedStop = stop
        stop.element.classList.add 'selected'
        stop.input.focus()

    deselect = ->
        if picker.state.selectedStop
            picker.state.selectedStop.element.classList.remove 'selected'
        picker.state.selectedStop = null

    throttleCounter = 0
    throttleTimeout = null

    changed = (throttle) ->
        if throttleTimeout
            clearTimeout throttleTimeout

        sendEvent = ->
            throttleCounter = 0
            $(picker.element).trigger 'change'

        if throttle and throttleCounter < 10
            throttleTimeout = setTimeout sendEvent, 100
        else
            sendEvent()

    element.rangePicker = picker = {
        options,
        state,
        element,
        normalizeMousePosition: ({x, y}) -> normalizeMousePosition(element, {x, y}),
        addStop,
        removeStop,
        select,
        deselect,
        normalize: (value) -> normalize(value, options)
        denormalize: (normalizedValue) -> denormalize(normalizedValue, options)
        get,
        set,
        getValues,
        getNormalizedValues,
        redraw: (segmentWidthsOnly) -> redrawPicker(picker, segmentWidthsOnly),
        changed
    }

    bindEvents(picker)
    return picker

bindEvents = (picker) ->
    onMouseMove = (event) ->
        stop = picker.state.draggedStop
        mousePosition =
            x: event.pageX
            y: event.pageY

        normalizedPosition = picker.normalizeMousePosition(mousePosition)
        value = picker.denormalize(normalizedPosition.x)
        picker.set(stop.index, value)

        event.preventDefault()
        event.stopPropagation()

    stopDragging = ->
        picker.state.draggedStop.element.classList.remove 'dragging'
        picker.state.draggedStop = null

    onMouseUp = (event) ->
        # clean up interaction events
        document.removeEventListener 'mouseup', onMouseUp
        document.removeEventListener 'mousemove', onMouseMove

        state = picker.state

        state.clickedStop = null

        if state.draggedStop
            stopDragging()

            event.preventDefault()
            event.stopPropagation()

    startDragging = (stop) ->
        picker.deselect()
        picker.state.clickedStop = null
        picker.state.draggedStop = stop
        stop.element.classList.add 'dragging'

        document.addEventListener 'mousemove', onMouseMove

    picker.element.addEventListener 'mousedown', (event) ->
        stop = event.target.rangePickerStop
        # left mouse clicked on a picker stop
        if stop and event.button is 0
            picker.state.clickedStop = stop

            document.addEventListener 'mouseup', onMouseUp

            # hopefully ignore default browser behaviour like text selection
            event.preventDefault()
            event.stopPropagation()

    picker.element.addEventListener 'mouseout', (event) ->
        stop = event.target.rangePickerStop
        if stop and stop is picker.state.clickedStop
            startDragging(picker.state.clickedStop)

            event.preventDefault()
            event.stopPropagation()

    picker.element.addEventListener 'click', (event) ->
        segment = event.target.rangePickerSegment
        if segment
            mousePosition =
                x: event.pageX
                y: event.pageY

            normalizedPosition = picker.normalizeMousePosition(mousePosition)
            value = picker.denormalize(normalizedPosition.x)
            picker.addStop(value)
            event.stopPropagation()
            event.preventDefault()
        stop = event.target.rangePickerStop
        if stop
            if stop is picker.state.selectedStop
                picker.deselect()
            else
                picker.select(stop)

            event.stopPropagation()
            event.preventDefault()

setupRangePicker = ($element, options) ->
    pickers = []
    for element in $element
        picker = createRangePicker(element, options)
        picker.redraw()
        pickers.push picker
    return pickers

module.exports = {
    createRangePicker,
    setupRangePicker
}

module.exports.__esModule = true
module.exports.default = setupRangePicker
