import queryData from '../api/queryData'
import commandQueue from '../api/commandQueue'

export const selectContact = ({commit}, contactId) => {
  if (contactId) {
    queryData.getContactById(contactId, (contact) => {
      commit('selectContact', contact)
    },
    () => {
      commit('selectContact', null)
    })
  } else {
    commit('selectContact', null)
  }
}

export const createNewContactRequest = ({commit, rootState}) => {
  if (!rootState.realEstateMasterDataContacts.navigation.selectedOrgUnit) {
    throw new Error('No tree node selected!')
  }

  const orgUnitId = rootState.realEstateMasterDataContacts.navigation.selectedOrgUnit.id
  commit('createNewContactRequest', orgUnitId)
}

export const createNewContactSuccess = ({commit, state}) => {
  if (!state.newContact) {
    throw new Error('No contact for saving selected')
  }

  return commandQueue.saveContact(state.newContact)
    .then((contactId) => {
      commit('createNewContactSuccess', contactId)
    })
}

export const createNewContactFailure = ({commit}) => {
  commit('createNewContactFailure')
}

export const deleteContact = () => {
  alert('Delete !!!')
}

export const editContactRequest = ({commit}) => {
  commit('editContactRequest')
}

export const editContactSuccess = ({commit, state}) => {
  if (!state.currentContact) {
    throw new Error('No contact for saving selected')
  }

  return commandQueue.saveContact(state.currentContact)
    .then(() => {
      commit('editContactSuccess')
    })
}

export const editContactFailure = ({commit, state}) => {
  if (!state.currentContact) {
    throw new Error('No contact selected')
  }

  queryData.getContactById(
    state.currentContact.uid,
    (contact) => {
      commit('selectContact', contact)
    },
    () => {
      alert('Fehler')
    }
  )

  commit('editContactFailure')
}

export const setContactAsChanged = ({commit}) => {
  commit('setContactAsChanged')
}

export const setContactAsUnchanged = ({commit}) => {
  commit('setContactAsUnchanged')
}
