// import axios from 'axios'
import {makeSetters} from '@helpers/vuex/mutations'
import {smarterPost} from '@helpers/vuex/data-loading'

const defaults = () => ({
  ui: {
    username: null,
    password: null,
    machineIds: [],
    selectedMachineIds: [],
    designator: null,
    includeFieldBorder: false
  }
})

export default {
  namespaced: true,
  state: defaults(),
  mutations: {
    ...makeSetters([
      'ui.username',
      'ui.password',
      'ui.machineIds',
      'ui.selectedMachineIds',
      'ui.includeFieldBorder',
      'ui.designator'
    ]),
    reset (state) {
      Object.assign(state, defaults())
    }
  },
  actions: {
    getMachineIds ({state, commit}) {
      return smarterPost('/api/v2/data-transfer/vario-doc/machine-ids', {
        username: state.ui.username,
        password: state.ui.password
      }, {
        id: 'dataTransfer.varioDoc.machineIds',
        onResult (machineIds) {
          if (machineIds) {
            commit('setMachineIds', machineIds)
          }
        }
      })
    },
    async send ({state, rootState, commit, rootGetters}) {
      let applicationMapIds = []
      if (rootState.fieldRecordSystem.mapManagement.ui.selectionMode === 'download') {
        applicationMapIds = rootState.fieldRecordSystem.mapManagement.mapIdsForDownload
      } else {
        applicationMapIds = [rootState.dataTransfer.selectedApplicationMapId]
      }
      const mapType = rootState.fieldRecordSystem.mapManagement.ui.selectionType
      const lookup = rootState.fieldRecordSystem.mapManagement.data[mapType].mapLookup

      const maps = applicationMapIds.map(id => lookup[id])
      const fieldIdsByMapId = rootGetters['fieldRecordSystem/mapManagement/fieldIdsByMapId']
      const harvestYear = rootGetters['fieldRecordSystem/harvestYear']

      const geoData = maps.reduce((lookup, map) => {
        for (const fieldId of fieldIdsByMapId[map.id]) {
          if (Object.values(lookup).find(x => Object.keys(x).includes(map.id))) continue
          if (!lookup[fieldId]) {
            lookup[fieldId] = {}
          }
          lookup[fieldId][map.id] = map.name
        }
        return lookup
      }, {})

      await smarterPost('/api/v2/data-transfer/vario-doc/send', {
        username: state.ui.username,
        password: state.ui.password,
        machineIds: state.ui.selectedMachineIds,
        designator: state.ui.designator,
        includeFieldBorder: state.ui.includeFieldBorder,
        geoData: geoData,
        harvestYear: harvestYear
      }, {
        id: 'dataTransfer.varioDoc.send'
      })

      commit('reset')
    }
  }
}
