attachValidation = ($control, eventName) ->
    $control.on eventName, (event) ->
        invalidatedHandler($(event.target))


invalidatedHandler = ($control, dontMarkValidControls = false) ->
    if $control.is 'table'
        isValid = $control.staticgrid()[0].validator.valid()
    else
        $form = $control.closest('div.tab-pane').find('form')

        validator = $form.validate()
        ignoreSettings = validator.settings.ignore #temporary override
        validator.settings.ignore = '[readonly], table.dataTable input, .ignore-validation'
            #previously used values
            #jquery.validate has this annoying bug where if you manually check a control that would be excluded by the
            #ignore list, it crashes...damned jquery.validate
            #'[readonly], :hidden > .selectized, .selectize-control .selectize-input input, table.dataTable input'
            
        isValid = false
        try
            isValid = validator.check($control)
        catch
            isValid = false

        #optics
        if not (dontMarkValidControls and isValid)
            if $control.hasClass('selectize-input')
                $control.closest('.form-group').find('select').valid()
            else if $control.hasClass('date')
                $control.closest('.form-group').find('input').valid()
            else
                $control.valid()

        validator.settings.ignore = ignoreSettings

    #logic
    args = {
        isValid: isValid
        control: $control
    }
    $control.trigger('change:validation', [args])


invalidateControls = (identifiers, dontMarkValidControls = false) ->
    for identifier in identifiers
        invalidatedHandler($(identifier), dontMarkValidControls)


catchEvents = ($domElement, state, $errorIndicaterElement) ->
    $domElement.on 'change:validation', (event, {isValid, control}) ->
        state[control[0].id] = isValid
        isAllValid = isStateValid(state)

        if($errorIndicaterElement)
            if isAllValid
                $errorIndicaterElement.removeClass('has-error')
            else
                $errorIndicaterElement.addClass('has-error')

    $domElement.on 'reset:validation', (event) ->
        resetState(state)
        $errorIndicaterElement.removeClass('has-error')


triggerValidationClearEvent = (domElements...) ->
    for $domElement in domElements
        $domElement.trigger 'reset:validation'


isStateValid = (state) ->
    isAllValid = true
    for id, object of state
        if typeof(object) is 'boolean'
            isAllValid = isAllValid and object
        else
            isAllValid = isAllValid and isStateValid(object)
    return isAllValid


resetState = (state) ->
    for propertyName, object of state
        if typeof(object) is 'boolean'
            delete state[propertyName]
        else
            resetState(object)


Validation = {
    attachValidation,
    invalidatedHandler,
    catchEvents,
    isStateValid,
    resetState,
    invalidateControls,
    triggerValidationClearEvent
}

module.exports = {Validation}

module.__esModule = true
module.exports.default = Validation
