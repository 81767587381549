const mutations = {
  selectTerm (state, term) {
    state.currentTerm = term
  },
  openCreateOrEdit (state, term) {
    if (!term) {
      throw Error('No currement term')
    }

    state.editTerm = term
    state.isEditTermModalOpen = true
  },
  closeCreateOrEdit (state) {
    state.isEditTermModalOpen = false
    state.editTerm = {
      start: null,
      end: null,
      leasePrice: 0,
      currency: null,
      remark: null
    }
  },
  openDeleteTerm (state) {
    if (!state.currentTerm) {
      throw new Error('No term selected')
    }

    state.isDeleteTermModalOpen = true
  },
  closeDeleteTerm (state) {
    state.isDeleteTermModalOpen = false
  }
}

export default mutations
