import axios from 'axios'
import moment from 'moment'

const state = {
  data: {
    currentDay: {
      numberHensTotal: 0,
      numberRoostersTotal: 0,
      henLosses: 0,
      roosterLosses: 0,
      broodEggs: 0,
      unbreadableEggs: 0,
      floorEggs: 0,
      eggsTotal: 0
    },
    previousDay: {
      numberHensTotal: 0,
      numberRoostersTotal: 0,
      henLosses: 0,
      roosterLosses: 0,
      broodEggs: 0,
      unbreadableEggs: 0,
      floorEggs: 0,
      eggsTotal: 0
    }
  }
}

const mutations = {
  setCurrentDay (state, data) {
    state.data.currentDay = data
  },
  setPreviousDay (state, data) {
    state.data.previousDay = data
  }
}

const actions = {
  loadFarmStatistics ({commit, rootGetters}) {
    let farm = rootGetters['poultryManagement/farmer/currentFarm']
    let currentDate = rootGetters['poultryManagement/farmer/selectedDate']
    let previousDate = moment(currentDate).subtract('1', 'day').format('YYYY-MM-DD')

    axios.get('/api/poultryManagement/farmer/farmstatistics', {params: {farmId: farm.id, date: currentDate}})
    .then((result) => {
      commit('setCurrentDay', result.data)
    })

    axios.get('/api/poultryManagement/farmer/farmstatistics', {params: {farmId: farm.id, date: previousDate}})
    .then((result) => {
      commit('setPreviousDay', result.data)
    })
  }
}

const getters = {
  currentDay (state) {
    return state.data.currentDay
  },
  previousDay (state) {
    return state.data.previousDay
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
