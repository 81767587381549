const mutations = {
  recieveBanks (state, banks) {
    state.banks = banks
  },
  selectBankingAccount (state, bankingAccount) {
    state.currentBankingAccount = bankingAccount
  },
  openCreateOrEdit (state, bankingAccount) {
    if (!bankingAccount) {
      throw Error('No currement banking account')
    }

    state.editBankingAccount = bankingAccount
    state.isEditModalOpen = true
  },
  closeCreateOrEdit (state) {
    state.isEditModalOpen = false
    state.editBankingAccount = null
  },
  openDelete (state) {
    if (!state.currentBankingAccount) {
      throw new Error('No banking account selected')
    }

    state.isDeleteModalOpen = true
  },
  closeDelete (state) {
    state.isDeleteModalOpen = false
  }
}

export default mutations
