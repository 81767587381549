const reducers = {
  flattenEntities: (array, entity) => array.concat([entity], (entity.children || []).reduce(reducers.flattenEntities, [])),
  hasFieldsSomewhere: (result, entity) => result || entity.type === 'field' || (entity.children || []).reduce(reducers.hasFieldsSomewhere, false)
}

export const filters = {
  fields: entity => entity.type === 'field',
  orgUnits: entity => entity.type === 'org',
  hasFieldsSomewhere: entity => [entity].reduce(reducers.hasFieldsSomewhere, false)
}

export const recursiveParent = x => x
  ? x.parent
    ? recursiveParent(x.parent)
    : x
  : null

export const flattenEntityForest = forest => forest.reduce(reducers.flattenEntities, [])
