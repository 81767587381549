import Vue from 'vue'
import Vuex from 'vuex'

import geometryImport from './modules/geometry-import'
import realEstate from './modules/real-estate-new'
import realEstateContractLease from './modules/real-estate/contract/lease'
import realEstateContractLeaseAppointment from './modules/real-estate/contract/lease-appointment'
import realEstateContractLeasePayments from './modules/real-estate/contract/lease-payments'
import realEstateMasterDataContacts from './modules/real-estate/master-data/contacts'
import poultryManagement from './modules/poultry-management'
import poultryManagementPrototype from './modules/poultry-management-prototype'
import dataTransfer from './modules/data-transfer'
import dataLoading from './modules/data-loading'

import i18n from './modules/i18n'
import masterData from './modules/master-data'

Vue.use(Vuex)

const store = new Vuex.Store({
  // strict: process.env.NODE_ENV !== 'production',
  state: {
    debug: {
      localization: false
    },
    permissions: []
  },
  mutations: {
    toggleDebugFlag (state, flag) {
      state.debug[flag] = !state.debug[flag]
    },
    setUserPermissions (state, permissions) {
      state.permissions = permissions
    }
  },
  modules: {
    geometryImport,
    realEstate,
    realEstateContractLease,
    realEstateContractLeaseAppointment,
    realEstateContractLeasePayments,
    realEstateMasterDataContacts,
    poultryManagement,
    poultryManagementPrototype,
    dataTransfer,
    i18n,
    masterData,
    dataLoading
  }
})

if (module.hot) {
  module.hot.accept([
    './modules/geometry-import',
    './modules/field-record-system',
    './modules/real-estate-new',
    './modules/real-estate/contract/lease',
    './modules/real-estate/contract/lease-appointment',
    './modules/real-estate/contract/lease-payments',
    './modules/real-estate/master-data/contacts',
    './modules/poultry-management',
    './modules/poultry-management-prototype',
    './modules/data-transfer',
    './modules/administration',
    './modules/i18n',
    './modules/data-loading'
  ], () => {
    const newGeometryImport = require('./modules/geometry-import').default
    const newFieldRecordSystem = require('./modules/field-record-system').default
    const newRealEstate = require('./modules/real-estate-new').default
    const newRealEstateContractLease = require('./modules/real-estate/contract/lease').default
    const realEstateContractLeaseAppointment = require('./modules/real-estate/contract/lease-appointment').default
    const realEstateContractLeasePayments = require('./modules/real-estate/contract/lease-payments').default
    const realEstateMasterDataContacts = require('./modules/real-estate/master-data/contacts').default
    const newI18n = require('./modules/i18n').default
    const newDataLoading = require('./modules/data-loading').default
    const administration = require('./modules/administration').default

    store.hotUpdate({
      modules: {
        geometryImport: newGeometryImport,
        fieldRecordSystem: newFieldRecordSystem,
        realEstate: newRealEstate,
        realEstateContractLease: newRealEstateContractLease,
        realEstateContractLeaseAppointment: realEstateContractLeaseAppointment,
        realEstateContractLeasePayments: realEstateContractLeasePayments,
        realEstateMasterDataContacts: realEstateMasterDataContacts,
        poultryManagement: poultryManagement,
        poultryManagementPrototype: poultryManagementPrototype,
        dataTransfer: dataTransfer,
        administration,
        i18n: newI18n,
        dataLoading: newDataLoading
      }
    })
  })
}

export default store
